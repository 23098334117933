import React from "react";
import {CREATE} from 'react-admin'
import dataProvider from "../../../rest-data-provider";
import PropTypes from "prop-types";
import {notifyError} from "../../../ec-admin-adapters/notifyF";
import RelatedAddOrEditButton from "../../../components/Buttons/RelatedAddOrEditButton";
import {AccommodationTierWithChange} from "./AccommodationTierWithChange";
import TemplateAccommodationTitle from "./TemplateAccommodationTitle";
import AccommodationInput from "./AccommodationInput";

const AccommodationAddButton = (props) => {
    const {templateInfo} = props;
    const submitEvent = ({notify, refresh, values,setShow}) => {
        dataProvider(CREATE, 'tripTemplateAccommodations', {data: values}).then(() => {
            refresh();
            setShow(false);
        }).catch(error => {
            notifyError(notify, error);
        })
    };

    if (templateInfo.ID) {
        const Title = <TemplateAccommodationTitle isEdit={false} info={templateInfo}/>;
        return <RelatedAddOrEditButton title={Title} record={{Trip_Template_Step_ID: templateInfo.ID}}
                                       submitEvent={submitEvent}
                                       validator={(values) => {
                                           const errors = {}
                                           if (!values.Accommodation_Tier_Code) {
                                               errors.Accommodation_Tier_Code = 'Required';
                                           }
                                           if (!values.Accommodation_ID) {
                                               errors.Accommodation_ID = 'Required';
                                           }
                                           return errors
                                       }
                                       }>
            <AccommodationTierWithChange destinationID={templateInfo.Destination.ID} required/>
            <AccommodationInput destinationID={templateInfo.Destination.ID} required/>
        </RelatedAddOrEditButton>
    }
    return null;

};

AccommodationAddButton.propTypes = {
    templateInfo: PropTypes.object.isRequired
};

export default AccommodationAddButton;
