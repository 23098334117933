import React from 'react'
import {
    TextInput, NumberInput,
    Create, TabbedForm, FormTab
} from 'react-admin'
import Validator from '../../validators/countryValidator';
import UIContentFormTab from "../../components/forms/UIContentFormTab";
import {Region, Status} from "../../components/refrences/input/RelatedInputs";

export default props => {
    const validator = new Validator().g_cr_var_fui();
    return (
        <Create {...props} >
            <TabbedForm    validate ={validator} redirect='list'>
                <FormTab label="General">
                    <TextInput source="Name" required/>
                    <TextInput source="Code" required/>
                    <NumberInput source="Latitude"/>
                    <NumberInput source="Longitude"/>
                    <NumberInput source="Latitude_Offset"/>
                    <NumberInput source="Longitude_Offset"/>
                    <Region/>
                   <Status source="Status_Code" label='Status' defaultValue='Published'/>
                    <NumberInput source="Sequence"/>
                </FormTab>
                <UIContentFormTab/>
            </TabbedForm>
        </Create>
    )
};
