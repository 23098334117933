import React, {Fragment, useState} from "react";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {
    SimpleForm,useNotify,useRefresh
} from 'react-admin'
import PropTypes from "prop-types";
import SaveCancelButtons from "../../components/Buttons/SaveCancelButtons";
import AddButton from "../../components/Buttons/AddButton";
import EditButton from "../../components/Buttons/EditButton";
import DialogActions from "@material-ui/core/DialogActions";
import SaveButton from "./SaveButton";
import CancelButton from "./CancelButton";
import {GetForm} from "../forms/GetForm";


const RelatedAddOrEditButton = props => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [show, setShow] = useState(false);
    const {record,children,submitEvent,title,validator} = props;
    const [form,setForm] = useState(null);

    const handleOpenClick = () => {
        setShow(true);
    };
    const handleCloseClick = () => {
        setShow(false);
    };

    const handleSubmit = (values) => {
        submitEvent({notify,refresh,values,setShow});
    }

    return (
        <Fragment>
            {record && (record.id||record.ID) ? <EditButton clickEvent={handleOpenClick}/> :
                <AddButton clickEvent={handleOpenClick}/>}
            <Dialog
                disableEnforceFocus
                fullWidth
                open={show}
                onClose={handleCloseClick}
                style={{zIndex: 1}}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <SimpleForm save={handleSubmit}
                                 validate ={validator}
                                record={record}
                                toolbar={null}
                    >
                       {children}
                        <GetForm setForm={setForm}/>
                    </SimpleForm>
                </DialogContent>
                <DialogActions>
                    <SaveButton clickEvent={()=>{
                        form.submit();
                    }}/>
                    <CancelButton clickEvent={handleCloseClick}/>
                </DialogActions>
            </Dialog>
        </Fragment>
    );

};

// required when create.
RelatedAddOrEditButton.propTypes = {
    submitEvent: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired,
    validator:PropTypes.func

}

export default RelatedAddOrEditButton;
