import React, {useEffect, useState} from 'react'
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {Destination} from '../../components/refrences/input/RelatedInputs'
import {SimpleForm, useNotify, useRefresh, SelectInput} from 'react-admin';
import Validator from "./wrapRouteValidator";
import {basePath} from "../../rest-data-provider/paths";
import axios from "axios";
import {notifyError, notifyShow} from "../../ec-admin-adapters/notifyF";
import inputsStyle from "../../theme/InputsStyle";
import {useFormState} from "react-final-form";
import loginOut from "./loginOut";

const theme = createMuiTheme();
const WrapRoute = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();

    function handleSubmit(values) {
        axios.post(basePath + "/routes/wrap", values).then((() => {
                notifyShow(notify, 'success wrap routes!');
                refresh();
            }
        )).catch(error => {
            notifyError(notify, error);
            if(error.response&&error.response.data==='Unauthorized'){
                loginOut(props.history)
            }
            refresh();
        })
    }

    const validator = new Validator().g_cr_var_fui()
    return (
        <MuiThemeProvider theme={theme}>
            <Typography variant="h6" style={{margin: 'auto'}}>
                The Wrap feature will take predefined routes that start or terminate at a given Destination and<br/>
                build new Routes by adding the Transfers "to/from" that Destination. First, select a Destination<br/>
                with Routes that you want to extend to another(e.g. new)Destination. Next, select the<br/>
                Destination that you want to create new Routes for.
            </Typography>
            <Container component="main" maxWidth="xs" style={{marginTop: '50px'}}>
                <CssBaseline/>
                <div className={theme.paper}>
                    <Form save={handleSubmit} validate={validator}/>
                </div>
            </Container>
        </MuiThemeProvider>
    );
}

const ServiceLine = (props) => {
    const formState = useFormState();
    const values = formState.values;
    const {ThroughHub, NewDestination} = values;
    const [choices, setChoices] = useState([]);
    const notify = useNotify();
    useEffect(() => {
        if (ThroughHub && NewDestination) {
            axios.get(basePath + '/transfers/getServiceLineIdentifier?ThroughHub=' + ThroughHub + '&NewDestination=' + NewDestination).then((response) => {
                const uniques ={};
                const co =[];
                response.data.forEach(sl=>{
                    const serviceLine = sl.Service_Line_Identifier;
                    if(serviceLine && !uniques[serviceLine]){
                        uniques[serviceLine] = serviceLine;
                        co.push({id: serviceLine, name: serviceLine});
                    }
                })
                setChoices(co);
            }).catch(error => {
                notifyError(notify, error);
            })
        }else{
            setChoices([]);
        }
    }, [ThroughHub, NewDestination]);
    return <SelectInput {...props} source='Service_Line_Identifier' label='Service Line Identifier'
                        choices={choices}
                        required/>
}


let Form = (props) => {
    const destinationL = inputsStyle().destinationL;
    return <SimpleForm resource="routes" {...props}>
        <Destination customClass={destinationL} source='ThroughHub'
                     label='Wrap All Routes with This Existing Destination' required/>
        <Destination customClass={destinationL} source='NewDestination' label='Create New Routes To/From' required/>
        <ServiceLine className={destinationL}/>
    </SimpleForm>
}


export default WrapRoute;
