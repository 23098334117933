import {useForm, useFormState} from "react-final-form";
import React, {useEffect, useState} from "react";
import {
    getTransferInfoByServiceAndFromDestination,
    findActivityServiceByDestinationAndAccommodationAndActivityCode,
    findTransferServiceByDestinations,
    roomTypeFetchByDestinationIDAndAccommodationID, transferLocationsFetch
} from "../../../rest-data-provider/fetchs/serviceFetch";
import {Service} from "../../../components/refrences/input/NoneStoreRelatedInputs";
import {minutesToTime} from "../../../utils/dateUtil";
import axios from "axios";
import {basePath} from "../../../rest-data-provider/paths";

export default (props)=>{
    const {sort, ...others}=props;
    const form = useForm();
    const formState=useFormState();
    const {Accommodation_ID,Service_Type_Code,Destination_ID,From_Destination_ID,To_Destination_ID} = formState.values;
    const [fetch,setFetch]=useState(null);
    const [fetchTrigger,setFetchTrigger]=useState(null);
    const [selectedCallback,setSelectedCallback] = useState(undefined);


    const values = formState.values;
    const serviceID = values['Service_ID'];

    useEffect(()=>{
        setFetchTrigger(new Date());
        if(Service_Type_Code==='AC'){
            setSelectedCallback(()=>({Service_Line_Identifier})=>{
                if(Service_Line_Identifier){
                    form.change('Service_Line_Identifier',Service_Line_Identifier);
                }
                else
                    form.change('Service_Line_Identifier',null);
            });
            setFetch(()=>{return roomTypeFetchByDestinationIDAndAccommodationID(Destination_ID,Accommodation_ID, sort)});
        }else if(Service_Type_Code==='AT'||Service_Type_Code==='PF'){
            setFetch(()=>{return findActivityServiceByDestinationAndAccommodationAndActivityCode(From_Destination_ID,Accommodation_ID, null, sort)});
            setSelectedCallback(()=>({Included_Note,Service_Line_Identifier})=>{
                if(Included_Note){
                    form.change('Included_Note',Included_Note);
                }
                else
                    form.change('Included_Note',null);
                if(Service_Line_Identifier){
                    form.change('Service_Line_Identifier',Service_Line_Identifier);
                }
                else
                    form.change('Service_Line_Identifier',null);
            });
        }else{
            setFetch(null);
        }
    },[Accommodation_ID,Destination_ID,From_Destination_ID,To_Destination_ID,Service_Type_Code, sort]);

    useEffect(()=>{
         if(Service_Type_Code==='TF'){
             setFetch(()=>{return findTransferServiceByDestinations(From_Destination_ID, To_Destination_ID)});
             form.change('Departure_Location',null);
             form.change('Arrival_Location',null);
             form.change('Duration_Minutes',minutesToTime(null));
             form.change('Transfer_ID',null);
             setSelectedCallback(()=>({id,Service_Line_Identifier})=>{
                 if(id){
                     if(Service_Line_Identifier){
                         form.change('Service_Line_Identifier',Service_Line_Identifier);
                     }
                     else
                         form.change('Service_Line_Identifier',null);
                     // add locations if it's a flight transfer
                     getTransferInfoByServiceAndFromDestination(id,From_Destination_ID, sort).then((transferInfo)=>{
                         const {Duration_Minutes,ID} = transferInfo;
                         if(Duration_Minutes){
                             form.change('Duration_Minutes',Duration_Minutes+' Minutes');
                         }

                         form.change('Transfer_ID',ID);
                         if(ID){
                             // get the transfer info
                             transferLocationsFetch(ID).then(({Departure_Location,Arrival_Location})=>{
                                 form.change('Departure_Location',Departure_Location);
                                 form.change('Arrival_Location',Arrival_Location);
                             }).catch(error=>{
                                 form.change('Departure_Location',error.response.data);
                                 form.change('Arrival_Location',error.response.data);
                             })

                         }
                     })
                 }
             });
        }
    },[From_Destination_ID,To_Destination_ID,Service_Type_Code, sort])

    useEffect(()=>{
        // if(Service_Type_Code==='TF'&& serviceID){
        //     console.log(fromDestinationID);
        //
        // }
        if(Service_Type_Code==='AT'||Service_Type_Code==='PF'){
            if(serviceID){
                axios.get(basePath + '/services/findActivityInfo/' + serviceID).then((resp) => {
                    if(resp.data.length>0){
                        const activityInfo = resp.data[0];
                        form.change('Activity_Name',activityInfo.Activity_Name);
                    }
                })
            }else{
                form.change('Activity_Name',null);
            }

        }
    },[Service_Type_Code,serviceID])

    return  (fetch?<Service {...others}
                            sort={sort}
                            selectedCallback={selectedCallback}
                            fetch={fetch} fetchTrigger={fetchTrigger} required/>:null);
}
