import axios from "axios";
import {basePath} from "../paths";

export const findTiersByDestination=async (destinationID)=>{
    if(destinationID)
        return axios.get(basePath + "/accommodationTiers/findTiersByDestination?Destination_ID="+destinationID).then(((tiers) => {
                return tiers.data.map(tier=>{
                    return {id:tier.Code,value:tier.Name};
                })
            }
        ))
    return [];
}
