import dataProvider from "../index";
import {GET_LIST,GET_ONE} from "react-admin";

export const getList=({referenceObj,sort,pagination,getFilter,field,primaryKey})=>{
    return (searchText)=>{
        let filter = {};
        if(typeof getFilter==="function"){
            filter = getFilter(searchText);
        }
        return  dataProvider(GET_LIST, referenceObj, {
            filter: filter,
            pagination: pagination||{page: 1, perPage: 25},
            sort: sort||(primaryKey&&{field: primaryKey, order: "ASC"})||{field: "id", order: "ASC"}
        }).then(
            (result) => {
                return  result.data.map(record => {
                    return getItem({item:record,primaryKey,field});
                })
            }
        )
    }
}



export const getOne=({referenceObj,field,primaryKey})=>{
    return (referenceValue)=>{
        return  dataProvider(GET_ONE, referenceObj, {
            id: referenceValue,
        }).then(
            (result) => {
                if(result&&result.data&&result.data){
                    return getItem({item:result.data,primaryKey,field});
                }
            }
        )
    }
}


function getItem({item,primaryKey,field}){
    return {id:(field&&item[primaryKey])||item.id,value:(field&&item[field])||item.Name};
}


