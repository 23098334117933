import React, {useEffect} from 'react'
import {
    Edit, NumberInput, DateTimeInput
    , TabbedForm, FormTab, ReferenceManyField, GET_ONE,TextInput
} from 'react-admin'
import Validator from '../../validators/tripTemplateStepValidator'
import EditTitle from "../../components/forms/EditTitle";
import {Destination} from "../../components/refrences/input/RelatedInputs";

import TripTemplateAccommodations from './tripTemplateAccommodations'
import dataProvider from "../../rest-data-provider";
import {CustomBackToolbar} from "../../components/forms/CustomBackToolbar";

function recordToTitle(record) {
    return 'Step (' + (record.Step_Number) + ') of ' + (record.Trip_Template && record.Trip_Template.Name) || 'Unknown';
}

const TripTemplateStepEdit = props => {
    const validator = new Validator().g_up_var_f_ui(props.id)
    const id = props.id;
    const [templateID, setTemplateID] = React.useState(undefined);
    useEffect(() => {
        dataProvider(GET_ONE, 'tripTemplateSteps', {
            id: id
        }).then(
            (result) => {
                if (result && result.data) {
                    setTemplateID(result.data.Trip_Template_ID);
                }
            }
        )
    }, [id]);
    const {history} = props;
    const redirect= '/tripTemplates/' + (templateID) + '/2';

    function back() {
        history.push(redirect)
    }

    return <Edit title={<EditTitle EntityName='TripTemplateStep' recordToTitle={recordToTitle} id={props.id}
                                   resource='tripTemplateSteps'/>}
                 undoable={false} {...props} >
        <TabbedForm  validate ={validator} redirect={redirect}
                    toolbar={<CustomBackToolbar back={back} redirect={redirect}/>}>
            <FormTab label="General">
                <DateTimeInput disabled label='Created' source="Created_Time"/>
                <DateTimeInput disabled label='Modified' source="Modified_Time"/>
                <Destination source='Destination_ID' label='Destination' filter={{Is_Master_Destination: 1}}/>
                <NumberInput source='Days'/>
                <TextInput disabled  source='Step_Number' />
            </FormTab>
            <FormTab label="Accommodation">
                <ReferenceManyField reference="tripTemplateAccommodations" target="Trip_Template_Step_ID"
                                    addLabel={false}
                                    sort={{field: 'ID', order: 'ASC'}}
                >
                    <TripTemplateAccommodations tripTemplateStepID={props.id}/>
                </ReferenceManyField>
            </FormTab>
        </TabbedForm>
    </Edit>
}
export default TripTemplateStepEdit;
