import React, {Fragment, useState} from "react";
import {Dialog, DialogContent, DialogTitle,DialogActions} from "@material-ui/core";
import PropTypes from "prop-types";
import TransferForm from './TransferForm'
import AddButton from "../../../components/Buttons/AddButton";
import SaveButton from "../../../components/Buttons/SaveButton";
import CancelButton from "../../../components/Buttons/CancelButton";

const TransferAddButton= props=>{
    const {routeID,initialValues} = props;
    const [show,setShow]=useState(false);
    const [form,setForm]=useState(undefined);
    function handleCloseClick(){
        setShow(false)
    }
    return (
        <Fragment>
            <AddButton clickEvent={()=>{setShow(true)}}/>
            <Dialog
                disableEnforceFocus
                fullWidth
                open={show}
                onClose={handleCloseClick}
                aria-label="Add Transfer"
            >
                <DialogTitle>Add Transfer</DialogTitle>
                <DialogContent>
                    <TransferForm cancelEvent={handleCloseClick} routeID={routeID} initialValues={initialValues} setForm={setForm}/>
                </DialogContent>
                <DialogActions>
                    <SaveButton clickEvent={()=>{
                        form.submit();
                    }}/>
                    <CancelButton clickEvent={handleCloseClick}/>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
TransferAddButton.propTypes = {
    routeID: PropTypes.string.isRequired
}

export default TransferAddButton;
