import React, {useEffect, useState} from "react";
import {SelectInput,GET_LIST} from 'react-admin';
import {useForm, useFormState} from "react-final-form";
import dataProvider from "../../../rest-data-provider";
import {emptyText} from "../../../constants/constant";

export default props=>{
    const formState = useFormState();
    const form = useForm();
    const Destination_ID = formState.values['Destination_ID'];
    const [choices,setChoices]=useState([]);
    useEffect(()=>{
        const dirty = form.getFieldState('Destination_ID').dirty;
        if (dirty) {
            setChoices([]);
            form.change("Default_Airport_ID",null);
        }
        if(Destination_ID){
            dataProvider(GET_LIST, "destinationsToAirports", {
                filter: {includeName: true, Destination_ID: Destination_ID},
                pagination: {page: 1, perPage: 100},
                sort: {field: "id", order: "ASC"}
            }).then(result => {
                if (result.data && result.data.length > 0) {
                    const airports = result.data.map(record => {
                        return {id: record.Airport_ID, name: record.Airport.Name}
                    });
                    setChoices(airports);
                }
            });
        }
    },[Destination_ID, form]);

    return <SelectInput {...props} source="Default_Airport_ID" label="Default Airport" choices={choices} allowEmpty={true} emptyText={emptyText}/>
}
