import React from 'react'
import {List, downloadCSV, Datagrid, TextField, EditButton, DeleteButton, Filter,} from 'react-admin'
import {unparse as convertToCSV} from 'papaparse/papaparse.min'
import idReducer from '../../ec-admin-adapters/idReducer'
import RouteListActions from './RouteListActions'
import {Destination as DestinationInput, Status} from "../../components/refrences/input/RelatedInputs";
import {Destination} from "../../components/refrences/field/RelatedFields";

const RouteFilter = (props) => (
    <Filter {...props}>
       <Status source="Status_Code" label='Status' alwaysOn  allowEmpty={true}/>
        <DestinationInput source='From_Destination_ID' label='From Master Destination' alwaysOn filter={{isMaster: true}}/>
        <DestinationInput source='To_Destination_ID' label='To Master Destination' alwaysOn filter={{isMaster: true}}/>
    </Filter>
);

const exporter = routes => {
    routes = idReducer(routes)
    const csv = convertToCSV({
        data: routes,
        // fields: ['Final_Transfer_Origin', 'Name', 'Final_Transfer_Destination'] // order fields in the export
    });
    downloadCSV(csv, 'routes  ' + new Date()); // download as 'routes.csv` file
}

const RouteTable = props => {
    return (
        <Datagrid {...props} >
            <TextField source="ID" label="ID"/>
            <Destination source="From_Destination_ID" label="From Destination" />
            <Destination source="To_Destination_ID" label="To Destination" />
            <TextField source="Status_Code" label="Status"/>
            <EditButton/>
            <DeleteButton undoable={false}/>
        </Datagrid>
    )
}

export default props => {
    return (
        <List {...props} filters={<RouteFilter/>} exporter={exporter}
              sort={{ field: 'ID', order: 'ASC' }}
              filterDefaultValues={{Status_Code: 'Published'}}
              actions={<RouteListActions />}
        >
            <RouteTable/>
        </List>
    )
}







