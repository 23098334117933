import baseValidator from './baseValidator'
import meta from '../validateMetas/routesToTransfersValidateMeta'
import {basePath} from "../rest-data-provider/paths";
import {stringify} from "query-string";
import axios from "axios";

class RoutesToTransfersValidator extends baseValidator {
    meta = meta
    uiMeta={
        resources: "routesToTransfers",
    }

    constructor() {
        super();
        this.g_cr_var_fui = super.g_cr_var_fui.bind(this);
        this.g_up_var_f_ui = super.g_up_var_f_ui.bind(this);
        this.uniqueValidate = this.uniqueValidate.bind(this);
    }

    async uniqueValidate(values,primaryValue){
        const {uniqueFields} = this.meta;
        const {resources} = this.uiMeta;
        const diffPath = primaryValue ? ("/edit/"+primaryValue+"?"):  "/create/?";
        if(uniqueFields){
            const uniqueValues ={};
            uniqueFields.forEach(field=>{
                uniqueValues[field]=values[field];
            })
            const url = basePath + "/" + resources + "/validate"+diffPath + stringify(uniqueValues)+"&Route_ID="+values.Route_ID+"&ID="+values.ID;

            return axios.get(url).then(function (response) {
                if (Object.keys(response.data).length > 0) {
                    return response.data;
                }
            })
        }
        return {};
    }
}

export default RoutesToTransfersValidator;




