import serviceValidateMeta from "./serviceValidateMeta";
export default {
    primaryKey:'ID',
    commonValidate: {
        Accommodation_ID: ['Required',{isInt:{min: 1}}],
        Name: ['Required',{isLength:{max: 100}}],
        Room_Type_Code:['Required',{isLength:{min: 6,max:6}}],
        Service_Line_Identifier:[{isLength:{max: 100}}],
        ...serviceValidateMeta
    },
}



