import {
    CHANGE_TRANSFER_FROM_DESTINATION_FOR_ROUTE
} from '../constants/actionTypes'

export default function(state ={} ,action){
 switch (action.type) {
     case CHANGE_TRANSFER_FROM_DESTINATION_FOR_ROUTE:
         return {...state,transfersForRoutes:action.transfers};
     default:
         return state;
 }
}
