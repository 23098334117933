import React from 'react'
import {
    TabbedForm,FormTab,TextInput,
    Create,BooleanInput
} from 'react-admin'
import Validator from '../../validators/accommodationRoomTypeValidator';
import Rates from "../rates";
import {VendorSubsAccommodation} from "../services/VendorSubsAccommodation";
import {Accommodation} from "../../components/refrences/input/RelatedInputs";
import AccommodationRoomServiceLine from "./AccommodationRoomServiceLine";

export default props => {
    const validator = new Validator().g_cr_var_fui();
    return (
        <Create title='Create Accommodation Room Type' {...props}>
            <TabbedForm  validate ={validator} redirect='list'>
                <FormTab label="General">
                    <TextInput source="Name" required/>
                    <Accommodation/>
                    <VendorSubsAccommodation source="Service.Vendor_ID" label='Vendor Name'/>
                    <BooleanInput source='Is_Default_for_Accommodation' label='Is Default For Accommodation' defaultValue={true}/>
                    <TextInput source='Room_Type_Code' label='Room Type Code'/>
                    <AccommodationRoomServiceLine/>
                </FormTab>
                <FormTab label="Service" >
                    <TextInput source="Service.Name" label="Name" required/>
                    <TextInput source="Service.Service_Type_Code" label='Service Type Code' defaultValue='AC' disabled />
                    <TextInput source='Room_Type_Code' label='Room Type Code'/>
                    <TextInput source='Service_Line_Identifier' label='Service Line Identifier'/>
                    <BooleanInput source="Service.Priced" label='Priced' defaultValue={false}/>
                    <BooleanInput source="Service.Needs_Update" label='Needs Update' defaultValue={false}/>
                </FormTab>
                <FormTab label="Rates">
                    <Rates/>
                </FormTab>
            </TabbedForm>
        </Create>
    )
}
