import React, {useEffect} from "react";
import {Special} from "../../components/refrences/input/RelatedInputs";
import dataProvider from "../../rest-data-provider";
import {GET_ONE,useNotify} from 'react-admin'
import {useForm} from "react-final-form";
import {notifyError} from "../../ec-admin-adapters/notifyF";

const DefaultAccommodationSpecial = (props)=>{
    const form = useForm();
    const notify = useNotify();
    useEffect(()=>{
        if(!props.record.ID){
            dataProvider(GET_ONE,'specials',{id:1}).then((result)=>{
                if(result&&result.data){
                    form.change( 'Start_Date', result.data.Start_Date);
                    form.change( 'End_Date', result.data.End_Date);
                    form.change( 'Name', result.data.Name);
                    form.change( 'Special_ID', result.data.ID);
                }
            }).catch(error=>{
                notifyError(notify,error);
            })
        }
    },[])
    return (
        <Special  {...props} label='Special Type'/>
    )
};

export default DefaultAccommodationSpecial;
