import React, {useEffect} from "react";
import {NumberInput,CREATE,TextInput } from 'react-admin'
import dataProvider from "../../../rest-data-provider";
import PropTypes from "prop-types";
import axios from "axios";
import {basePath} from "../../../rest-data-provider/paths";
import {Destination} from "../../../components/refrences/input/RelatedInputs";
import {notifyError} from "../../../ec-admin-adapters/notifyF";
import {useFormState,useForm} from 'react-final-form';
import RelatedAddOrEditButton from "../../../components/Buttons/RelatedAddOrEditButton";



const TripTemplateStepAddButton = props=>{
    const {tripTemplateID} = props;
    const submitEvent=({notify,refresh,values,setShow})=>{
        dataProvider(CREATE, 'tripTemplateSteps', {data: values}).then(() => {
            refresh();
            setShow(false);
        }).catch(error => {
            notifyError(notify,error);
        })
    }
    return <RelatedAddOrEditButton title='Add Template Step' record={{Trip_Template_ID:tripTemplateID}} submitEvent={submitEvent}>
        <Destination source='Destination_ID' label='Destination' filter={{Is_Master_Destination: 1}}/>
        <NumberInput source='Days'/>
        <StepNumber/>
    </RelatedAddOrEditButton>
}

const StepNumber = props=>{
    const form = useForm();
    const formState = useFormState();
    const tripTemplateID = formState.values['Trip_Template_ID'];
    useEffect(()=>{
        axios.get(basePath + '/tripTemplateSteps/getMaxStepNumber/'+tripTemplateID).then((response) => {
                form.change('Step_Number',response.data === null ? 0 : response.data + 1);
            })
    },[tripTemplateID,form]);
    return <TextInput {...props} disabled  source='Step_Number'/>
}

TripTemplateStepAddButton.propTypes ={
    tripTemplateID: PropTypes.string.isRequired
};

export default TripTemplateStepAddButton;
