import React from "react";
import dataProvider from '../../rest-data-provider'
import {GET_ONE} from 'react-admin';
import {Activity} from "../../components/refrences/input/RelatedInputs";
import {useForm} from "react-final-form";
import {activityUIContent} from "../common/changes";

const ActivitySelectInput = (props) => {
    const form = useForm();
    // function changeUIContent(UIContent) {
    //     if (UIContent) {
    //         Object.keys(UIContent).forEach(key => {
    //             form.change('UI_Content.' + key, UIContent[key]);
    //         })
    //     } else {
    //         const UIContentFields = ['Page_Title', 'Subtitle', 'Included_Note', 'Available_Note', 'Meta_Description', 'Description', 'Comments', 'Keywords']
    //         for (let key of UIContentFields) {
    //             form.change( 'UI_Content.' + key, null);
    //         }
    //     }
    // }
    //
    // function handleChangeActivity(value) {
    //     if (value) {
    //         dataProvider(GET_ONE, 'activities', {id: value})
    //             .then(({data}) => {
    //                 form.change('Name', data.Name);
    //                 form.change('Name', data.Name);
    //                 changeUIContent(data.UI_Content);
    //             })
    //     } else {
    //         form.change('Name', null);
    //         changeUIContent(undefined);
    //     }
    // }

    function handleChangeActivity(value){
        activityUIContent(value,form);
    }

    return (
        <Activity allowEmpty {...props} onChange={handleChangeActivity}/>
    )
}



export default ActivitySelectInput;
