import BushField from "../../components/forms/BushField";
import axios from "axios";
import {basePath} from "../../rest-data-provider/paths";
import React from "react";
import {TextInput} from 'react-admin';
import {notifyError} from "../../ec-admin-adapters/notifyF";

export default props => {
    return (
        <BushField subsField='Destination_ID' subsEvent={({value, form, notify}) => {
            if (value) {
                axios.get(basePath + "/destinations/findCountry/" + value).then(((country) => {
                        form.change('Country', country.data.Name)
                    }
                )).catch(error=>{
                    notifyError(notify,error);
                })
            }
        }}>
            <TextInput {...props} disabled source='Country'/>
        </BushField>
    )

}
