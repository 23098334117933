import React, {Fragment, useState} from "react";
import {GET_ONE, useNotify, Confirm,DELETE} from 'react-admin'
import dataProvider from "../../../rest-data-provider";
import {notifyError, notifyShow} from "../../../ec-admin-adapters/notifyF";

import {Button} from "@material-ui/core";
import {Delete} from "@material-ui/icons";
import axios from "axios";
import {basePath} from "../../../rest-data-provider/paths";

const ReservationDeleteButton = (props) => {
    const {row, Trip_Step_ID, steps, setSteps} = props;
    const [show, setShow] = useState(false);
    const notify = useNotify();
    const submitEvent = () => {
        const ID = row['ID'];
        setShow(false);
        dataProvider(DELETE, 'reservations', {id: ID}).then(() => {
            axios.get(basePath + '/reservations/getStepList/' + Trip_Step_ID).then((response) => {
                steps[Trip_Step_ID]['Reservations']=response.data;
                setSteps({...steps});
              notifyShow(notify,'Delete Reservation successfully');
            })
        }).catch(error => {
            notifyError(notify, error);
        })
    };

    return <Fragment>
        <Button style={{color: "#f44336"}} onClick={() => setShow(true)}>
            <Delete/>Remove
        </Button>
        <Confirm title={`Remove Reservation (${row.Sequence}) from step (${steps[Trip_Step_ID]['Step_Number']})`} content={""} onClose={() => setShow(false)} onConfirm={submitEvent}
                 isOpen={show}/>
    </Fragment>
};


export default ReservationDeleteButton;
