import dataProvider from "../../rest-data-provider";
import {GET_LIST} from 'react-admin';

export default  async(serviceLine)=>{
    if (serviceLine && serviceLine.length >= 11) {
        const code = serviceLine.substring(5, 11);
        return dataProvider(GET_LIST, 'vendors', {
            filter: {Code: code},
            pagination: {page: 1, perPage: 1},
            sort: {field: "id", order: "ASC"}
        }).then(
            (result) => {
                if (result && result.data && result.data.length) {
                    return result.data[0];
                }
            }
        )
    }
}
