
export default {
    primaryKey:'ID',
    commonValidate: {
        Step_Number: ['Required',{isInt:{min:0,max:4294967295 }}],
        Destination_ID: ['Required',{isInt:{min:1,max:4294967295 }}],
        Days: ['Required',{isInt:{min:0,max:4294967295 }}],
    }
}



