import React from 'react'
import {
    List, downloadCSV, Datagrid, TextField, EditButton, DeleteButton,
    Filter,
} from 'react-admin'
import {unparse as convertToCSV} from 'papaparse/papaparse.min'
import idReducer from '../../ec-admin-adapters/idReducer'


const RoomTierFilter = (props) => (
    <Filter {...props}>
    </Filter>
);

const exporter = roomTiers => {
    roomTiers = idReducer(roomTiers)
    const csv = convertToCSV({
        data: roomTiers,
        // fields: ['Final_Transfer_Origin', 'Name', 'Final_Transfer_Destination'] // order fields in the export
    });
    downloadCSV(csv, 'accommodationTiers  ' + new Date()); // download as 'accommodationTiers.csv` file
}

const RoomTierTable = props => {
    return (
        <Datagrid {...props} >
            <TextField source="Sequence"/>
            <TextField source="Name"/>
            <EditButton/>
            <DeleteButton undoable={false}/>
        </Datagrid>
    )
}

export default props => {

    return (
        <List {...props} filters={<RoomTierFilter/>} exporter={exporter}
              sort={{ field: 'Sequence', order: 'ASC' }}
              title='Accommodation Tiers'
        >
            <RoomTierTable/>
        </List>
    )
}







