import React, {Fragment, useState} from "react";
import {Dialog, DialogContent, DialogTitle,DialogActions} from "@material-ui/core";
// import PropTypes from "prop-types";
import TransferForm from './TransferForm'
import EditButton from "../../../components/Buttons/EditButton";
import SaveButton from "../../../components/Buttons/SaveButton";
import CancelButton from "../../../components/Buttons/CancelButton";

const TransferEditButton= props=>{
    const {record} = props;
    const {Transfer,Sequence,Is_Overnight,ID,Route_ID,Status_Code} = record;
    const [show,setShow]=useState(false);
    const [form,setForm]= useState(undefined);
    function handleCloseClick(){
        setShow(false)
    }
    function handleOpenClick(){
        setShow(true);
    }

    return (
        <Fragment>
            <EditButton clickEvent={handleOpenClick}/>
            <Dialog
                disableEnforceFocus
                fullWidth
                open={show}
                onClose={handleCloseClick}
                aria-label="Add Transfer"
            >
                <DialogTitle>Edit Transfer</DialogTitle>
                <DialogContent>
                    <TransferForm cancelEvent={handleCloseClick} initialValues={{...Transfer,Sequence,Is_Overnight,ID,Route_ID,Status_Code}} setForm={setForm}/>
                </DialogContent>
                <DialogActions>
                    <SaveButton clickEvent={()=>{
                        form.submit();
                    }}/>
                    <CancelButton clickEvent={handleCloseClick}/>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}

export default TransferEditButton;
