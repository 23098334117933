export default {
    primaryKey:'ID',
    uniqueFields: ['Sequence'],
    commonValidate: {
        Route_ID: ['Required',{isInt:{min: 1}}],
        From_Destination_ID: ['Required',{isInt:{min: 1}}],
        To_Destination_ID: ['Required',{isInt:{min: 1}}],
        Transfer_Mode_Code:['Required',{isLength:{max:15}}],
        Service_Line_Identifier:['Required',{isLength:{max:100}}],
        Sequence:['Required',{isInt:{min:0,max:255}}]
    }
}



