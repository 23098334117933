import React from 'react'
import {
    Edit, TextInput, DateTimeInput, SimpleForm,NumberInput
} from 'react-admin'
import Validator from '../../validators/pricingFactorValidator'
import EditTitle from "../../components/forms/EditTitle";

export default props => {
    const validator = new Validator().g_up_var_f_ui(props.id)
    return <Edit  title={<EditTitle EntityName='PricingFactor' id={props.id} resource='pricingFactors'/>} undoable={false} {...props} >
        <SimpleForm  validate ={validator}>
            <DateTimeInput disabled label='Created' source="Created_Time"/>
            <DateTimeInput disabled label='Modified' source="Modified_Time"/>
            <TextInput source="Code" required disabled/>
            <TextInput source="Sequence" disabled/>
            <TextInput source="Type" disabled/>
            <TextInput source="PCT_Type" disabled label='PCT Type'/>
            <TextInput source="Target" disabled/>
            <NumberInput source="Amount" required/>
            <TextInput fullWidth multiline source="Description" disabled/>
        </SimpleForm>

    </Edit>
}
