import React, {useEffect, useState} from "react";
import {CREATE,DateInput,useNotify} from 'react-admin'
import dataProvider from "../../../rest-data-provider";
import PropTypes from "prop-types";
import {Milestone} from "../../../components/refrences/input/NoneStoreRelatedInputs";
import {notifyError} from "../../../ec-admin-adapters/notifyF";
import Validator from '../../../validators/packagesToMilestonesValidator';
import RelatedAddOrEditButton from "../../../components/Buttons/RelatedAddOrEditButton";
import axios from "axios";
import {basePath} from "../../../rest-data-provider/paths";


const MilestoneAddButton =(props)=>{
    const {packageID} = props;
    const [nextCode,setNextCode]=useState(null);
    const notify = useNotify();
    const submitEvent = ({notify,refresh,values,setShow})=>{
        dataProvider(CREATE, 'packagesToMilestones', {data: values}).then(() => {
            refresh();
            setShow(false);
        }).catch(error => {
            notifyError(notify,error);
        })
    };

    useEffect(() => {
        axios.get(basePath + '/packagesToMilestones/nextOneMilestone/' +packageID).then((response) => {
            setNextCode(response.data);
        }).catch(error => {
            notifyError(notify,error);
        })
    }, [packageID,notify]);

    const validator = new Validator().g_cr_var_fui();
    return <RelatedAddOrEditButton record={{Date:new Date(),Reservation_Package_ID:packageID,Reservation_Milestone_Type_Code:nextCode}}  title='Add Milestone' submitEvent={submitEvent} validator={validator}>
        <Milestone required/>
        <DateInput source='Date'/>
    </RelatedAddOrEditButton>
};

MilestoneAddButton.propTypes = {
    packageID: PropTypes.string.isRequired
};

export default MilestoneAddButton;
