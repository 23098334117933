import React from 'react'
import {
    List, downloadCSV, Datagrid, TextField, EditButton, DeleteButton,
    Filter,DateInput
} from 'react-admin'
import {unparse as convertToCSV} from 'papaparse/papaparse.min'
import idReducer from '../../ec-admin-adapters/idReducer'
import {Accommodation,Special} from '../../components/refrences/field/RelatedFields';
import DateZoneField from "../../components/Fields/DateZoneField";
import {Accommodation as AccommodationInput} from '../../components/refrences/input/RelatedInputs';

const AccommodationsToSpecialsFilter = (props) => (
    <Filter {...props}>
        <DateInput source='Start_Date' alwaysOn/>
        <DateInput source='End_Date' alwaysOn/>
        <AccommodationInput source='Accommodation_ID' alwaysOn/>
    </Filter>
);

const exporter = AccommodationsToSpecials => {
    AccommodationsToSpecials = idReducer(AccommodationsToSpecials)
    const csv = convertToCSV({
        data: AccommodationsToSpecials,
        // fields: ['Final_Transfer_Origin', 'Name', 'Final_Transfer_Destination'] // order fields in the export
    });
    downloadCSV(csv, 'AccommodationsToSpecials  ' + new Date()); // download as 'AccommodationsToSpecials.csv` file
}

const AccommodationsToSpecialsTable = props => {
    return (
        <Datagrid {...props} >
            <Accommodation label='Accommodation'source='Accommodation_ID'/>
            <TextField source="Name"/>
            <DateZoneField source='Start_Date'/>
            <DateZoneField source='End_Date'/>
            <EditButton/>
            <DeleteButton undoable={false}/>
        </Datagrid>
    )
}

export default props => {

    return (
        <List {...props} filters={<AccommodationsToSpecialsFilter/>} exporter={exporter}
              sort={{ field: 'ID', order: 'ASC' }}
              title='Accommodation Specials'
        >
            <AccommodationsToSpecialsTable/>
        </List>
    )
}







