import React from "react";
import {UPDATE,CREATE,NumberInput,TextInput} from 'react-admin'
import dataProvider from "../../../rest-data-provider";
import {notifyError} from "../../../ec-admin-adapters/notifyF";
import Validator from '../../../validators/tripDayValidator';
import RelatedEditButton from "../../../components/Buttons/MergeButton";


const TripDayEditButton =(props)=>{
    const {record,totalDays} = props;
    const submitEvent = ({notify,refresh,values})=>{
        if(values.id>0){
            dataProvider(UPDATE, 'tripDays', {data: values,id:values.id}).then(() => {
                refresh();
            }).catch(error => {
                notifyError(notify,error);
            })
        }else{
            const theValues = {...values};
            delete theValues.ID ;
            delete theValues.id ;
            dataProvider(CREATE, 'tripDays', {data: theValues}).then(() => {
                refresh();
            }).catch(error => {
                notifyError(notify,error);
            })
        }

    };
    const validator = record && record.ID>0 ? new Validator().g_up_var_f_ui(record.ID):new Validator().g_cr_var_fui();
    const validate = (values)=>{
        const Day_Number = values['Day_Number'];
        if(Day_Number>totalDays){
            return {Day_Number:"Day Number should not over total days : "+ totalDays};
        }
        return validator(values);
    }

    return <RelatedEditButton record={record}  title='Edit Trip Day' submitEvent={submitEvent} validator={validate}>
        <NumberInput source='Day_Number' disabled/>
        <TextInput  fullWidth multiline source='Notes' />
        <TextInput  fullWidth multiline source='Remarks' />
    </RelatedEditButton>
};

export default TripDayEditButton;
