import React from 'react'
import {
    Edit, DateTimeInput
    , TabbedForm, FormTab,ReferenceManyField
} from 'react-admin'
import Validator from '../../validators/routeValidator'
import EditTitle from "../../components/forms/EditTitle";
import Transfers from "./transfers";
import {Destination as DestinationInput, Destination, Status} from "../../components/refrences/input/RelatedInputs";

const recordToTitle=(record,resources)=>{
    const {From_Destination_ID,To_Destination_ID} = record;
    const destinations = resources.destinations.data;
    return ((destinations[From_Destination_ID]&&destinations[From_Destination_ID].Name)||'Unknown') +" To "+ ((destinations[To_Destination_ID]&&destinations[To_Destination_ID].Name)||'Unknown');
}

export default props => {
    const validator = new Validator().g_up_var_f_ui(props.id)
    return <Edit title={<EditTitle EntityName='Route' id={props.id} resource='routes'recordToTitle={recordToTitle}/>} undoable={false} {...props} >
        <TabbedForm  validate ={validator} submitOnEnter={false}>
            <FormTab label="General">
                <DateTimeInput disabled label='Created' source="Created_Time"/>
                <DateTimeInput disabled label='Modified' source="Modified_Time"/>
                <Destination label='From Master Destination' source='From_Destination_ID' filter={{isMaster: true}}/>
                <Destination label='To Master Destination' source='To_Destination_ID' filter={{isMaster: true}}/>
               <Status source="Status_Code" label='Status' defaultValue='Published'/>
            </FormTab>
            <FormTab label="Transfers">
                <ReferenceManyField reference="routesToTransfers" target="Route_ID" addLabel={false} sort={{order:'ASC',field:'ID'}}>
                    <Transfers routeID={props.id} history={props.history}/>
                </ReferenceManyField>
            </FormTab>

        </TabbedForm>

    </Edit>
}
