import React from "react";
import {Cancel} from "@material-ui/icons";
import {Button} from "@material-ui/core";
import PropTypes from "prop-types";

const CancelButton  = ({clickEvent}) =>{
    return  (
        <Button
            label="accommodation.action.cancel"
            onClick={clickEvent}
        >
            <Cancel/>Cancel
        </Button>
    )
}




CancelButton.propTypes = {
    clickEvent: PropTypes.func.isRequired
}

export default CancelButton;
