import React, {useState,Fragment} from 'react'
import {Button, Radio, createMuiTheme, FormControlLabel, FormGroup, MuiThemeProvider} from '@material-ui/core'
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import {basePath} from "../../rest-data-provider/paths";
import UploadTemplatesButton from "./UploadTemplatesButton";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import auth from "../auth/AuthProvider";
import UploadFile from "../../components/UploadFile";
import PDFSettingAddOrEditButton from "./PDFSettingAddOrEditButton";

const theme = createMuiTheme();
const checkLabels = [
    "Sample Itinerary - Boilerplate",
    "Final Itinerary - Boilerplate",
    "Summary of Services - Boilerplate",
    "Footer",
    "Common CSS"
];
const subPaths = [
    "preliminary",
    "final",
    "summaryOfServices",
    "footer",
    "css"
];

const Templates = (props) => {
    const { setTemplateID } = props;
    const [ selectedLabel, setSelectedLabel ] = useState(0);
    const changeTemplate = (id) => {
        setSelectedLabel(id);
        setTemplateID(id);
    };
    return (
        <MuiThemeProvider theme={theme}>
            <Typography variant="h5" style={{padding: '20px', display: 'flex'}}>
                Templates:
                <FormGroup style={{padding:'0 20px'}}>
                    <p style={{margin: '0.25rem', paddingLeft: '1.75rem', fontSize: '1.25rem'}}>Document / Component</p>
                    {checkLabels.map((val, id) => (
                        <FormControlLabel
                            key={id}
                            checked={selectedLabel === id}
                            control={<Radio />}
                            onChange={() => changeTemplate(id)}
                            label={val}
                        />
                    ))}
                    <ButtonGroup  variant="outlined" style={{marginLeft: '10px'}} >
                        <Button href={`${basePath}/docTemplates/downloadDocs/${subPaths[selectedLabel]}&${auth.getAgency()}`} download>Download</Button>
                        <UploadTemplatesButton  subpath={subPaths[selectedLabel]}/>
                    </ButtonGroup>
                </FormGroup>
                <div>
                    <p style={{margin: '0.25rem', fontSize: '1.25rem'}}>PDF Settings:</p>
                    <PDFSettingAddOrEditButton />
                </div>
            </Typography>
        </MuiThemeProvider>
    );
}

const Logo = (props) => {
    const { type, logoData, setLogoData, code } = props;
    const [show, setShow] = useState(false);
    const path = logoData ? logoData.path + logoData[type] : null;
    
    const style = {width: type === 'First_Page_Logo' ? '500px' : '200px', display: 'grid', direction: 'rtl', padding: '0 20px', textAlign: 'center'};

    return (
        <MuiThemeProvider theme={theme}>
            <Typography variant="h5" style={{padding: '20px', display: 'flex'}}>
                {type.replaceAll('_', ' ')}:
                <div style={style}>
                    <img src={path} alt="No Logo file" width="100%" />
                    <Button variant="outlined" style={{width: '100px', marginTop: '20px'}} onClick={() => setShow(true)}>
                        Replace
                    </Button>
                    <UploadFile fileLimit={1} show={show} setShow={setShow} fileTypes={['image/jpeg', 'image/png', 'image/bmp']} saveEvent={
                        (files) => {
                            if(files.length===1) {
                                const url = basePath + '/tools/uploadImage';
                                const formData = new FormData();
                                formData.append('myImage', files[0]);
                                formData.append('agency', auth.getAgency());
                                formData.append('type', type);
                                formData.append('code', code);
                                const config = {
                                        headers: {
                                        'content-type': 'multipart/form-data'
                                    }
                                }
                                axios.post(url, formData, config).then((res) => {
                                    setLogoData(null);
                                }).catch(error => {
                                    console.log(error);
                                });
                                setShow(false);
                            }
                        }
                    }/>
                </div>
            </Typography>
        </MuiThemeProvider>
    )
}

const Tool = props=>{
    const [templateID, setTemplateID] = useState(0);
    const [logoData, setLogoData] = useState(null);
    React.useEffect(() => {
        if(logoData === null) {
            axios.post(basePath + '/docTemplates/getLogoData', {code: subPaths[templateID], agency: auth.getAgency()}).then((res) => {
                setLogoData(res.data);
            }).catch(error => {
                console.log(error);
            })
        }
    }, [logoData, templateID]);
    return <Fragment>
        <Templates {...props} setTemplateID={setTemplateID}/>
        <Logo type="First_Page_Logo" code={subPaths[templateID]} logoData={logoData} setLogoData={setLogoData} {...props}/>
        <Logo type="Footer_Logo" code={subPaths[templateID]} logoData={logoData} setLogoData={setLogoData} {...props}/>
    </Fragment>
}

export default Tool;
