import React, {Fragment, useState} from "react";
import {Button,Menu, MenuItem} from "@material-ui/core";
import {useNotify} from 'react-admin'
import axios from "axios";
import {basePath} from "../../rest-data-provider/paths";

import UploadFile from "../../components/UploadFile";
import {notifyError, notifyShow} from "../../ec-admin-adapters/notifyF";
import auth from "../auth/AuthProvider";

const UploadTemplatesButton = (props) => {
    const { subpath } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [show, setShow] = useState(false);
    const [action, setAction] = useState("");

    const notify = useNotify();

    const openMenu = event => {
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };

    const handleOpenClick = (action) => {
        closeMenu();
        setAction(action);
        setShow(true);
    };

    return (
        <Fragment>
            <Button {...props} onClick={() => handleOpenClick(subpath)} label="ra.action.create">
                Upload
            </Button>
            <UploadFile fileLimit={1} show={show} setShow={setShow} fileTypes={['text/html','text/css']} saveEvent={
                (files) => {
                    if(files.length===1){
                        const formData = new FormData();
                        formData.append("file", files[0]);
                        formData.append("agency", auth.getAgency());
                        axios.put(`${basePath}/docTemplates/${action}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then(()=>{
                            notifyShow(notify,'upload successfully')
                            setShow(false);
                        }).catch(error=>{
                            notifyError(notify,error)
                        })
                    }else{
                        const formData = new FormData();
                        formData.append("action", action);
                        for(let i = 0; i<files.length;i++){
                            formData.append(`files[${i}]`, files[i]);
                        }
                        axios.post(basePath + "/trips/uploadDocs", formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).catch(error=>{
                            notifyError(notify,error)
                        })
                    }
                }
            }/>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                    closeMenu()
                }}
            >
                <MenuItem onClick={() => {
                    handleOpenClick('preliminary');
                }}>Sample Itinerary</MenuItem>
                <MenuItem onClick={() => {
                    handleOpenClick('final');
                }}>Final Itinerary</MenuItem>
                <MenuItem onClick={() => {
                    handleOpenClick('summaryOfServices');
                }}>Summary of Services</MenuItem>
                <MenuItem onClick={() => {
                    handleOpenClick('footer');
                }}>Footer</MenuItem>
                <MenuItem onClick={() => {
                    handleOpenClick('Css');
                }}>Common Css</MenuItem>
            </Menu>
        </Fragment>
    )
}
export default UploadTemplatesButton;
