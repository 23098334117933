import React, {Fragment} from "react";
import {
   Datagrid, TextField
} from 'react-admin';
import RateAddOrEditButton from "./RateAddOrEditButton";
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types'
import CustomDeleteButton from "../../components/Buttons/CustomDeleteButton";
import BooleanFieldWithNumber from "../../components/Fields/BooleanFieldWithNumber";
import DateZoneField from "../../components/Fields/DateZoneField";

const useStyles = makeStyles({
    buttons: {
        display:'flex',
        'justify-content':'space-between'
    },
});

function recordToTitle(record){
    return 'Remove Rate ('+(record&&record.Rack_USD)+') from Service?';
}

const MarginField = props=>{
    const {record} = props;
    if(record === undefined) return null;
    const {Base_Net,Base_Rack} = record;
    record.Margin = (((Base_Rack - Base_Net) / Base_Rack)*100).toFixed(2);
    return <TextField {...props} />;
}

const Rates = (props) => {
    const classes = useStyles();
    const {serviceID,accommodationID,vendorID,...others} = props;

    return (
        <Fragment>
            <div className={classes.buttons}>
                <span/>
                <RateAddOrEditButton {...props} />
            </div>

            <Datagrid style={{"width":"100%"}} {...others}  >
                <DateZoneField source="Start_Date" label='Start Date'/>
                <DateZoneField source="End_Date" label='End Date'/>
                <TextField source="Currency_Code" label='Currency Code'/>
                <TextField source="Base_Net" label='Base Net'/>
                <TextField source="Base_Rack" label='Base Rack'/>
                <TextField source="Rack_USD" label='Rack USD'/>
                <MarginField source="Margin" label='Margin %' sortable={false}/>
                <BooleanFieldWithNumber source="Is_Gratuity_Included" label="Is Gratuity Included" />
                <TextField source="Status_Code"/>
                <TextField source="Rate_Status_Code"/>
                <RateAddOrEditButton  serviceID={serviceID}/>
                <CustomDeleteButton recordToTitle={recordToTitle}/>
            </Datagrid>
        </Fragment>
    )
}
Rates.propTypes ={
    serviceID: PropTypes.number.isRequired,
    accommodationID:PropTypes.number,
    vendorID:PropTypes.number,
}


export default Rates;
