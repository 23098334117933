import {compareAsc,parse,differenceInDays,addDays as addDay,format,differenceInMinutes,isValid} from 'date-fns';


const defaultFormat = 'yyyy-MM-dd';
const defaultDateTimeFormat = 'yyyy-MM-dd HH:mm';
export const normalCompare = (DateOneStr, DateTwoStr,format=defaultFormat)=>{
    if(!DateOneStr||!DateOneStr){
        return true;
    }
    const backupDate = new Date();
    const DateOne = parse(DateOneStr,format,backupDate);
    const DateTwo = parse(DateTwoStr,format,backupDate);
    return compareAsc(DateOne,DateTwo)>0;
}

//only support format : yyyy-MM-dd
export const getDays=(DateOneStr,DateTwoStr,format=defaultFormat)=>{
    if(!DateOneStr||!DateOneStr){
        return '';
    }
    const backupDate = new Date();
    const DateOne = parse(DateOneStr,format,backupDate);
    const DateTwo = parse(DateTwoStr,format,backupDate);
    return differenceInDays(DateTwo,DateOne)
}

export const convertToDate=(dateStr,format=defaultFormat)=>{
    if(!format)
        format = defaultFormat;
    const date = parse(dateStr,format,new Date());
    return date;
}

export const addDays=(date,days)=>{
    return addDay(date,days);
}

export const formatDate= (date,formatStr)=>{
    return format(date,formatStr||defaultFormat);
}


export function getTime(date1Str, date2Str,formatStr=defaultDateTimeFormat){
    // const format = 'HH:mm';
    const date1 = convertToDate(date1Str,formatStr);
    const date2 = convertToDate(date2Str,formatStr);
    if(isValid(date1)&&isValid(date2)){
        const diff = differenceInMinutes(date2,date1);
        return minutesToTime(diff);
    }
    return '';
}



export function minutesToTime(minutes){
    minutes = parseInt(minutes);
    if(minutes>0){
        let d = parseInt(minutes/1440);
        minutes = minutes%1440;
        let h = parseInt(minutes/60);
        let m = minutes%60;
        let time ='';
        if(d>0){
            if(d===1)
                time+='1 day ';
            else{
                time+=`${d} days `;
            }
        }
        if(h>0){
            if(h===1)
                time+='1 hour ';
            else{
                time+=`${h} hours `;
            }
        }
        if(m>0){
            if(m===1)
                time+='1 minute ';
            else{
                time+=`${m} minutes `;
            }
        }
        return time;
    }
}
