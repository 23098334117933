import uiContentValidateMeta from './uiContentValidateMeta'
export default {
    primaryKey:'Code',
    uniqueFields: ['Name','Code'],
    commonValidate: {
        Code: ['Required',{isLength:{max: 15}}],
        Name: ['Required',{isLength:{max: 100}}],
        Status_Code: ['Required',{isLength:{max: 15}}],
        Sequence: [{isInt:{min:1,max: 255}}],
        Latitude: [{isFloat:{min:-999999.999999,max:999999.999999}}],
        Longitude: [{isFloat:{min:-999999.999999,max:999999.999999}}],
        ...uiContentValidateMeta
    }
}



