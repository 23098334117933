export default {
    primaryKey:'ID',
    commonValidate: {
        Code: [{isLength:{max: 100}}],
        Price: ['Required',{isFloat:{min:0.0,max:999999.999999}}],
        Vendor_Invoice_Number: [{isLength:{max: 100}}],
        Quote_Number: [{isLength:{max: 100}}],
    },
}



