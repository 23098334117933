import {Vendor} from "../../components/refrences/input/NoneStoreRelatedInputs";
import React, {useEffect} from "react";
import {useNotify} from 'react-admin';
import {useForm, useFormState} from "react-final-form";
import {notifyError} from "../../ec-admin-adapters/notifyF";
import getVendorByServiceLine from "./getVendorByServiceLine";


export const VendorSubsServiceLine = props => {
    const formState = useFormState();
    const form = useForm();
    const notify = useNotify();
    const lineIdentifier = formState.values['Service_Line_Identifier'];
    useEffect(() => {
        if (lineIdentifier) {
            getVendorByServiceLine(lineIdentifier).then(vendor => {
                if (vendor) {
                    form.change('Service.Vendor_ID', vendor.ID);
                    form.change('Service.Vendor_Name',vendor.Name);
                }else{
                    form.change('Service.Vendor_ID', null);
                    form.change('Service.Vendor_Name',null);
                }
            }).catch(error => {
                notifyError(notify, error);
            })
        }else{
            form.change('Service.Vendor_ID', null);
        }
    },[lineIdentifier])
return (<Vendor {...props}  disabled helperText='No Vendor Found for the Service Line Identifier'/>)

}
