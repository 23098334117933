import AccommodationsToActivitiesList from './AccommodationsToActivitiesList';
import AccommodationsToActivitiesCreate from './AccommodationsToActivitiesCreate';
import AccommodationsToActivitiesEdit from './AccommodationsToActivitiesEdit';

export default {
    list: AccommodationsToActivitiesList,
    create: AccommodationsToActivitiesCreate,
    edit: AccommodationsToActivitiesEdit

};
