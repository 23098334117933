import {FormSpy} from "react-final-form";
import React from "react";
import {useNotify} from 'react-admin';
import PropTypes from "prop-types";

const BushFormSpyField = (props)=>{
    const {subsEvent,children} = props;
    const notify = useNotify();
    return (
        <FormSpy
            subscription={{ values:true }}
            render={({ form,values }) => {
                subsEvent({values,form,notify});
                return children;
            }}
        />
    )
}

BushFormSpyField.propTypes = {
    subsEvent: PropTypes.func.isRequired
}

export default BushFormSpyField;


