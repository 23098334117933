import React from 'react'
import {
    SimpleForm, TextInput, NumberInput,BooleanInput,
    Create
} from 'react-admin'

import Validator from '../../validators/airportValidator'

export default props => {
    const validator = new Validator().g_cr_var_fui()
    return (
        <Create {...props} >
            <SimpleForm  validate ={validator} redirect='list'>
                <TextInput source="Name" required/>
                <BooleanInput source="Is_Transfer" label="Is Transfer"/>
                <TextInput source="IATA_Code" label="IATA Code"/>
                <NumberInput source="Latitude"/>
                <NumberInput source="Longitude"/>
            </SimpleForm>
        </Create>
    )
};
