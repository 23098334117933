import uiContentValidateMeta from "./uiContentValidateMeta";

export default {
    primaryKey:'ID',
    commonValidate: {
        Name: ['Required',{isLength:{max: 100}}],
        Country_Code: ['Required',{isLength:{max: 15}}],
        Ecosystem_Code: [{isLength:{max: 100}}],
        Destination_Code: [{isLength:{max: 3}}],
        Status_Code: ['Required',{isLength:{max: 15}}],
        Airport_Name: [{isLength:{max: 100}}],
        Latitude: ['Required',{isFloat:{min:-999999.999999,max:999999.999999}}],
        Longitude: ['Required',{isFloat:{min:-999999.999999,max:999999.999999}}],
        ...uiContentValidateMeta
    }
}



