import React from "react";
import {Activity} from "../../components/refrences/input/RelatedInputs";
import {useForm} from "react-final-form";
import {activityUIContent} from "../common/changes";

const ActivitySelectInput = (props) => {
    const form = useForm();

    function handleChangeActivity(value) {
        activityUIContent(value, form);
        //important !! this value is for Park Fees Code 2020/5/31.
        if (value && value === 'PFEES') {
            form.change('Service.Service_Type_Code', 'PF');
        } else {
            form.change('Service.Service_Type_Code', 'AT');
        }
        if (value) {
            let Service_Code = value;
            const length = Service_Code.length;
            if (length < 6) {
                for (let i = length; i < 6; i++) {
                    Service_Code += "_";
                }
            }
            form.change('Service_Code', Service_Code);
        } else {
            form.change('Service_Code', null);
        }
    }

return (
    <Activity allowEmpty {...props} onChange={handleChangeActivity}/>
)
}


export default ActivitySelectInput;
