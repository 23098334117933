import React from "react";
import {CREATE} from 'react-admin'
import dataProvider from "../../../rest-data-provider";
import PropTypes from "prop-types";
import {LodgingType} from "../../../components/refrences/input/NoneStoreRelatedInputs";
import {notifyError} from "../../../ec-admin-adapters/notifyF";
import RelatedAddOrEditButton from "../../../components/Buttons/RelatedAddOrEditButton";

const LodgingTypeAddButton =(props)=>{
    const {accommodationID} = props;
    const submitEvent = ({notify,refresh,values,setShow})=>{
        dataProvider(CREATE, 'accommodationsToLodgingTypes', {data: values}).then(() => {
            refresh();
            setShow(false);
        }).catch(error => {
            notifyError(notify,error);
        })
    };

    return <RelatedAddOrEditButton title='Add LodgingType' record={{Accommodation_ID:accommodationID}} submitEvent={submitEvent}>
        <LodgingType/>
    </RelatedAddOrEditButton>
};

LodgingTypeAddButton.propTypes = {
    accommodationID: PropTypes.string.isRequired
};

export default LodgingTypeAddButton;
