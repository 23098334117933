import React, {Fragment, useEffect, useState} from "react";
import {Button, DialogActions, DialogContent, Dialog, Link, Menu, MenuItem} from "@material-ui/core";
import {Email,Cancel} from "@material-ui/icons";
import {useNotify} from 'react-admin'
import axios from "axios";
import {basePath} from "../../rest-data-provider/paths";
import parse from 'html-react-parser';
import {notifyError, notifyShow} from "../../ec-admin-adapters/notifyF";
import RichEditor from "../../components/editor/RichEditor";
import {convertToHTML} from "draft-convert";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";


const actionsStyles = makeStyles(theme=>({
    root: {
        display: 'block'
    },
}));
const VendorEmailButton = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [show, setShow] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [action, setAction] = useState("");
    const [serviceHtml, setServiceHtml] = useState(undefined);
    const [editorContent,setEditorContent] = useState(undefined);
    const notify = useNotify();
    const {packageID} = props;
    const [emails,setEmails] =useState(undefined);

    const openMenu = event => {
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        if(packageID){
            axios.get(basePath + '/reservationsToPackages/getVendorEmail/' + packageID).then((response) => {
                setEmails(response.data);
            }).catch(error => {
                notifyError(notify,error);
            })
        }
    },[packageID])

    const handleOpenClick = (action) => {
        closeMenu();
        setDisabled(false);
        setAction(action);
        axios.get(basePath + '/reservationsToPackages/servicesInfo/' + packageID+"?action="+action).then((response) => {
            setShow(true);
            setServiceHtml(response.data);
        }).catch(error => {
            notifyError(notify,error);
        })
    };

    const getThanks=(editorContent)=>{
        return convertToHTML({styleToHTML: (style) => {
                if (style === 'CODE') {
                    return <span style={{backgroundColor:'rgba(0, 0, 0, 0.05)'}} />;
                }
                if (style === 'UNDERLINE') {
                    return <span style={{textDecoration: 'underline'}} />;
                }
            },
            blockToHTML: (block) => {
                if (block.type === 'blockquote') {
                    return <blockquote style={{
                        borderLeft:'5px solid #eee',color:'#666',fontFamily:"Hoefler Text, Georgia, serif",fontStyle:'italic',margin:'16px 0',padding:'10px 20px'
                    }}/>;
                }
            },
        })(editorContent);
    }

    const handleSaveClick = () => {
        setDisabled(true);
        axios.post(basePath + '/reservationsToPackages/email/' + packageID,{action,thanks:getThanks(editorContent),emails:emails}).then(() => {
            setShow(false);
            notifyShow(notify,"send email successfully ");
        }).catch(error => {
            setShow(false);
            notifyError(notify,error);
        })
    };

    const actionsClasses = actionsStyles();

    return (
        <Fragment>
            <Button color="primary"  onClick={openMenu} label="ra.action.create">
                <Email/>Vendor Email
            </Button>
            <Dialog
                disableEnforceFocus
                fullWidth={false}
                maxWidth="xl"
                open={show}
                onClose={()=>setShow(false)}
                aria-label="Add Step"
            >

                <DialogContent>
                    {serviceHtml ?
                        <div>
                            {parse(serviceHtml.header)}
                            <RichEditor html={serviceHtml.thanks} setEditorContent={setEditorContent}/>
                            {parse(serviceHtml.servicesFragment)}
                        </div>
                        : null
                    }


                </DialogContent>
                <DialogActions classes={actionsClasses}>
                    <div>
                        <TextField fullWidth id="Email Address" label="Email Address" type='text' value={emails} onChange={e=>{
                            const value = e.target.value;
                            setEmails(value);
                        }}/>
                    </div>
                    <div style={{display: 'flex',justifyContent: 'flex-end',padding: '8px'}}>
                        <Button color="primary"
                                onClick={handleSaveClick}
                                disabled={disabled}
                        >
                            <Email/>Send
                        </Button>
                        <Button color="primary" onClick={()=>{
                            const previewPath = basePath+'/reservationsToPackages/preview/servicesInfo/'+packageID+"?action="+action+"&thanks="+encodeURIComponent(getThanks(editorContent));
                            window.open(previewPath);
                            // w.location.href=''
                        }}>
                            Preview
                        </Button>
                        <Button
                            onClick={()=>setShow(false)}
                        >
                            <Cancel/>Cancel
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                    closeMenu()
                }}
            >
                <MenuItem onClick={() => {
                    handleOpenClick('request');
                }}>Request</MenuItem>
                <MenuItem onClick={() => {
                    handleOpenClick('confirm');
                }}>Confirm</MenuItem>
            </Menu>
        </Fragment>
    )
}
export default VendorEmailButton;
