export default {
    primaryKey:'ID',
    commonValidate: {
        Accommodation_ID: [{isInt:{min: 1}}],
        Service_ID: [{isInt:{min: 1}}],
        Currency_Code: ['Required',{isLength:{max: 15}}],
        Base_Net:['Required',{isFloat:{min:0,max:9999999999.99}}],
        Base_Rack:['Required',{isFloat:{min:0,max:9999999999.99}}],
        Rack_USD:['Required',{isFloat:{min:0,max:9999999999.99}}],
        Status_Code: ['Required',{isLength:{max: 100}}],
        Start_Date:[{LTT:'End_Date'}]
    },
}



