import React from 'react'
import {
    SimpleForm,
    Create,
} from 'react-admin'
import Validator from '../../validators/routeValidator'
import {Destination as DestinationInput, Destination, Status} from "../../components/refrences/input/RelatedInputs";

export default props => {
    const validator = new Validator().g_cr_var_fui()
    return (
        <Create {...props}>
            <SimpleForm  validate ={validator} submitOnEnter={false} redirect='list'>
                <Destination label='From Master Destination' source='From_Destination_ID' filter={{isMaster: true}}/>
                <Destination label='To Master Destination' source='To_Destination_ID' filter={{isMaster: true}}/>
                <Status source="Status_Code" label='Status' defaultValue='Published'/>
            </SimpleForm>
        </Create>
    )
};
