import React from 'react'
import {
    List, downloadCSV, Datagrid, TextField, EditButton, DeleteButton,
    Filter,TextInput,NullableBooleanInput
} from 'react-admin'
import {unparse as convertToCSV} from 'papaparse/papaparse.min'
import idReducer from '../../ec-admin-adapters/idReducer'


const AnimalFilter = (props) => (
    <Filter {...props}>
        <TextInput source="Name"  alwaysOn/>
        <NullableBooleanInput source="Is_Big_Five" label='Is Big Five'  alwaysOn/>
        <NullableBooleanInput source="Is_Bird_Six" label='Is Bird Six'  alwaysOn/>
    </Filter>
);

const exporter = ecosystems => {
    ecosystems = idReducer(ecosystems)
    const csv = convertToCSV({
        data: ecosystems,
        // fields: ['Final_Transfer_Origin', 'Name', 'Final_Transfer_Destination'] // order fields in the export
    });
    downloadCSV(csv, 'ecosystems  ' + new Date()); // download as 'ecosystems.csv` file
}

const AnimalTable = props => {
    return (
        <Datagrid {...props} >
            <TextField source="Sequence"/>
            <TextField source="Name"/>
            <EditButton/>
            <DeleteButton undoable={false}/>
        </Datagrid>
    )
}

export default props => {

    return (
        <List {...props} filters={<AnimalFilter/>} exporter={exporter}
              sort={{ field: 'Sequence', order: 'ASC' }}
        >
            <AnimalTable/>
        </List>
    )
}







