export default {
    primaryKey:'Code',
    uniqueFields: ['Name','Code'],
    commonValidate: {
        Code: ['Required',{isLength:{max: 15}}],
        Name: ['Required',{isLength:{max: 100}}],
        Sequence: [{isInt:{min:0,max: 255}}],
        Conversion_Rate:['Required',{isFloat:{min:0.00001,max:9999999999.99}}]
    }
}



