import React, {useEffect, useState} from 'react'
import {
    Create,TextInput, SimpleForm,NumberInput
} from 'react-admin';

import Validator from '../../validators/tripStepValidator'

import CountrySubsDestination from "./CountrySubsDestination";
import {getParameters} from "../../utils/paramtersUtil";
import {Destination} from "../../components/refrences/input/RelatedInputs";
import HiddenInput from "../../components/inputs/HiddenInput";
import {CustomBackToolbar} from "../../components/forms/CustomBackToolbar";


export default props => {
    const validator = new Validator().g_cr_var_fui()
    const {history} = props;
    const {search} = props.location;
    const parameters = getParameters(search);
    const {Trip_ID,Step_Number} = parameters
    const [redirect, setRedirect] = useState('/tripSteps');
    useEffect(()=>{
        setRedirect( '/tripSteps?filter=%7B"Trip_ID"%3A'+Trip_ID+'%7D');
    },[Trip_ID])


    function back() {
        history.push(redirect)
    }

    return <Create title='Create Trip Step' {...props} >
        <SimpleForm  validate ={validator} redirect={redirect}  toolbar={<CustomBackToolbar back={back} redirect={redirect}/>}>
            <HiddenInput disabled  source="Trip_ID" label='Trip ID' defaultValue={Trip_ID}/>
            <TextInput disabled  source="Step_Number" label='Step Number' defaultValue={Step_Number}/>
            <NumberInput source="Days" label='Nights'/>
            <Destination source='Destination_ID' />
            <CountrySubsDestination/>
            <TextInput fullWidth multiline  source='Remarks'/>
        </SimpleForm>
    </Create>
}
