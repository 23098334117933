import React, {Fragment} from "react";
import {
   Datagrid, TextField
} from 'react-admin';
import AnimalAddButton from "./AnimalAddButton";
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types'
import CustomDeleteButton from "../../../components/Buttons/CustomDeleteButton";
import {Animal} from "../../../components/refrences/field/RelatedFields";

const useStyles = makeStyles({
    buttons: {
        display:'flex',
        'justify-content':'space-between'
    },
});

function recordToTitle(record){
    return 'Remove Animal ('+(record.Animal.Name)+') from Accommodation?';
}

const Animals = (props) => {
    const classes = useStyles();
    const {accommodationID,...others} = props;
    return (
        <Fragment>
            <div className={classes.buttons}>
                <span/>
                <AnimalAddButton {...props}/>
            </div>

            <Datagrid style={{"width":"100%"}} {...others}  >
                <TextField source="Animal_Code" label="Code"/>
                <Animal source="Animal_Code" label="Name"  sortable={false}/>
                <CustomDeleteButton recordToTitle={recordToTitle}/>
            </Datagrid>
        </Fragment>
    )
}
Animals.propTypes ={
    accommodationID: PropTypes.string.isRequired
}


export default Animals;
