import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from "@material-ui/core/index";
import {Delete} from "@material-ui/icons/index";
import {connect} from 'react-redux'
import {Confirm, DELETE, useNotify, useRefresh} from 'react-admin'
import dataProvider from "../../rest-data-provider";
import {notifyError} from "../../ec-admin-adapters/notifyF";

const CustomDeleteButton = props => {
    const [show, setShow] = useState(false);
    const {resource, resources, record,recordToTitle,recordToContent, successNotification,buttonLabel} = props;
    const notify = useNotify();
    const refresh = useRefresh();
    const defaultRecordToTitle = (record, resource) => 'Delete ' + resource + "(" + (record ? record.Name || record.id : '') + ")";
    const title = (recordToTitle && recordToTitle(record, resources)) || defaultRecordToTitle(record, resource);
    const content = (recordToContent && recordToContent(record)) || '';

    function submitEvent() {
        dataProvider(DELETE, resource, {id: record.id}).then(() => {
            notify(successNotification || 'Element deleted');
            refresh();
        }).catch(error => {
            notifyError(notify,error);
        })
    }
    return (
        <Fragment>
            <Button style={{color: "#f44336"}} onClick={()=>setShow(true)}>
                <Delete/>{buttonLabel||'Remove'}
            </Button>
            <Confirm title={title} content={content} onClose={()=>setShow(false)} onConfirm={submitEvent}
                     isOpen={show}/>
        </Fragment>)
}


CustomDeleteButton.propTypes = {
    recordToTitle: PropTypes.func,
    recordToContent: PropTypes.func,
    successNotification: PropTypes.string,
    buttonLabel:PropTypes.string,
}

const mapStateToProps = state => ({
    resources: state.admin.resources
})

export default connect(mapStateToProps)(CustomDeleteButton);
