import React from 'react'
import {
    List, Datagrid, TextField, EditButton, DeleteButton,
    Filter
} from 'react-admin'
import {Region} from "../../components/refrences/field/RelatedFields";
import {Status,Region as RegionInput} from "../../components/refrences/input/RelatedInputs";


const CountryFilter = (props) => (
    <Filter {...props}>
        <RegionInput source="Region_Code" label='Region' alwaysOn allowEmpty={true}/>
        <Status source="Status_Code" label='Status' alwaysOn  allowEmpty={true}/>
    </Filter>
);

const CountryTable = props => {
    return (
        <Datagrid {...props} >
            <TextField source="Sequence"/>
            <TextField source="Name"/>
            <Region label='Region' source='Region_Code'/>
            <TextField source="Status_Code" label="Status"/>
            <EditButton/>
            <DeleteButton undoable={false}/>
        </Datagrid>
    )
}


export default props => {
    return <List {...props} filters={<CountryFilter/>} exporter={false}
                 sort={{field: 'Sequence', order: 'ASC'}}
                 filterDefaultValues={{Status_Code: 'Published'}}
    >
        <CountryTable/>
    </List>
}







