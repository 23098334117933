export default {
    primaryKey:'ID',
    Service:{
        Name: ['Required',{isLength:{max: 100}}],
        Service_Type_Code:['Required',{isLength:{max: 15}}],
        Vendor_ID:['Required',{isInt:{min:1,max:4294967295 }}],
        Capacity:[{isInt:{min:1,max:4294967295 }}],
    },
}



