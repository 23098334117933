import React, {Fragment, useState} from "react";
import {Button, Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {VpnKeyOutlined} from "@material-ui/icons";
import {PasswordInput ,SimpleForm,useNotify} from 'react-admin' ;
import SaveCancelButtons from "../../components/Buttons/SaveCancelButtons";
import axios from "axios";
import {basePath} from "../../rest-data-provider/paths";
import {notifyError, notifyShow} from "../../ec-admin-adapters/notifyF";

const ChangePasswordButton = (props)=>{
    const {Email} = props.record;
    const [show,setShow] = useState(false);
    const notify = useNotify();
    return  <Fragment>
        <Button color="primary" onClick={()=>{setShow(true)}}>
            <VpnKeyOutlined/> Change Password
        </Button>
        <Dialog
            disableEnforceFocus
            fullWidth
            open={show}
            onClose={()=>{setShow(false)}}
        >
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent>
                <SimpleForm
                    record={{Email:Email}}
                    validate={(values)=>{
                        if(!values.Password){
                            return {Password:'Required'};
                        }
                        if(!values.Email){
                            return {Password:'Can not get the Email which need to edit'};
                        }
                    }}
                    save={(values)=>{
                        axios.put(basePath + "/users/admin/changePassword", values).then((() => {
                                notifyShow(notify,'change password successfully');
                                setShow(false);
                            }
                        )).catch(error => {
                            notifyError(notify,error);
                            setShow(false);
                        })
                    }}
                    toolbar={<SaveCancelButtons cancelEvent={()=>{setShow(false)}}/>}
                >
                    <PasswordInput source='Password' required/>
                </SimpleForm>

            </DialogContent>
        </Dialog>
    </Fragment>

}


export default ChangePasswordButton;
