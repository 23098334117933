import React, {Fragment} from "react";
import {
    Datagrid
} from 'react-admin';
import MilestoneAddButton from "./MilestoneAddButton";
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types'
import {Milestone} from "../../../components/refrences/field/RelatedFields";
import MilestoneEditButton from "./MilestoneEditButton";
import DateZoneField from "../../../components/Fields/DateZoneField";

const useStyles = makeStyles({
    buttons: {
        display:'flex',
        'justify-content':'space-between'
    },
});


const Milestones = (props) => {
    const classes = useStyles();
    const {packageID,...others} = props;
    return (
        <Fragment>
            <div className={classes.buttons}>
                <span/>
                <MilestoneAddButton {...props}/>
            </div>

            <Datagrid style={{"width":"100%"}} {...others}  >
                <Milestone source="Reservation_Milestone_Type_Code" label="Name"  sortable={false}/>
                <DateZoneField source='Date' />
                <MilestoneEditButton/>
            </Datagrid>
        </Fragment>
    )
}
Milestones.propTypes ={
    packageID: PropTypes.string.isRequired
}


export default Milestones;
