import React, {Fragment, useEffect, useState} from "react";
import {
   Datagrid, TextField
} from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types'
import CustomDeleteButton from '../../../components/Buttons/CustomDeleteButton'
import {Accommodation, AccommodationTier} from "../../../components/refrences/field/RelatedFields";
import {getOneTemplateStep} from "../../../rest-data-provider/fetchs/tripTemplateStepsFetch";
import AccommodationAddButton from "./AccommodationAddButton";
import AccommodationEditButton from "./AccommodationEditButton";

const useStyles = makeStyles({
    buttons: {
        display:'flex',
        'justify-content':'space-between'
    },
});

function recordToTitle(record){
    return record ? 'Remove '+ (record.Accommodation.Name)+ ' from Trip Template Step?' : '';
}

const Steps = (props) => {
    const classes = useStyles();
    const {tripTemplateStepID,data,...others} = props;
    const [templateInfo,setTemplateInfo]=useState({});
    useEffect(()=>{
        getOneTemplateStep(tripTemplateStepID).then(info=>{
            setTemplateInfo(info)
        })
    },[tripTemplateStepID])
    return (
        <Fragment>
            <div className={classes.buttons}>
                <span/>
                <AccommodationAddButton {...props} templateInfo={templateInfo}/>
            </div>

            <Datagrid style={{"width":"100%"}} {...others} data={data}  >
                <Accommodation source="Accommodation_ID" label='Accommodation'/>
                <AccommodationTier source='Accommodation_Tier_Code' label='Accommodation_Tier'/>
                <AccommodationEditButton  templateInfo={templateInfo}/>
                <CustomDeleteButton recordToTitle={recordToTitle}/>
            </Datagrid>
        </Fragment>
    )
}
Steps.propTypes ={
    tripTemplateStepID: PropTypes.string.isRequired
}


export default Steps;
