import React from "react";
import {ReferenceField as RelatedField, TextField, GET_ONE} from 'react-admin'
import PropTypes from "prop-types";
import dataProvider from "../../../rest-data-provider";

export const Accommodation = (props) =>{
    return(
    <RelatedField {...props} reference="accommodations" >
        <TextField source="Name"/>
    </RelatedField>
    )
}
Accommodation.propTypes={
    source:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired
}

export const Activity = (props) =>{
    return(
        <RelatedField {...props} reference="activities" >
            <TextField source="Name"/>
        </RelatedField>
    )
}
Activity.propTypes={
    source:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired
}

export const AccommodationTier = (props) =>
    <RelatedField {...props}  reference="accommodationTiers" >
        <TextField source="Name"/>
    </RelatedField>
AccommodationTier.propTypes={
    source:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired
}

export const AccommodationRoomType = (props) =>
    <RelatedField {...props}  reference="accommodationRoomTypes" >
        <TextField source="Name"/>
    </RelatedField>
AccommodationRoomType.propTypes={
    source:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired
}

export const AgencyName = (props) => {
    const { record } = props;
    const [name, setName] = React.useState('');
    dataProvider(GET_ONE, 'agencies', {
        id: record.Agency_ID
    }).then((resp) => {
           setName(resp.data.Name);
    }).catch(error=>{
        console.log(error);
    })
    return name;
}
AgencyName.propTypes={
    source:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired
}

export const Airport = (props) =>
    <RelatedField {...props}  reference="airports" >
        <TextField source="Name"/>
    </RelatedField>
Airport.propTypes={
    source:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired
}

export const Amenity = (props) =>
    <RelatedField {...props}  reference="amenities" >
        <TextField source="Name"/>
    </RelatedField>
Amenity.propTypes={
    source:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired
}

export const LodgingType = (props) =>
    <RelatedField {...props}  reference="lodgingTypes" >
        <TextField source="Name"/>
    </RelatedField>
LodgingType.propTypes={
    source:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired
}

export const Animal = (props) =>
    <RelatedField {...props}  reference="animals" >
        <TextField source="Name"/>
    </RelatedField>
Animal.propTypes={
    source:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired
}

export const Currency =(props)=>
    <RelatedField {...props} reference="currencies">
        <TextField source="Code"/>
    </RelatedField>
Currency.propTypes={
    source:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired
}

export const Country =(props)=>{
    return <RelatedField {...props} reference="countries">
        <TextField source="Name"/>
    </RelatedField>
}
Country.propTypes={
    source:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired
}

export const Destination =(props)=>
    <RelatedField {...props} reference="destinations">
        <TextField source="Name"/>
    </RelatedField>
Destination.propTypes={
    source:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired
}

export const Milestone = (props) =>
    <RelatedField {...props}  reference="reservationMilestoneTypes" >
        <TextField source="Name"/>
    </RelatedField>
Milestone.propTypes={
    source:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired
}

export const Region =(props)=>
    <RelatedField {...props} reference="regions">
        <TextField source="Name"/>
    </RelatedField>
Region.propTypes={
    source:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired
}

export const Special = (props) =>
    <RelatedField {...props} reference="specials" >
        <TextField source="Name"/>
    </RelatedField>
Special.propTypes={
    source:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired
}

export const ServiceType = (props) =>
    <RelatedField {...props}  reference="serviceTypes" >
        <TextField source="Name"/>
    </RelatedField>
ServiceType.propTypes={
    source:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired
}

export const Service = (props) =>
    <RelatedField {...props}  reference="services" >
        <TextField source="Name"/>
    </RelatedField>
Service.propTypes={
    source:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired
}

export const TransferMode = (props) =>
    <RelatedField {...props}  reference="transferModes" >
        <TextField source="Name"/>
    </RelatedField>
TransferMode.propTypes={
    source:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired
}

export const TripTemplate = (props) =>
    <RelatedField {...props}  reference="tripTemplates" >
        <TextField source="Name"/>
    </RelatedField>
TripTemplate.propTypes={
    source:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired
}

export const Vendor = (props) =>{
    return <RelatedField {...props}  reference="vendors" >
        <TextField source="Name"/>
    </RelatedField>
}
Vendor.propTypes={
    source:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired
}

export const FullNameField = ({ record = {} }) => <span>{record.First_Name} {record.Last_Name}</span>;
FullNameField.defaultProps = { label: 'Name' };

export const FormatField = ({ record = {},format }) => <span>{format(record)}</span>;
FormatField.propTypes={
    format:PropTypes.func.isRequired,
    label:PropTypes.string.isRequired
}
