import React from 'react'
import {
    Edit, TextInput, DateTimeInput, SimpleForm,DateInput
} from 'react-admin'
import Validator from '../../validators/accommodationsToSpecialsValidator'
import EditTitle from "../../components/forms/EditTitle";
import {Accommodation} from "../../components/refrences/input/RelatedInputs";
import SpecialRelatedChangeInput from "./SpecialSelectInput";

export default props => {
    const validator = new Validator().g_up_var_f_ui(props.id)
    return <Edit  title={<EditTitle EntityName='Accommodation Special' id={props.id} resource='accommodationsToSpecials'/>} undoable={false} {...props} >
        <SimpleForm  validate ={validator}>
                <DateTimeInput disabled label='Created' source="Created_Time"/>
                <DateTimeInput disabled label='Modified' source="Modified_Time"/>
            <Accommodation source='Accommodation_ID'/>
            <SpecialRelatedChangeInput />
            <TextInput source='Name'/>
            <DateInput source='Start_Date' label='Start Date'/>
            <DateInput source='End_Date' label='End Date'/>
            <TextInput fullWidth multiline source='Description'/>
            <TextInput fullWidth multiline source='Terms_Conditions' label='Terms & Conditions'/>
        </SimpleForm>

    </Edit>
}
