import React, {useEffect,cloneElement,Children} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';


function EnhancedTableHead(props) {
    const {onSelectAllClick, numSelected, rowCount, fields, isCheckbox} = props;

    return (
        <TableHead>
            <TableRow>
                {isCheckbox ?
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{'aria-label': 'select all desserts'}}
                        />
                    </TableCell>
                    : null}


                {fields.map(field => (
                    <TableCell
                        key={field}
                    >
                        {field}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
    fields: PropTypes.array.isRequired
};


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    const [selected, setSelected] = React.useState([]);
    const {rows, fields, selectCallBack, stateParams, components, isCheckbox} = props;
    const rowsJson = {};
    rows.forEach(row => {
        rowsJson[row.ID] = row;
    })

    useEffect(()=>{
        setSelected([])
    },[rows])

    const getSelectedRows = (IDS) => {
        const selectedRows = [];
        IDS.forEach(ID => {
            selectedRows.push(rowsJson[ID]);
        })
        return selectedRows;
    }


    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelected = rows.map(n => n.ID);
            setSelected(newSelected);
            selectCallBack({stateParams: stateParams, selectedRows: getSelectedRows(newSelected)});
            return;
        }
        setSelected([]);
        selectCallBack({stateParams: stateParams, selectedRows: []});
    };

    const handleClick = (event, ID) => {
        const selectedIndex = selected.indexOf(ID);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, ID);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        selectCallBack({stateParams: stateParams, selectedRows: getSelectedRows(newSelected)});
    };

    const isSelected = ID => selected.indexOf(ID) !== -1;
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={rows.length}
                            fields={fields}
                            isCheckbox={isCheckbox}
                        />
                        <TableBody>
                            {rows.map((row, index) => {
                                const isItemSelected = isSelected(row.ID);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        // onClick={event => handleClick(event, row.ID)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.ID}
                                        selected={isItemSelected}
                                    >
                                        {isCheckbox ?
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    onClick={event => handleClick(event, row.ID)}
                                                    inputProps={{'aria-labelledby': labelId}}
                                                />
                                            </TableCell>
                                            : null}
                                        {fields.map(field => {
                                            return <TableCell key={'reservations-'+row.ID + "-" + field}>{row[field]}</TableCell>
                                        })}

                                        {Children.map(components,(component,index) => {
                                            return <TableCell key={'reservations-'+row.ID + "-" +index}>{cloneElement(component,{row:row,...stateParams})}</TableCell>
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            </Paper>
        </div>
    );
}


EnhancedTable.propTypes = {
    fields: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    selectCallBack: PropTypes.func.isRequired,
    stateParams: PropTypes.object.isRequired,
    isCheckbox: PropTypes.bool,

};
