import React from 'react';
import {Route} from 'react-router-dom';
import ChangePassword from './ChangePassword';
import WrapRoute from './WrapRoute';
import Tool from "./Tool";
import UpdateJson from "./Integration";

export default [
    <Route exact path="/doc_template" component={Tool}/>,
    <Route exact path="/changePassword" component={ChangePassword}/>,
    <Route exact path="/route_wrap" component={WrapRoute}  />,
    <Route exact path="/integration" component={UpdateJson}/>,
]

;


