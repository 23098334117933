
import React from 'react';
import {
    List, Datagrid, TextField, EditButton, DeleteButton,
    TextInput,NullableBooleanInput,
    Filter,NumberInput
} from 'react-admin';
import BooleanFieldWithNumber from "../../components/Fields/BooleanFieldWithNumber";
import {
    AccommodationTier, Activity,
    Airport, Amenity, Animal, Country,
    Destination as DestinationInput, LodgingType, Region,
    Status,
    Vendor as VendorInput
} from "../../components/refrences/input/RelatedInputs";
import {Destination , AccommodationTier as AccommodationTierField} from "../../components/refrences/field/RelatedFields";

const AccommodationFilter = (props) => (
    <Filter {...props}>
        <TextInput source="Name"  alwaysOn/>
        <Status source="Status_Code" label='Status' alwaysOn  allowEmpty={true}/>
        <DestinationInput label="Destination" source="Destination_ID"/>
        <DestinationInput source="Master_Destination_ID" label='Master Destination'  allowEmpty={true}/>
        <Country source="Country_Code" label='Country'   allowEmpty={true}/>
        <Region label="Region" source="Region_Code"/>

        <VendorInput label='Vendor' source='Vendor_ID'/>
        <AccommodationTier label='Accommodation Tier' source='Accommodation_Tier_Code'/>
        <NumberInput source='Age_Minimum' label='Youngest Traveler Age'/>

        <NullableBooleanInput source="Is_Default_For_Destination" label='Is Default For Destination' />
        <NullableBooleanInput source="Is_Default_For_Overnight" label='Is Default For Overnight' />
        <NullableBooleanInput source="Has_Triple_Rooms" label='Has Triple Rooms'  />
        <NullableBooleanInput source="Has_Quad_Rooms" label='Has Quad Rooms'  />

        <Airport label="Default Airport" source="Default_Airport_ID"/>
        <Animal label="Animal" source="Animal_Code"/>
        <Activity label="Activity" source="Activity_Code"/>
        <Amenity label="Amenity" source="Amenity_Code"/>
        <LodgingType label='Lodging Type' source='Lodging_Type_Code'/>

    </Filter>
);

const AccommodationTable = props=>{
    return (
        <Datagrid {...props}>
            <TextField source="ID" label="ID"/>
            <TextField source="Sequence" />
            <TextField source="Name"/>
            <Destination source='Destination_ID' label='Destination'/>
            <AccommodationTierField label='Accommodation Tier' source='Accommodation_Tier_Code' />
            <TextField source="Status_Code" label='Status'/>
            <BooleanFieldWithNumber source="Is_Default_For_Destination" label="Is Default For Destination" />
            <BooleanFieldWithNumber source="Is_Default_For_Overnight" label='Is Default For Overnight' />
            <EditButton/>
            <DeleteButton undoable={false}/>
        </Datagrid>
    )
}

export default props => (
    <List {...props} filters={<AccommodationFilter/>} exporter={false}
          sort={{ field: 'ID', order: 'ASC' }}
          filterDefaultValues={{Status_Code: 'Published'}}
    >
       <AccommodationTable/>
    </List>
)








