import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from "@material-ui/core/index";
import {RestoreFromTrash} from "@material-ui/icons/index";
import {connect} from 'react-redux'
import {Confirm,useNotify,useRefresh} from 'react-admin'
import axios from "axios/index";
import {basePath} from "../../rest-data-provider/paths";
import {notifyError} from "../../ec-admin-adapters/notifyF";

const RestoreButton = props=>{
    const [show,setShow]=useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const {resource,resources,record,successNotification,recordToTitle,recordToContent} = props;
    const defaultRecordToTitle = (record,resource)=>'Restore '+resource+ "("+(record.Name||record.id)+")";
    const title = (recordToTitle&&recordToTitle(record,resources))||defaultRecordToTitle(record,resource);
    const content = (recordToContent&&recordToContent(record))||'';
    const handleSubmit = ()=>{
        axios.put(basePath +"/" + resource+"/restoreOne/"+record.id).then((() => {
                notify(successNotification||'Element Restore');
                refresh();
            }
        )).catch(error => {
            notifyError(notify,error);
        })
    }

    return (
        <Fragment>
            <Button style={{color:"#3F51B5"}} onClick={()=>{setShow(true)}}>
                <RestoreFromTrash />Restore
            </Button>
            <Confirm title={title} content={content} onClose={()=>{setShow(false)}} onConfirm={handleSubmit} isOpen={show}/>
        </Fragment>
    )
}

RestoreButton.propTypes={
    recordToTitle:PropTypes.func,
    recordToContent:PropTypes.func,
    successNotification: PropTypes.string,
}

const mapStateToProps=state=>({
    resources:state.admin.resources
})

export default connect(mapStateToProps)(RestoreButton);
