import React from "react";
import {CREATE} from 'react-admin'
import dataProvider from "../../../rest-data-provider";
import PropTypes from "prop-types";
import {Airport} from "../../../components/refrences/input/NoneStoreRelatedInputs";
import {notifyError} from "../../../ec-admin-adapters/notifyF";
import RelatedAddOrEditButton from "../../../components/Buttons/RelatedAddOrEditButton";

const AirportAddButton =(props)=>{
    const {destinationID} = props;
    const submitEvent = ({notify,refresh,values,setShow})=>{
        dataProvider(CREATE,'destinationsToAirports',{data:values}).then(()=>{
            refresh();
            setShow(false);
        }).catch(error=>{
            notifyError(notify,error);
        })
    }

    return <RelatedAddOrEditButton title='Add Airport' record={{Destination_ID:destinationID}} submitEvent={submitEvent}>
       <Airport/>
    </RelatedAddOrEditButton>
}

AirportAddButton.propTypes ={
    destinationID: PropTypes.string.isRequired
}

export default AirportAddButton;
