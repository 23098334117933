import {useForm} from "react-final-form";
import {Vendor} from "../../components/refrences/input/NoneStoreRelatedInputs";
import axios from "axios";
import {useNotify} from 'react-admin';
import {basePath} from "../../rest-data-provider/paths";
import {notifyError} from "../../ec-admin-adapters/notifyF";
import React from "react";
import {tripVendorFetch} from "../../rest-data-provider/fetchs/vendorFetch";

export const VendorChange=props=>{
    const form = useForm();
    const notify = useNotify();
    const values = form.getState().values;
    const {Trip_ID,id}=values;
    return <Vendor {...props} fetch={(name) => tripVendorFetch(Trip_ID,name)}
                   selectedCallback={(choice) => {
                       if(choice&&choice.id){
                           axios.get(basePath + "/reservationsToPackages/generateCode?Billing_Vendor_ID="+(choice.id||'')+"&Trip_ID="+(Trip_ID||'')+"&ID="+(id||0)).then((result) => {
                                   form.change("Code",result.data.Code);
                               }
                           ).catch(error=>{
                               notifyError(notify,error);
                           })
                       }else{
                           form.change("Code",null);
                       }


                   }}
    />
};
