export default {
    primaryKey:'ID',
    uniqueFields: ['Name','IATA_Code'],
    commonValidate: {
        Name: ['Required',{isLength:{max: 100}}],
        IATA_Code:[{isLength:{max: 3}}],
        Latitude: [{isFloat:{min:-999999.999999,max:999999.999999}}],
        Longitude: [{isFloat:{min:-999999.999999,max:999999.999999}}],
    },
}



