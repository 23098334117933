import React from 'react'
import {
    SimpleForm, TextInput, Create,PasswordInput,BooleanInput
} from 'react-admin'

import Validator from '../../validators/userValidator'
import RoleSelect from "./RoleSelect";
import { AgencyMode } from '../../components/refrences/input/RelatedInputs';
import SecretCodeGenerator from './SecretCodeGenerator';

export default props => {
    const validator = new Validator().g_cr_var_fui()
    return (
        <Create {...props} >
            <SimpleForm  validate ={validator} initialValues={{Role:'Standard'}}>
                <TextInput source="Email" required/>
                <PasswordInput source="Password"/>
                <TextInput source="Name" required/>
                <RoleSelect/>
                <AgencyMode resource="Agency" label="Agency" required/>
                <BooleanInput source="Is_Active" label='Is Active' defaultValue={true} required/>
                <SecretCodeGenerator />
            </SimpleForm>
        </Create>
    )
};
