import dataProvider from '../../rest-data-provider'
import {GET_ONE} from 'react-admin';


export const activityUIContent = (value, form)=>{
    if (value) {
        dataProvider(GET_ONE, 'activities', {id: value})
            .then(({data}) => {
                // form.change('Name', data.Name);
                _changeUIContent(data.UI_Content,form);
            })
    } else {
        // form.change('Name', null);
        _changeUIContent(undefined,form);
    }
}

function _changeUIContent(UIContent,form) {
    if (UIContent) {
        Object.keys(UIContent).forEach(key => {
            form.change('UI_Content.' + key, UIContent[key]);
        })
    } else {
        const UIContentFields = ['Page_Title', 'Subtitle', 'Included_Note', 'Available_Note', 'Meta_Description', 'Description', 'Comments', 'Keywords']
        for (let key of UIContentFields) {
            form.change( 'UI_Content.' + key, null);
        }
    }
}
