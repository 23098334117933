import {useForm} from "react-final-form";
import {AccommodationTier} from "../../../components/refrences/input/NoneStoreRelatedInputs";
import React from "react";
import {findTiersByDestination} from "../../../rest-data-provider/fetchs/accommodationTierFetch";

export const AccommodationTierWithChange=(props)=>{
    const form = useForm();

    return  <AccommodationTier source='Accommodation_Tier_Code' label='Accommodation Tier'
                               fetch={()=>findTiersByDestination(props.destinationID)}
                               allowEmpty {...props} onChange={()=>{
        form.change('Accommodation_ID',"");
    }
    }/>
}
