import React from 'react';
import {useLogout } from 'react-admin';
import Button from '@material-ui/core/Button';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';

export default () => {
    const logout = useLogout();
        return (
            <Button
                onClick={logout}
                size="small"
            >
                <ExitIcon/> Logout
            </Button>

    )
};
