import React from "react";
import titleStyle from "../../../theme/TitleStyles";

export default (props)=>{
    const {serviceType,isEdit,info}=props;
    if(serviceType==='AC'){
        return ACTitle(info,isEdit);
    }else if (serviceType==='TF'){
        return TFTitle(info,isEdit);
    }else if (serviceType==='AT'){
        return ATTitle(info,isEdit);
    }
    return null;
}

const ACTitle = (info,isEdit) => {
    const {Destination,Accommodation_Tier_Code,Accommodation} = info;
    const obj = {Destination,"Accommodation Tier":Accommodation_Tier_Code};
    if(Accommodation)
        obj.Accommodation = Accommodation;
    return <p>{isEdit?'Edit':'Add'} Accommodation Reservation <br/>
        {keyValues(obj)}
    </p>
}

const TFTitle = () => {
    return <p>Transfer Reservation <br/>
    </p>
}

const ATTitle = (info,isEdit) => {
    return <p>{isEdit?'Edit':'Add'} Activity Reservation</p>
}




function keyValues(obj){
    const spanClassName = titleStyle().span;
    const keys = Object.keys(obj);
    return keys.map(key => {
        return <span className={spanClassName} key={'title-' + key}>{key}:{obj[key]}</span>
    });

}

