import React from 'react'
import PropTypes from "prop-types";
import {
    SimpleForm,
    NumberInput,
    TextInput, DateInput,
    useNotify
} from 'react-admin'
import {AccommodationTier, TripTypes, AgencyMode} from "../../components/refrences/input/RelatedInputs";
import TripValidator from '../../validators/tripValidator'
import axios from "axios";
import {basePath} from "../../rest-data-provider/paths";
import {notifyError} from "../../ec-admin-adapters/notifyF";
import BushFormSpyField from "../../components/forms/BushFormSpyField";
import HiddenInput from "../../components/inputs/HiddenInput";
import {GetForm} from "../../components/forms/GetForm";

const TripNameSubsEvent=({values, form}) => {
    const {tripTemplateName, Start_Date, Requester_First_Name,Requester_Last_Name} = values;
    function concatName(name,items){
        items.forEach (concat=>{
            name+='-'+(concat||' ');
        })
        return name;
    }
    form.change('Name',concatName(tripTemplateName,[Start_Date,Requester_First_Name,Requester_Last_Name]));
}

const TripRequestForm = props => {
    const {tripTemplateID,tripTemplateName,cancelEvent, saveEvent,setForm} = props;
    const notify = useNotify();
    const validator = new TripValidator().g_cr_var_fui();
    const handleSubmit = (values) => {
        axios.post(basePath + "/trips/ui", {Trip_Template_ID: tripTemplateID, ...values}).then(() => {
            notify('Trip created successfully');
            saveEvent();
        }).catch(error => {
            notifyError(notify, error);
            cancelEvent();
        })
    }

    return <SimpleForm
         validate ={validator}
        save={handleSubmit}
        toolbar={null}
        record={{tripTemplateName:tripTemplateName}}
    >
        <BushFormSpyField subsEvent={TripNameSubsEvent}>
            <HiddenInput source='Name'/>
        </BushFormSpyField>
        <TextInput source='Requester_First_Name' label='Requester First Name'/>
        <TextInput source='Requester_Last_Name' label='Requester Last Name' required/>
        <TextInput source='Requester_Email' label='Requester Email'/>
        <TextInput source='Requester_Phone' label='Requester Phone'/>
        <AccommodationTier source='Accommodation_Tier_Code' label='Amenity Level'/>
        <AgencyMode resource="Agency" label="Agency" required/>
        <DateInput source='Start_Date' label='Start Date' required/>
        <NumberInput source='Passenger_Count' label='Number In Party' required/>
        <TripTypes source='Type' label='Type'required defaultValue='FIT'/>
        <GetForm setForm={setForm}/>
    </SimpleForm>
}

TripRequestForm.propTypes = {
    tripTemplateID: PropTypes.number.isRequired,
    cancelEvent: PropTypes.func.isRequired,
    saveEvent: PropTypes.func.isRequired,
    tripTemplateName:PropTypes.string.isRequired,
    setForm:PropTypes.func.isRequired,
}

export default TripRequestForm;
