import React, {useState, useEffect, useCallback} from "react";
import BushMenu from './BushMenu';
import {
    Layout,
    AppBar,
    UserMenu,
    MenuItemLink,
    getNotification,
    useTranslate,
    complete,
    undoableEventEmitter,
    hideNotification,
    undo,
    useLogout
} from 'react-admin';
import {useSelector, useDispatch} from 'react-redux';
import Snackbar from "@material-ui/core/Snackbar";
import classnames from "classnames";
import Button from "@material-ui/core/Button";
import {makeStyles, Avatar} from "@material-ui/core";
import auth from './routes/auth/AuthProvider';
import axios from 'axios';
import config from './config/config';

const ignoreErrors = ['ra.notification.logged_out'];

const profileStyles = makeStyles(() => ({
    mainPanel: {
        display: 'flex',
        padding: '0.5rem',
        alignItems: 'center',
    },
    detailPanel: {
        display: 'inline',
        paddingLeft: '1rem',
        '& p': {
            margin: 0,
        }
    }
}));

const Profile = props => {
    const styles = profileStyles();
    const { user } = props;
    return user ? <div className={styles.mainPanel}>
        <Avatar>{user.Name.charAt(0)}</Avatar>
        <div className={styles.detailPanel}>
            <p><b>{user.Name}</b></p>
            <p>{user.Email}</p>
        </div>
    </div> : null;
};

const MyUserMenu = props => {
    const [user, setUser] = useState('');
    useEffect(() => {
        if(user === '') {
            axios.get(config.service_base_url + '/users/' + auth.getUserID()).then((res) => {
                setUser(res.data);
            }).catch((error) => {
                console.log(error);
            })
        }
    });
    return <UserMenu {...props} label={user.Name}>
        <Profile user={user} />
        <MenuItemLink
            to="/changePassword"
            primaryText="ChangePassword"
        />
    </UserMenu>
};

const appbarStyles = makeStyles(() => ({
    appbar: {
        width: '100%',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        margin: 0,
        textShadow: '0 0 3px #fff, 0 0 5px #fff',
    }
}));

const MyAppBar = props => {
    const styles = appbarStyles();
    const [agency, setAgency] = useState('');
    useEffect(() => {
        axios.get(config.service_base_url + '/agencies/getAgencyData/' + auth.getAgency()).then((res) => {
            setAgency(res.data.result[0].Name);
        }).catch((error) => {
            console.log(error);
        })
    });
    return <AppBar {...props} userMenu={<MyUserMenu/>}>
        <p className={styles.appbar} style={{color: `#${auth.getAgency()*3%10}${auth.getAgency()*3%10}${auth.getAgency()*3%10}`}}>{agency}</p>
    </AppBar>;
};

const MyLayout = props => <Layout {...props} appBar={MyAppBar}/>;

const BushLayout = (props) => {

    return <MyLayout {...props} menu={BushMenu} notification={MyNotification}/>
};


const useStyles = makeStyles((theme) => {
    return ({
        error: {
            backgroundColor: theme.palette.error.dark,
            color: theme.palette.error.contrastText,
            zIndex:1000000000
        },
        warning: {
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.contrastText,
            zIndex:1000000000
        },
        undo: {
            color: theme.palette.primary.light,
        },
    });
}, {name: 'RaNotification'});

const MyNotification = props => {
    const {type, className, ...rest} = props;
    const [open, setOpen] = useState(false);
    const [duration,setDuration] = useState(undefined);
    const [msg,setMsg] = useState(undefined);
    const notification = useSelector(getNotification);
    const dispatch = useDispatch();
    const translate = useTranslate();
    const logout = useLogout();
    const styles = useStyles({});
    useEffect(function () {
        if(notification&&notification.message){
            let message = notification.message;
            if(ignoreErrors.includes(message)){
                setOpen(false);
            }else{
                if(notification&&notification.message==='Unauthorized'){
                    setMsg('You have been logged out for security reasons');
                    setDuration(4000);
                    setTimeout(() => {
                        handleExited();
                            logout();
                    }, 4000);
                } else{
                    setMsg(translate(message, notification.messageArgs));
                    setDuration( (notification&&notification.autoHideDuration)||4000);
                }
                setOpen(true);
            }
        }else{
            setOpen(false);
        }
    }, [notification]);
    const handleRequestClose = useCallback(function () {
        setOpen(false);
    }, [setOpen]);
    const handleExited = useCallback(function () {
        if (notification && notification.undoable) {
            dispatch(complete());
            undoableEventEmitter.emit('end', {isUndo: false});
        }
        dispatch(hideNotification());
    }, [dispatch, notification]);
    const handleUndo = useCallback(function () {
        dispatch(undo());
        undoableEventEmitter.emit('end', {isUndo: true});
    }, [dispatch]);

    return <Snackbar open={open} {...rest}
                     message={msg}
                     autoHideDuration={duration}
                     disableWindowBlurListener={notification && notification.undoable}
                     onExited={handleExited}
                     onClose={handleRequestClose}
                     ContentProps={{className: classnames(styles[(notification && notification.type) || type], className)}}
                     action={notification&& notification.undoable ? <Button color="primary" className={styles.undo} size='small'
                                                             onClick={handleUndo}>{translate('ra.action.undo')}</Button> : null}
    />

}


export default BushLayout;
