export default {
    primaryKey:'ID',
    commonValidate: {
        Name: ['Required',{isLength:{max: 200}}],
        Street_Address: ['Required',{isLength:{max: 200}}],
        City: ['Required',{isLength:{max: 200}}],
        State: ['Required',{isLength:{max: 200}}],
        Zip: ['Required',{isLength:{max: 32}}],
        Phone: ['Required',{isLength:{max: 20}}],
        Email: ['Required',{isLength:{max: 64}}],
    },
}