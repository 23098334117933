// this is for UI and service to validate bean.
import validator from 'validator'
import {normalCompare} from '../utils/dateUtil'

export function _is_null(value) {
    return typeof(value)===undefined||value==null||value==='';
}


//generate => g , create=>cr ,update=>up , validator =>var forUI =>f_ui validate=>vae
class SimpleValidateGenerator {
    // static _is_null =(value)=>typeof(value)===undefined||value==null||value===''
    static _vae_msg = {
        'isLength': (options) => (options && options.msg) || (!_is_null(options.min)? options.min + "<=" : "") + " `length` " + (!_is_null(options.max) ? " <=" + options.max : ""),
        'Required': (options) => (options && options.msg) || 'required',
        'isEmail': (options) => (options && options.msg) || 'not a email',
        'isURL': (options) => (options && options.msg) || 'not a validated URL',
        'isFloat': (options) => (options && options.msg) || (!_is_null(options.min)? options.min + "<=" : "") + " `number` " + (!_is_null(options.max) ? " <=" + options.max : ""),
        'isInt': (options) => (options && options.msg) || (!_is_null(options.min) ? options.min + "<=" : "") + " `number` " + (!_is_null(options.max) ? " <=" + options.max : ""),
    }




    //validate values (form)
    static g_vae(settings) {
        return (values) => {
            const error = {};
            this.g_vae_object_key(error,values,settings);
            return !this.noError(error)?error:{};
        }
    }

    static noError(error){
        const isNoError = Object.keys(error).every(key=>{
            const value = error[key];
            if(value){
                if(Object.prototype.toString.call(value)==='[object Object]'){
                    if(!this.noError(value)){
                        return false;
                    }
                }else{
                    return false;
                }
            }
            return true;
        })
        return isNoError;
    }

    // for a single object key  recur validate
    static g_vae_object_key(error, values, settings){
        Object.keys(settings).forEach(key=>{
            const setting = settings[key];
            let value =undefined;
            if(values&&!_is_null(values[key])){
                value = values[key];
            }
            // single key
            if(Object.prototype.toString.call(setting)==='[object Array]'){
                this.g_vae_str_key(key,error,value,setting,values)
                //a big object like associated UI_Content.
            }else if(Object.prototype.toString.call(setting)==='[object Object]'){
                const childrenSettings = setting;
                const childrenValues = value;
                error[key] = {};
                const childrenError = error[key];
                this.g_vae_object_key(childrenError,childrenValues,childrenSettings);
            }
                // with custom function
            else if(Object.prototype.toString.call(setting)==='[object Function]'){
                const funError = setting(value);
                if(funError){
                    error[key]= funError;
                }
            }
        })
    }

    // for a single array key validate(composed of string and object)
    static g_vae_str_key(key, error, value, setting,values){
        for (let _validate of setting) {
            if (typeof _validate == 'string') {
                this._str_vae(value, key, _validate, error);
            } else if (_validate instanceof Object) {

                Object.keys(_validate).forEach(_validate_fn => {
                    const options = _validate[_validate_fn];
                    if(!_is_null(value) && _validate_fn==='LTT'){
                        const compareValue = values[options];
                        if(compareValue&&normalCompare(value,compareValue)){
                            error[key]='should not over '+options;
                        }
                    }
                    else if (!_is_null(value) && !validator[_validate_fn](value + '', options)) {
                        error[key] = this._vae_msg[_validate_fn](options)
                    }
                })
            }
        }
    }

    // for a single string key (part of array)
    static _str_vae(value, key, _validate, error) {
        if (_validate === 'Required') {
            if (_is_null(value)) {
                error[key] = this._vae_msg[_validate]()
            }
        }else {
            if(!_is_null(value)){
                value += '';
                if (!validator[_validate](value)) {
                    error[key] = this._vae_msg[_validate]()
                }
            }
        }
    }


}

export default SimpleValidateGenerator;

