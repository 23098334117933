import React from 'react'
import {
    List, Datagrid, TextField, EditButton, DeleteButton,
    TextInput,
    Filter
} from 'react-admin'
import {Status} from "../../components/refrences/input/RelatedInputs";

const ActivityFilter = (props) => (
    <Filter {...props}>
        <TextInput source="UI_Content.Description" label='Website Description' alwaysOn />
       <Status source="Status_Code" label='Status' alwaysOn  allowEmpty={true}/>
    </Filter>
);

export default props => {
    return <List {...props} filters={<ActivityFilter/>} exporter={false}
                 sort={{ field: 'Sequence', order: 'ASC' }}
                 filterDefaultValues={{Status_Code: 'Published'}}
    >
        <Datagrid>
            <TextField source="Sequence"/>
            <TextField source="Name"/>
            <TextField source="Status_Code" label='Status'/>
            <EditButton/>
            <DeleteButton undoable={false}/>
        </Datagrid>
    </List>
}







