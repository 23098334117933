import React, {useState} from 'react'
import {useLogin} from 'react-admin'
import {Button} from '@material-ui/core'
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {loginValidator} from '../../../validators/userValidator'
import {ErrorSlideTransition, SuccessSlideTransition} from '../../../components/customNotification'
import Snackbar from "@material-ui/core/Snackbar";
import FormField from '../../../components/Fields/FormField'

const theme = createMuiTheme();


function Made() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Built by the '}
            <Link color="inherit" href="https://www.ecwise.com/en/home/">
                ECWISE
            </Link>
            {' team.'}
        </Typography>
    );
}

const MyLoginPage = () => {
    const login = useLogin();
    const [Email, setEmail] = useState(null);
    const [Password, setPassword] = useState(null);
    const [VerificationCode, setVerificationCode] = useState(null);
    const [Secret, setSecret] = useState(null);
    const [msg,setMsg]=useState();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoginFirst, setIsLoginFirst] = useState(false);
	const [qrImage, setQrImage] = useState(null);
    const [error]=useState(true);

    const onEmailChange = (e) => {
        setEmail(e.target.value);
        setIsLoginFirst(false);
        setIsAdmin(false);
        setVerificationCode(null);
        setSecret(null);
    }

    const submit = (e) => {
        e.preventDefault();
        const credentials = {Email, Password, VerificationCode, Secret};
        const msg = loginValidator({Email, Password});
        if (msg) {
            setMsg(msg);
            return;
        }

        login(credentials)
            .catch((error) => {
                if(!error.response.data.secret)
                    setMsg(error.response.data);
                else
                {
                    if(error.response.data.qrImage) {
                        setQrImage(error.response.data.qrImage);
                        setSecret(error.response.data.secret);
                        setIsLoginFirst(true);
                    }
                    else
                        setSecret(error.response.data.secret);
                    setIsAdmin(true);
                }
            });
    }

    return (
        <MuiThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={theme.paper}>
                    <Avatar className={theme.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form onSubmit={submit} noValidate>
                        <FormField
                            name="Email"
                            // autoComplete="Email"
                            onChange={onEmailChange}
                        />
                        <FormField
                            name="Password"
                            type="password"
                            autoComplete="current-Password"
                            onChange={(e) => {
                                setPassword(e.target.value);
                                // this.setState({Password: e.target.value, msg: undefined})
                            }}
                        />

                        {isAdmin && 
                             <div style={{textAlign: 'center'}}>
                                <FormField
                                    name="VerificationCode"
                                    type="text"
                                    onChange={(e) => {
                                        setVerificationCode(e.target.value);
                                    }}
                                />
                                <p>You must enter a verification code from authenticator apps.</p>
                                 {isLoginFirst && <img src={qrImage} alt="QR code" />}
                            </div>
                         }

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className=""
                        >
                            Sign In
                        </Button>
                        {/*<Grid container>*/}
                        {/*    <Grid item>*/}
                        {/*        <Link href="/#/register" variant="body2">*/}
                        {/*            {"Don't have an account? Sign Up"}*/}
                        {/*        </Link>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                    </form>
                </div>
                <Box mt={5}>
                    <Made/>
                </Box>
                <Snackbar
                    open={msg !== undefined}
                    onClose={() => {
                        setMsg(undefined);
                    }}
                    autoHideDuration={2000}
                    TransitionComponent={(error === true ? ErrorSlideTransition : SuccessSlideTransition)}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{msg}</span>}
                />
            </Container>
        </MuiThemeProvider>
    );
}

export default MyLoginPage;
