import React from 'react'
import {
    Edit, TextInput, NumberInput, BooleanInput
    , SimpleForm
} from 'react-admin'
import Validator from '../../validators/airportValidator'
import EditTitle from "../../components/forms/EditTitle";

export default props => {
    const validator = new Validator().g_up_var_f_ui(props.id)
    return <Edit title={<EditTitle EntityName='Airport' id={props.id} resource='airports'/>} undoable={false} {...props} >
        <SimpleForm  validate ={validator}>
            <TextInput source="Name" required/>
            <BooleanInput source="Is_Transfer" label="Is Transfer"/>
            <TextInput source="IATA_Code" label="IATA Code"/>
            <NumberInput source="Latitude"/>
            <NumberInput source="Longitude"/>
        </SimpleForm>
    </Edit>
}
