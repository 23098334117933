import {useForm, useFormState} from "react-final-form";
import React, {useEffect} from "react";
import axios from "axios";
import {basePath} from "../../rest-data-provider/paths";
import {notifyError} from "../../ec-admin-adapters/notifyF";
import {useNotify,TextInput} from 'react-admin';

export default props=>{
    const formState = useFormState();
    const form = useForm();
    const notify = useNotify();
    const { Role } = formState.values;
    const [visible, setVisible] = React.useState(false);
    const [code, setCode] = React.useState(null);
    useEffect(()=>{
        if(Role === 'Admin'){
            axios.get(basePath + '/users/generateSecretCode/').then((res) => {
                setCode(res.data);
                form.change('Secret_Code',res.data);
                setVisible(true);
            }).catch(error => {
                notifyError(notify,error);
            })
        } else {
            setCode(null);
            form.change('Secret_Code',null);
            setVisible(false);
        }
    },[Role]);

    return  visible ? 
        <div>
            <p>You have to use the 2FA authenticator. Your sercet code is <b>{code}</b>.</p>
            <TextInput {...props} source="Secret_Code" style={{display: 'none'}} />
        </div>
        : null
}
