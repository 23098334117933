import uiContentValidateMeta from "./uiContentValidateMeta";

export default {
    primaryKey:'Code',
    uniqueFields: ['Name','Code'],
    commonValidate: {
        Code: ['Required',{isLength:{max: 3}}],
        Name: ['Required',{isLength:{max: 100}}],
        Region_Code: ['Required',{isLength:{max: 15}}],
        Status_Code: ['Required',{isLength:{max: 15}}],
        Latitude: [{isFloat:{min:-999999.999999,max:999999.999999}}],
        Longitude: [{isFloat:{min:-999999.999999,max:999999.999999}}],
        Latitude_Offset: [{isFloat:{min:-999999.999999,max:999999.999999}}],
        Longitude_Offset: [{isFloat:{min:-999999.999999,max:999999.999999}}],
        Sequence: [{isInt:{min:0,max: 255}}],
        ...uiContentValidateMeta
    }
};




