import TextField from "@material-ui/core/TextField/index";
import React from "react";

export default (props) => {
    const {name} = props;
    return <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id={name}
        label={name}
        autoFocus
        InputLabelProps={{shrink: true}}
        {...props}
    />
}
