import {Accommodation} from "../../../components/refrences/input/NoneStoreRelatedInputs";
import {findMasterDestinationAccommodations} from "../../../rest-data-provider/fetchs/accommodationFetch";
import {Field} from "react-final-form";
import React from "react";

export default props=>{
    const {destinationID} = props;
    return (<Field name={'Accommodation_Tier_Code'} subscription={{ value: true }}>
        {({ input: { value } }) => {
            return <Accommodation {...props}
                                  fetch={()=>findMasterDestinationAccommodations(destinationID,value)}
                                  fetchTrigger={new Date()}/>;
        }}
    </Field>)
}
