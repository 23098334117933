import React, {useEffect, useState} from "react";
import {addDays, convertToDate, formatDate} from "../../../utils/dateUtil";
import AddButton from "../../../components/Buttons/AddButton";
import {Dialog, DialogContent, DialogTitle, Menu, MenuItem} from "@material-ui/core";
import {SimpleForm, useNotify, CREATE, GET_ONE, GET_LIST} from 'react-admin';
import {typeInputs, fetchKeys} from "./ConditionalReservationInputs";
import dataProvider from "../../../rest-data-provider";
import {notifyError, notifyShow} from "../../../ec-admin-adapters/notifyF";
import {
    fromDestinationForNewReservation,
    fromDestinationForStep
} from "../../../rest-data-provider/fetchs/destinationFetch";
import {isValid} from "date-fns";
import Title from "./Title";
import SaveButton from "../../../components/Buttons/SaveButton";
import CancelButton from "../../../components/Buttons/CancelButton";
import DialogActions from "@material-ui/core/DialogActions";
import {GetForm} from "../../../components/forms/GetForm";
import ReservationValidator from '../../../validators/reservationValidator';
import axios from "axios";
import {basePath} from "../../../rest-data-provider/paths";

export const ReservationAddButton = ({record, steps, setSteps}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [show, setShow] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [serviceType, setServiceType] = useState(null);
    const [reservation, setReservation] = useState({});
    const [destinationName, setDestinationName] = useState(null);
    const [fromDestinationName, setFromDestinationName] = useState(null);
    const {Start_Date: startDate, Accommodation_Tier_Code, Destination_ID, Trip_ID, Step_Number, Reservations, id, Days} = record;
    const [form, setForm] = useState(null);

    const validator = new ReservationValidator().g_cr_var_fui();
    let reservations = [];
    if (Reservations && Reservations.length > 0) {
        reservations = Reservations.filter(Reservation => Reservation.ID);
    }
    let Start_Date = convertToDate(startDate)
    // can not get step Start_Date after create , and it will cause a bug, it's ok to use new Date(); because it will be refreshed after request list page.
    if (!isValid(Start_Date))
        Start_Date = new Date();
    // can not get step Days after edit , and it will cause a bug, it's ok to use 0; because it will be refreshed after request list page.
    const End_Date = formatDate(addDays(Start_Date, Days || 0));

    const notify = useNotify();
    // const refresh = useRefresh();
    const openMenu = event => {
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };
    const closeDialog = () => {
        setShow(false);
        setServiceType(null);
    };

    useEffect(() => {
        if (Destination_ID) {
            dataProvider(GET_ONE, 'destinations', {
                id: Destination_ID
            }).then(
                (resp) => {
                    if (resp && resp.data) {
                        setDestinationName(resp.data.Name);
                    }
                }
            ).catch(error => {
                notifyError(notify, error);
            })
        }
    }, [Destination_ID])

    useEffect(() => {
        if (serviceType) {
            const Sequence = ((reservations && reservations.length) || 0) + 1;
            const rs = {
                Trip_ID: Trip_ID,
                Step_Number: Step_Number,
                Trip_Step_ID: id,
                Start_Date: startDate,
                End_Date: startDate,
                Service_Type_Code: serviceType,
                Destination_ID: Destination_ID,
                Sequence
            };
            if (serviceType === 'AC') {
                rs.End_Date = End_Date;
                setReservation(rs);
                setShow(true);
            } else if (serviceType === 'TF') {
                fromDestinationForNewReservation(Trip_ID, Step_Number).then(
                    (fromDestination) => {
                        if (fromDestination) {
                            rs.From_Destination_ID = fromDestination;
                        //     dataProvider(GET_ONE, 'destinations', {
                        //         id: fromDestination
                        //     }).then(
                        //         (resp) => {
                        //             if (resp && resp.data) {
                        //                 setFromDestinationName(resp.data.Name);
                        //                 setReservation(rs);
                        //                 setShow(true);
                        //             }
                        //         }
                        //     )
                        // } else {

                        }
                        setReservation(rs);
                        setShow(true);
                    }
                );
            } else if (serviceType === 'AT') {

                rs.From_Destination_ID = Destination_ID;
                setReservation(rs);
                setShow(true);

            }
        }
    }, [serviceType]);

    const openDialog = (serviceType) => {
        setAnchorEl(null);
        setServiceType(serviceType);


    };

    const submitEvent = (values) => {
        setSubmitting(true);
        return dataProvider(CREATE, 'reservations', {data: values}).then((resp) => {
            const Trip_Step_ID = id;
            return axios.get(basePath + '/reservations/getStepList/' + Trip_Step_ID).then((response) => {
                steps[Trip_Step_ID]['Reservations'] = response.data;
                setSteps({...steps});
                closeDialog();
                setSubmitting(false);
            })
        }).catch(error => {
            notifyError(notify, error);
            closeDialog();
            setSubmitting(false);
        })
    }


    // get a string Trip_ID after create,so parse it to int.
    return (
        <div>
            <AddButton clickEvent={openMenu} label={'Add Reservation'}/>
            {serviceType ?
                <Dialog
                    disableEnforceFocus
                    fullWidth
                    open={show}
                    onClose={closeDialog}
                >
                    <DialogTitle>
                        <Title serviceType={serviceType} isEdit={false}
                               info={{'Destination': destinationName, Accommodation_Tier_Code, fromDestinationName}}/>
                    </DialogTitle>
                    <DialogContent>
                        <SimpleForm
                            record={reservation}
                            toolbar={null}
                            save={submitEvent}
                            validate={validator}
                        >
                            {
                                fetchKeys(typeInputs(serviceType, false, {
                                    tripID: parseInt(Trip_ID),
                                    destinationID: Destination_ID
                                }))
                            }
                            <GetForm setForm={setForm}/>
                        </SimpleForm>
                    </DialogContent>
                    <DialogActions>
                        <SaveButton disabled={submitting} clickEvent={() => {
                            form.submit();
                        }}/>
                        <CancelButton clickEvent={closeDialog}/>
                    </DialogActions>
                </Dialog> : null
            }

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                    closeMenu()
                }}
            >
                <MenuItem onClick={() => {
                    openDialog('TF')
                }}>Transfer</MenuItem>
                <MenuItem onClick={() => {
                    openDialog('AC')
                }}>Accommodation</MenuItem>
                <MenuItem onClick={() => {
                    openDialog('AT')
                }}>Activity</MenuItem>
            </Menu>
        </div>
    );
}
