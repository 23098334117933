import React from 'react'
import {
    List, downloadCSV, Datagrid, TextField, EditButton, DeleteButton,
    Filter,NumberField
} from 'react-admin'
import {unparse as convertToCSV} from 'papaparse/papaparse.min'
import idReducer from '../../ec-admin-adapters/idReducer'


const PricingFactorFilter = (props) => (
    <Filter {...props}>
    </Filter>
);

const exporter = pricingFactors => {
    pricingFactors = idReducer(pricingFactors)
    const csv = convertToCSV({
        data: pricingFactors,
        // fields: ['Final_Transfer_Origin', 'Name', 'Final_Transfer_Destination'] // order fields in the export
    });
    downloadCSV(csv, 'pricingFactors  ' + new Date()); // download as 'pricingFactors.csv` file
}

const PricingFactorTable = props => {
    return (
        <Datagrid {...props} >
            <TextField source="Sequence"/>
            <TextField source="Code"/>
            <TextField source="Type"/>
            <TextField source="PCT_Type" label='PCT Type'/>
            <TextField source="Target"/>
            <NumberField source="Amount"/>
            <TextField source="Description"/>
            <EditButton/>
        </Datagrid>
    )
}

export default props => {

    return (
        <List {...props} filters={<PricingFactorFilter/>} exporter={exporter}
              sort={{ field: 'Sequence', order: 'ASC' }}
        >
            <PricingFactorTable/>
        </List>
    )
}







