import React, {Fragment, useState} from "react";
import {Button, DialogActions, DialogContent, Dialog, Link, Menu, MenuItem} from "@material-ui/core";
import {InsertDriveFile,Cancel} from "@material-ui/icons";
import {useNotify} from 'react-admin'
import axios from "axios";
import {basePath} from "../../rest-data-provider/paths";
import parse from 'html-react-parser';
import {notifyError} from "../../ec-admin-adapters/notifyF";
import TextField from "@material-ui/core/TextField";
import {replaceParamVal} from "../../utils/paramtersUtil";
// import makeStyles from "@material-ui/core/styles/makeStyles";


// const useStyles = makeStyles({
//     paper: {
//         width: '1020px'
//     }
// });

const GuestDocsButton = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [show, setShow] = useState(false);
    const [action, setAction] = useState("");
    const [content,setContent] = useState('');
    const [zoom,setZoom] = useState(4);
    const notify = useNotify();
    const {tripID, agencyID} = props;
    // const classes  = useStyles();

    const openMenu = event => {
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setZoom(4);
        setAnchorEl(null);
    };

    const handleOpenClick = (action) => {
        closeMenu();
        // setDisabled(false);
        setAction(action);
        const url = `${basePath}/trips/docs/${action}/${agencyID + '-' + tripID}`;
        axios.get(url).then((response) => {
            setShow(true);
            setContent(response.data);
        }).catch(error => {
            notifyError(notify,error);
        })
    };

    return (
        <Fragment>
            <Button color="primary"  onClick={openMenu} label="ra.action.create">
                <InsertDriveFile/>Guest Docs
            </Button>
            <Dialog
                // classes={classes}
                disableEnforceFocus
                maxWidth="xl"
                fullWidth={false}
                open={show}
                onClose={()=>setShow(false)}
                aria-label="Add Step"
            >

                <DialogContent>
                    {content ?
                        <div>
                            {parse(content)}
                        </div>
                        : null
                    }
                </DialogContent>
                <DialogActions>
                    {(action==='preliminary'||action==='final')
                        ?
                        <TextField id="zoom" label="zoom" type='number' value={zoom} onChange={e=>{
                            const value = e.target.value;
                            if(value>0&& value<13){
                                setZoom(value);
                                const url = document.getElementById('gMap').getAttribute('src');
                                const nUrl = replaceParamVal("zoom",value,url);
                                document.getElementById('gMap').setAttribute("src", nUrl)
                            }
                        }}/>
                        :null
                    }
                    <Link target="_blank"  color="primary" href={`${basePath}/trips/docs/${action}/preview/${agencyID + '-' + tripID}?zoom=${zoom}`}>
                        Preview
                    </Link>
                    <Button
                        onClick={()=>setShow(false)}
                    >
                        <Cancel/>Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                    closeMenu()
                }}
            >
                <MenuItem onClick={() => {
                    handleOpenClick('preliminary');
                }}>Sample Itinerary</MenuItem>
                <MenuItem onClick={() => {
                    handleOpenClick('final');
                }}>Final Itinerary</MenuItem>
                <MenuItem onClick={() => {
                    handleOpenClick('summaryOfServices');
                }}>Summary of Services</MenuItem>
            </Menu>
        </Fragment>
    )
}
export default GuestDocsButton;
