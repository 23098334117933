import React from 'react'
import {
    TabbedForm, FormTab, TextInput, NumberInput,
    Create, BooleanInput
} from 'react-admin'
import Validator from '../../validators/destinationValidator';
import UIContentFormTab from "../../components/forms/UIContentFormTab";
import {Country, Status} from "../../components/refrences/input/RelatedInputs";
import DestinationChangeIsMaster from "./DestinationChangeIsMaster";
import IsMasterChangeDestination from "./airports/IsMasterChangeDestination";

export default props => {
    const validator = new Validator().g_cr_var_fui();
    return (
        <Create {...props}>
            <TabbedForm  validate ={validator} redirect='list'>
                <FormTab label='General'>
                    <TextInput source="Name" required/>
                    <NumberInput source="Latitude"/>
                    <NumberInput source="Longitude"/>
                    <TextInput source="Destination_Code" label='Destination Code'/>
                    <BooleanInput source="Is_Transfer_Only" label="Transfer-Only"/>
                    <IsMasterChangeDestination/>
                    <DestinationChangeIsMaster/>
                    <Country source='Country_Code'/>
                    <Status source="Status_Code" label='Status' defaultValue='Published'/>
                </FormTab>
                <UIContentFormTab/>
            </TabbedForm>
        </Create>
    )
}
