import AdaptorDownShift from "./AdaptorDownShift";
import React  from "react";
import PropTypes from "prop-types";
import {Field} from 'react-final-form';

const Component = (props)=> <AdaptorDownShift  {...props}/>

const BushDownShift = (props)=>{
    const{source,label,...others}=props;
    return <Field
        name={source}
        component={Component}
        label={label||source}
        {...others}
    />
}

BushDownShift.propTypes = {
    source: PropTypes.string.isRequired,
    label:PropTypes.string,
    fetch: PropTypes.func.isRequired

}

export default BushDownShift;
