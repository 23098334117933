import React from "react";
import {BooleanField} from 'react-admin';
import {_is_null} from "../../validators/simpleValidateGenerator";

export default (props)=>{
    const{record,source} = props;
    const paths = source.split(".");
    let value =record;
    let localRecord={};
    let field = localRecord;
    for(let i =0;i<paths.length && value;i++){
        const path = paths[i];
        value = value[path];

        if(i!==paths.length-1){
            field = field[path]={};
        }else{
            if(value && value===1){
                field[path]=true;
            }else{
                field[path]=false;
            }
        }
    }
    if(_is_null(value)){
        localRecord = record;
    }
    return <BooleanField {...props} record = {localRecord} />
}
