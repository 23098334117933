import {useForm, useFormState} from "react-final-form";
import React, {useEffect} from "react";
import {ServiceType} from "../../components/refrences/input/RelatedInputs";

export default props=>{
    const formState = useFormState();
    const form = useForm();
    const Activity_Code = formState.values['Activity_Code'];

    useEffect(()=>{
        if(Activity_Code&&Activity_Code==='PFEES'){
            form.change('Service.Service_Type_Code','PF');
        }else{
            form.change('Service.Service_Type_Code','AT');
        }
    },[Activity_Code])
    return  <ServiceType {...props} source='Service.Service_Type_Code' />
}
