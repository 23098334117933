import React, {Fragment} from "react";
import {
   Datagrid, TextField
} from 'react-admin';
import AmenityAddButton from "./AmenityAddButton";
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types'
import CustomDeleteButton from '../../../components/Buttons/CustomDeleteButton'
import {Amenity} from "../../../components/refrences/field/RelatedFields";

const useStyles = makeStyles({
    buttons: {
        display:'flex',
        'justify-content':'space-between'
    },
});

function recordToTitle(record){
    return 'Remove Amenity ('+(record.Amenity.Name)+') from Accommodation?';
}

const Amenities = (props) => {
    const classes = useStyles();
    const {accommodationID,...others} = props;
    return (
        <Fragment>
            <div className={classes.buttons}>
                <span/>
                <AmenityAddButton {...props}/>
            </div>

            <Datagrid style={{"width":"100%"}} {...others}  >
                <TextField source="Amenity_Code" label="Code"/>
                <Amenity source="Amenity_Code" label="Name" sortable={false}/>
                <CustomDeleteButton recordToTitle={recordToTitle}/>
            </Datagrid>
        </Fragment>
    )
}
Amenities.propTypes ={
    accommodationID: PropTypes.string.isRequired
}


export default Amenities;
