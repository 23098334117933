import React, {useEffect} from 'react'
import {
    Create, TextInput, TabbedForm, FormTab,BooleanInput
} from 'react-admin';
import Validator from '../../validators/destinationsToActivitiesValidator';
import {Destination, ServiceType, Status} from "../../components/refrences/input/RelatedInputs";
import Rates from "../rates";
import ActivitySelectInput from "../activities/ActivitySelectInput";
import UIContentFormTab from "../../components/forms/UIContentFormTab";
import {VendorSubsServiceLine} from "../services/VendorSubsServiceLine";
import DisabledBooleanInput from "../../components/inputs/DisabledBooleanInput";
import {useForm, useFormState} from "react-final-form";


export default props => {
    const validator = new Validator().g_cr_var_fui();
    return <Create title='Create Destination Activity' {...props} >
        <TabbedForm  validate ={validator} redirect='list'>
            <FormTab label="General">
                <Destination source='Destination_ID' label='Destination'/>
                <ActivitySelectInput/>
                <TextInput source="Name"/>
                <TextInput source="Service_Line_Identifier" label='Service Line Identifier'/>
                <VendorSubsServiceLine source="Service.Vendor_ID" label='Vendor Name'/>
                <Status source="Status_Code" label='Status' defaultValue='Published'/>
            </FormTab>
            <UIContentFormTab/>
            <FormTab label="Service" >
                <ServiceName/>
                <ServiceType source='Service.Service_Type_Code' defaultValue='AT'/>
                <DisabledBooleanInput source="Service.Priced" label='Priced' defaultValue={false}/>
                <BooleanInput source="Service.Needs_Update" label='Needs Update' defaultValue={false}/>
            </FormTab>
            <FormTab label="Rates">
                <Rates/>
            </FormTab>
        </TabbedForm>
    </Create>
}

const ServiceName = props=>{
    const form = useForm();
    const formState = useFormState();
    const ActivityName = formState.values['Name']||'';
    const Service = formState.values['Service'];
    let VendorName = '';
    if(Service&&Service['Vendor_Name']){
        VendorName = Service['Vendor_Name'];
    }
    useEffect(() => {
       form.change('Service.Name',VendorName+'-'+ActivityName)
    }, [ActivityName,VendorName]);
   return <TextInput source="Service.Name"  label="Name"  required/>;
}
