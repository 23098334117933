export default {
    primaryKey:'ID',
    commonValidate: {
        Trip_ID: ['Required',{isInt:{min:1,max: 4294967295}}],
        Day_Number: ['Required',{isInt:{min:1,max: 255}}],
        Notes: [{isLength:{min:1}}],
        Remarks: [{isLength:{min:1}}]
    },
}





