import React from "react";
import {DropzoneDialog} from "material-ui-dropzone";
import PropTypes from "prop-types";


const UploadFile= (props) => {
    const {show, setShow, saveEvent, filesLimit, fileTypes} = props;
    return <DropzoneDialog
        open={show}
        onSave={saveEvent}
        acceptedFiles={fileTypes}
        showPreviews={true}
        maxFileSize={5000000}
        onClose={()=>{setShow(false)}}
        filesLimit={filesLimit||1}
    />
}

UploadFile.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    saveEvent: PropTypes.func.isRequired,
    filesLimit:PropTypes.number,
    fileTypes: PropTypes.array.isRequired,
}


export default UploadFile;
