export default {
    primaryKey:'Code',
    commonValidate: {
        Host: ['Required',{isLength:{max: 100}}],
        Port: ['Required',{isInt:{max: 100000}}],
        Sender: ['Required','isEmail',{isLength:{max: 50}}],
        Auth_Code: ['Required',{isLength:{max: 50}}],
    },
}



