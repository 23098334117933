//!!!! important !! since browser client can not use fs to get paths of directory, so I have to add this array manually.
const api_paths=["accommodations","base","destinations","accommodationsToActivities",'destinationsToAirports',
    'airports','routes','accommodationsToAnimals','accommodationsToAmenities','accommodationsToLodgingTypes','transfers','routesToTransfers',
    'vendors','accommodationRoomTypes','rates','specials','accommodationsToSpecials','tripTemplates',
    'tripTemplateSteps','tripTemplateAccommodations','destinationsToActivities','trips','guests','tripSteps','reservations','services','reservationsToPackages','packagesToMilestones','tripDays','users',
    'agencies', 'increaseRate'
];
const apis ={};

(function(apis,api_paths){
    let providerClass;
    let path;
    for(path of api_paths){
        providerClass = require("./"+path);
        const provider = new providerClass.default();
        provider.callApi.bind(provider)
        apis[path] = provider.callApi;
    }
}(apis,api_paths))
export default (type, resource, params)=>{
    const callApi = apis[resource]||apis['base'];
    return callApi(type, resource, params);
};

