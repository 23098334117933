import React from "react";
import {BooleanInput} from 'react-admin';
import {useForm} from "react-final-form";
export default props=>{
    const form = useForm();
    return (
        <BooleanInput source='Is_Master_Destination' label='Is Master' onChange={value=>{
            if(value&&props.record){
                form.change('Master_Destination_ID',props.record.id);
            }else{
                form.change('Master_Destination_ID',null);
            }
        }}/>
    );
}
