import React from 'react'
import {
    Edit, TextInput, NumberInput, DateTimeInput
    , TabbedForm, FormTab, ReferenceManyField, Datagrid, EditButton,
    TextField,BooleanInput
} from 'react-admin'
import Validator from '../../validators/destinationValidator';
import Airports from "../destinations/airports";
import UIContentFormTab from "../../components/forms/UIContentFormTab";
import EditTitle from "../../components/forms/EditTitle";
import {Country, Status} from "../../components/refrences/input/RelatedInputs";
import DestinationChangeIsMaster from "./DestinationChangeIsMaster";
import IsMasterChangeDestination from "./airports/IsMasterChangeDestination";




export default props => {
    const validator = new Validator().g_up_var_f_ui(props.id)
    return <Edit title={<EditTitle EntityName='Destination' id={props.id} resource='destinations'/>} undoable={false} {...props} >

        <TabbedForm  validate ={validator}>
            <FormTab label='General'>
                <DateTimeInput disabled label='Created' source="Created_Time"/>
                <DateTimeInput disabled label='Modified' source="Modified_Time"/>
                <TextInput source="Name" required/>
                <NumberInput source="Latitude"/>
                <NumberInput source="Longitude"/>
                <TextInput source="Destination_Code" label='Destination Code'/>
                <BooleanInput source="Is_Transfer_Only" label="Transfer-Only" defaultValue={0} />
                <IsMasterChangeDestination/>
                <DestinationChangeIsMaster/>
                <Country source='Country_Code'/>
                <Status source="Status_Code" label='Status' defaultValue='Published'/>
            </FormTab>
            <UIContentFormTab/>
            <FormTab label ='Accommodations'>
                <ReferenceManyField reference="accommodations" target="Destination_ID" addLabel={false} sort={{ field: 'ID', order: 'ASC' }} >
                    <Datagrid style={{"width":"100%"}}>
                        <TextField source="ID" label="ID"/>
                        <TextField source="Name"/>
                        <TextField source="Status_Code" label="Status"/>
                        <EditButton/>
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
            <FormTab label="Airports">
                <ReferenceManyField reference="destinationsToAirports" target="Destination_ID" addLabel={false} sort={{ field: 'Airport_ID', order: 'ASC' }}>
                    <Airports destinationID={props.id}/>
                </ReferenceManyField>
            </FormTab>
        </TabbedForm>

    </Edit>
}
