import React from "react";
import {CREATE} from 'react-admin'
import dataProvider from "../../../rest-data-provider";
import PropTypes from "prop-types";
import {Amenity} from "../../../components/refrences/input/NoneStoreRelatedInputs";
import {notifyError} from "../../../ec-admin-adapters/notifyF";
import RelatedAddOrEditButton from "../../../components/Buttons/RelatedAddOrEditButton";


const AmenityAddButton =(props)=>{
    const {accommodationID} = props;
    const submitEvent = ({notify,refresh,values,setShow})=>{
        dataProvider(CREATE, 'accommodationsToAmenities', {data: values}).then(() => {
            refresh();
            setShow(false);
        }).catch(error => {
            notifyError(notify,error);
        })
    }

    return <RelatedAddOrEditButton title='Add Amenity' record={{Accommodation_ID:accommodationID}} submitEvent={submitEvent}>
        <Amenity/>
    </RelatedAddOrEditButton>
}

AmenityAddButton.propTypes = {
    accommodationID: PropTypes.string.isRequired
}

export default AmenityAddButton;
