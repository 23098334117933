import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import {
    SimpleForm,
    NumberInput,
    useNotify,
    BooleanInput,
    SelectInput,
    CREATE,
    UPDATE,
    useRefresh
} from 'react-admin'
import dataProvider from "../../../rest-data-provider";
import axios from "axios";
import {basePath} from "../../../rest-data-provider/paths";
import Validator from "../../../validators/routesToTransfersValidator";
import {Destination} from "../../../components/refrences/input/NoneStoreRelatedInputs";
import {notifyError} from "../../../ec-admin-adapters/notifyF";
import HiddenInput from "../../../components/inputs/HiddenInput";
import {Status} from "../../../components/refrences/input/RelatedInputs";
import {GetForm} from "../../../components/forms/GetForm";


const  TransferForm = props => {
    const refresh = useRefresh();
    const notify = useNotify();
    const {initialValues, cancelEvent,setForm} = props;
    const validator = _initValidator();
    const [data, setData] = useState([]);
    const [record,setRecord] = useState({});

    function _initValidator() {
        if(initialValues.ID)
            return new Validator().g_up_var_f_ui(initialValues.ID);
        else
            return new Validator().g_cr_var_fui();
    }


    useEffect(() => {
        setRecord(initialValues);
        const From_Destination_ID = initialValues.From_Destination_ID;
        initData(From_Destination_ID);
    }, []);


    function initData(From_Destination_ID) {

        if (From_Destination_ID)
            axios.get(basePath + '/transfers/getListWithDestination?limit=1000&offset=0&order=ASC&sort=ID&Status_Code=Published&From_Destination_ID=' + From_Destination_ID).then(response => {
                const data = response.data;
                setData(data || [])
            })
        else {
            setData([]);
        }
    }



    function _setToDestinationsChoices() {

        const filterChoices = _filterChoices(['From_Destination_ID'], 'To_Destination_ID', record);
        const toDestinationsChoices = filterChoices.map(choice => {
            return {id: choice.To_Destination_ID, name: choice.To_Destination.Name}
        })
        return toDestinationsChoices;
    }


    function _setTransferModeChoices() {
        const filterChoices = _filterChoices(['From_Destination_ID', 'To_Destination_ID'], 'Transfer_Mode_Code', record);

        const transferModeChoices = filterChoices.map(choice => {
            return {id: choice.Transfer_Mode_Code, name: choice.Transfer_Mode.Name}
        })
        return transferModeChoices;
    }

    function _setServiceLineIdentifierChoices() {
        const filterChoices = _filterChoices(['From_Destination_ID', 'To_Destination_ID', 'Transfer_Mode_Code'], 'Service_Line_Identifier', record);
        const serviceLineIdentifierChoices = filterChoices.map(choice => {
            return {id: choice.Service_Line_Identifier, name: choice.Service_Line_Identifier}
        })
        return serviceLineIdentifierChoices;
    }

    function _filterChoices(parentFieldArray, uniqueField, values) {
        const uniqueFieldArray = [];
        return data.filter(choice => {
            for (let parentField of parentFieldArray) {
                if (choice[parentField] + '' !== values[parentField] + '') {
                    return false;
                }
            }
            const uniqueValue = choice[uniqueField];
            if (!uniqueValue)
                return false;
            if (uniqueFieldArray.includes(uniqueValue)) {
                return false;
            }

            uniqueFieldArray.push(uniqueValue);
            return true;
        })

    }

    function handleSubmit(values) {
        if(values.ID){
            dataProvider(UPDATE, 'routesToTransfers', {data: values,id:values.ID}).then(() => {
                refresh();
            }).catch(error => {
                notifyError(notify, error);
            })
        }else{
            dataProvider(CREATE, 'routesToTransfers', {data: values}).then(() => {
                refresh();
            }).catch(error => {
                notifyError(notify, error);
            })
        }
    }

    return (
        <SimpleForm redirect={false}
                    submitOnEnter={false}
                    save={handleSubmit}
                    toolbar={null}
                    resource="routesToTransfers"
                    initialValues={record}
                    validate={validator}
        >

            <HiddenInput source='ID'/>
            <HiddenInput source='Route_ID'/>
            <Destination source='From_Destination_ID' label='From Destination' required
                         selectedCallback={({id}) => {
                             const {From_Destination_ID,...others} = record;
                             setRecord({...others,From_Destination_ID:id});
                             initData(id);
                         }}/>

            <SelectInput key='transfer_form_key_t_d' source='To_Destination_ID' label='To Destination'
                         onChange={(e) => {
                             const {To_Destination_ID,Transfer_Mode_Code,Service_Line_Identifier,...others} = record;
                             setRecord({...others,To_Destination_ID:e.target.value});
                         }
                         }
                         choices={_setToDestinationsChoices()} required/>
            <SelectInput key='transfer_form_key_t_m' source='Transfer_Mode_Code' label='How'
                         onChange={(e) => {
                             const {Transfer_Mode_Code,Service_Line_Identifier,...others} = record;
                             setRecord({...others,Transfer_Mode_Code:e.target.value});
                         }}
                         choices={_setTransferModeChoices()} required/>
            <SelectInput key='transfer_form_key_s_l' source='Service_Line_Identifier' label='Service Line Identifier'
                         onChange={(e) => {
                             setRecord({...record,Service_Line_Identifier:e.target.value});
                         }}
                         choices={_setServiceLineIdentifierChoices()} required/>
            <NumberInput key='transfer_form_key_s' source='Sequence' onChange={(e) => {
                setRecord({...record,Sequence:e.target.value});
            }} required/>
            <BooleanInput key='transfer_form_key_i_o' source='Is_Overnight' onChange={(value) => {
                setRecord({...record,Is_Overnight:value});
            }}
            />
            <Status source="Status_Code" label='Status' defaultValue='Published' required/>
            <GetForm setForm={setForm}/>
        </SimpleForm>
    )
}


TransferForm.propTypes = {
    cancelEvent: PropTypes.func.isRequired,
    setForm: PropTypes.func.isRequired,
}

export default TransferForm;
