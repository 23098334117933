import React, {useEffect, useState} from "react";
import {TextInput, DateInput, NumberInput, GET_ONE} from 'react-admin'
import {DrinksBasis, MealBasis, Passengers, RoomConfiguration} from "../../../components/refrences/input/RelatedInputs";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
    Accommodation,
    Destination,
    Activity
} from "../../../components/refrences/input/NoneStoreRelatedInputs";
import {getDays, getTime, minutesToTime} from "../../../utils/dateUtil";
import BushFormSpyField from "../../../components/forms/BushFormSpyField";
import TimeInput from "../../../components/inputs/TimeInput";
import {findMasterDestinationAccommodations} from "../../../rest-data-provider/fetchs/accommodationFetch";
import ReservationService from "./ReservationService";
import {useForm, useFormState} from "react-final-form";
import PAX from "./PAX";
import StartEndDate from "./StartEndDate";
import dataProvider from "../../../rest-data-provider";
import HiddenInput from "../../../components/inputs/HiddenInput";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

const NightsSubsEvent = ({values, form}) => {
    const {Start_Date, End_Date} = values;
    useEffect(() => {
        if (Start_Date && End_Date) {
            const nights = getDays(Start_Date, End_Date);
            form.change('Nights', nights);
        } else {
            form.change('Nights', 0);
        }
    }, [Start_Date, End_Date]);
}

const Nights = props => {
    return <BushFormSpyField subsEvent={NightsSubsEvent}>
        <NumberInput {...props} source='Nights' disabled/>
    </BushFormSpyField>;
}

const commonACInput = [
    <TextInput fullWidth  source='Service_Line_Identifier' lable='Service Line Identifier' disabled/>,
    <DateInput source='Start_Date' label='Start Date'/>,
    <DateInput source='End_Date' label='End Date'/>,
    <Nights/>,
    <TextInput source='Reference_Number' label='Reference #'/>,
    <MealBasis/>,
    <DrinksBasis/>,
    <TextInput fullWidth multiline source='Included_Note' lable='Included'/>,
    <TextInput fullWidth multiline source='Remarks' lable='Remarks'/>,
    <RoomConfiguration/>
]



const AccommodationChangeIncludeNote = (props) => {
    const {destinationID} = props;
    const form = useForm();
    return <Accommodation {...props} fetch={() => findMasterDestinationAccommodations(destinationID)}
                          selectedCallback={({id}) => {
                              dataProvider(GET_ONE, 'accommodations', {
                                  id
                              }).then(
                                  (resp) => {
                                      if (resp.data && resp.data.UI_Content) {
                                          form.change('Included_Note', resp.data.UI_Content.Included_Note);
                                      } else {
                                          form.change('Included_Note', '');
                                      }
                                  }
                              )
                          }}/>;
}

const addACInputs = (props) => {
    const {tripID} = props;
    // const form = useForm();
    return [
        <AccommodationChangeIncludeNote {...props} key='Reservation-Add-Accommodation'/>,
        <ReservationService sort={["Service_Type_Code DESC", "Name ASC"]}/>,
        ...commonACInput,
        <Passengers tripID={parseInt(tripID)}/>,
        <PAX/>
    ]
}


const editACInputs = addACInputs;
//     (props) => {
//     const {tripID,destinationID} = props;
//     return [
//         <Accommodation key='Reservation-Add-Accommodation' fetch={()=>findMasterDestinationAccommodations(destinationID)}/>,
//         <ReservationService/>,
//         ...commonACInput,
//         <Passengers tripID={parseInt(tripID)}/>,
//         <PAX/>
//     ]
// }

const ReservationDestination =(props)=>{
    const form  =useForm();
    const {source}=props;
    return  <Destination {...props} source={source||'From_Destination_ID'} selectedCallback={() => {
        form.change('Service_ID', null);
    }
    }/>;
}

const useInputStyles = makeStyles({
    margin: {
        marginRight: '8px',
        width: '200px'
    },
    parentStyle: {
        display: 'flex',
        alignItems: 'baseline',
    },
    formControlLabel: {
        width: '4rem',
        textAlign: 'center',
        fontSize: '0.75rem',
    }
});

const DepartureGroup = props => {
    const form = useForm();
    const formState = useFormState();
    const { Start_Time } = formState.values;
    const classes = useInputStyles();
    const [isScheduled, setIsScheduled] = useState(Start_Time ? true : false);
    const setSchedule = (val) => {
        setIsScheduled(val);
        if(!val) form.change('Start_Time', null);
    }
    return (
        <div className={classes.parentStyle}>
            <DateInput {...props} source='Start_Date' label='Departure Date' className={classes.margin}/>
            <FormControlLabel
                control={<Switch color="primary" checked={isScheduled} onChange={(e) => setSchedule(e.target.checked)} />}
                label={<Typography className={classes.formControlLabel}>Departure Scheduled</Typography>}
                labelPlacement="top"
            />
            <TimeInput {...props} source='Start_Time' label='Departure Time' disabled={!isScheduled}/>
        </div>
    )
}
const ArrivalGroup = props => {
    const form = useForm();
    const formState = useFormState();
    const { End_Time } = formState.values;
    const classes = useInputStyles();
    const [isScheduled, setIsScheduled] = useState(End_Time ? true : false);
    const setSchedule = (val) => {
        setIsScheduled(val);
        if(!val) form.change('End_Time', null);
    }
    return (
        <div className={classes.parentStyle}>
            <DateInput {...props} source='End_Date' label='Arrival Date' className={classes.margin}/>
            <FormControlLabel
                control={<Switch color="primary" checked={isScheduled} onChange={(e) => setSchedule(e.target.checked)} />}
                label={<Typography className={classes.formControlLabel}>Arrival Scheduled</Typography>}
                labelPlacement="top"
            />
            <TimeInput {...props} source='End_Time' label='Sched Arrival Time' disabled={!isScheduled}/>
        </div>
    )
}

const DurationSubsEvent = ({values, form}) => {
    const {Start_Time, End_Time,Start_Date,End_Date} = values;
    useEffect(() => {
        if(Start_Date&&End_Date&&Start_Time&&End_Time){
            form.change('Duration_Time', getTime(Start_Date+" "+Start_Time, End_Date+" "+End_Time));
        }
    }, [Start_Time, End_Time,Start_Date,End_Date]);
}


const commonTFInputs = [
    <ReservationDestination label={"From Destination"}/>,
    <ReservationDestination label={"To Destination"} source={"To_Destination_ID"}/>,
    <ReservationService sort={["Service_Type_Code DESC", "Name ASC"]}/>,
    <TextInput fullWidth  source='Service_Line_Identifier' lable='Service Line Identifier' disabled/>,
    <HiddenInput   source='Transfer_ID' label='Transfer ID'/>,
    <TextInput   source='Airline' label='Airline'/>,
    <TextInput   source='Flight_Number' label='Flight Number'/>,
    <DepartureGroup/>,
    <TextInput fullWidth multiline source='Departure_Location' label='Departure Location'/>,
    <ArrivalGroup/>,
    <TextInput fullWidth multiline source='Arrival_Location' label='Arrival Location'/>,
    <TextInput source='Duration_Minutes' label='Approx Duration' format={v => {
        if(v){
            return v+ ' Minutes';
        }
        return '';
        }} disabled/>,
    <TextInput source='Duration_Time' label='Edit Approx Duration'/>,
    <TextInput source='Reference_Number' label='Reference #'/>,
]

const addTFInputs = (props) => {
    const {tripID} = props;
    return [
        ...commonTFInputs,
        <Passengers tripID={parseInt(tripID)}/>,
        <PAX/>,
        <TextInput fullWidth multiline source='Remarks' lable='Remarks'/>
    ]
}

const editTFInputs = (props) => {
    const {tripID} = props;
    return [
        ...commonTFInputs,
        <Passengers tripID={parseInt(tripID)}/>,
        <PAX/>,
        <TextInput fullWidth multiline source='Remarks' lable='Remarks'/>
    ]
}


const TimeGroup = props => {
    const classes = useInputStyles();
    return (<div>
        <TimeInput {...props} source='Start_Time' label='Departure Time' className={classes.margin}/>
        <TimeInput {...props} source='End_Time' label='Arrival Time' className={classes.margin}/>
        <PlannedDuration/>
    </div>)
}

const PlannedDuration = props => {
    return <BushFormSpyField subsEvent={DurationSubsEvent}>
        <TextInput {...props} source='Duration_Time' label='Planned Duration'/>
    </BushFormSpyField>;
}

const LocationGroup = props => {
    const classes = useInputStyles();
    return (<div>
        <TextInput fullWidth multiline   {...props} source='Departure_Location' label='Departure Location'
                   className={classes.margin}/>
        <TextInput fullWidth multiline   {...props} source='Arrival_Location' label='Arrival Location'/>
    </div>)
}

const ActivityAccommodation = (props) => {
    const formState = useFormState();
    const {From_Destination_ID} = formState.values;
    const [fetch, setFetch] = useState(null);
    const [fetchTrigger, setFetchTrigger] = useState(null);
    const form = useForm();
    useEffect(() => {
        setFetchTrigger(new Date());
        setFetch(() => {
            return () => findMasterDestinationAccommodations(From_Destination_ID)
        })
    }, [From_Destination_ID])
    return fetch ?
        <Accommodation {...props} fetchTrigger={fetchTrigger} fetch={fetch} allowEmpty={true} selectedCallback={() => {
            form.change('Service_ID', null);
        }
        }/> : null;
}



const commonATInputs = [
    <StartEndDate/>,
    <DateInput source='End_Date' label='End Date'/>,
    <ReservationDestination/>,
    <ActivityAccommodation/>,
    <ReservationService sort={["Service_Type_Code DESC", "Name ASC"]}/>,
    <TextInput fullWidth  source='Service_Line_Identifier' lable='Service Line Identifier' disabled/>,
    <TextInput source='Activity_Name' label='Activity Name' disabled/>,
    <TextInput source='Reference_Number' label='Reference #'/>,
    <TextInput fullWidth multiline source='Included_Note' lable='Included'/>,
    <TextInput fullWidth multiline source='Remarks' lable='Remarks'/>
];


const addATInputs = (props) => {
    const {tripID} = props;
    return [
        ...commonATInputs,
        <Passengers tripID={parseInt(tripID)}/>,
        <PAX/>,
        <TimeGroup/>,
        <LocationGroup/>
    ]
};
const editATInputs = (props) => {
    const {tripID} = props;
    return [
        ...commonATInputs,
        <Passengers tripID={parseInt(tripID)}/>,
        <PAX/>,
        <TimeGroup/>,
        <LocationGroup/>
    ]
}


export const typeInputsMap = {
    'AC': {edit: editACInputs, add: addACInputs},
    'TF': {edit: editTFInputs, add: addTFInputs},
    'AT': {edit: editATInputs, add: addATInputs},
    'PF': {edit: editATInputs, add: addATInputs}
}

export function typeInputs(serviceType, isEdit, props) {
    const serviceTypeInputs = typeInputsMap[serviceType];
    let inputs = null;
    if (serviceTypeInputs) {
        inputs = serviceTypeInputs[isEdit ? 'edit' : 'add'];
    }
    if (typeof inputs === 'function') {
        return inputs(props);
    }

    return null;

}

export const fetchKeys = (inputs) => {
    if (inputs && inputs.length) {
        return inputs.map((input, index) => {
            return React.cloneElement(input, {key: index})
        })
    }
    return null;

}
