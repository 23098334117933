import React from 'react'
import {
    DateTimeInput,TabbedForm,FormTab,
    Edit, TextInput,NumberInput
} from 'react-admin'
import Validator from '../../validators/activityValidator'
import UIContentFormTab from "../../components/forms/UIContentFormTab";
import EditTitle from "../../components/forms/EditTitle";
import {Status} from "../../components/refrences/input/RelatedInputs";

export default props => {
    const validator = new Validator().g_up_var_f_ui(props.id);
    return <Edit undoable={false} title={<EditTitle EntityName='Activity' id={props.id} resource='activities'/>} {...props}>
        <TabbedForm  validate ={validator}>
            <FormTab label="General">
            <DateTimeInput disabled label='Created' source="Created_Time"/>
            <DateTimeInput disabled label='Modified' source="Modified_Time"/>
            <TextInput source="Name" required/>
            <TextInput source="Code" required/>
            <Status source="Status_Code" label='Status' defaultValue='Published'/>
            <NumberInput source="Sequence" />
            </FormTab>
            <UIContentFormTab/>
        </TabbedForm>
    </Edit>
}
