import React from 'react'
import {
    Edit, TextInput, DateTimeInput
    , TabbedForm, FormTab, SaveButton, Toolbar, BooleanInput
} from 'react-admin'
import Validator from '../../validators/accommodationRoomTypeValidator';
import EditTitle from "../../components/forms/EditTitle";
import {withStyles} from "@material-ui/core";
import CustomDeleteButton from "../../components/Buttons/CustomDeleteButton";
import Rates from "../rates";
import HiddenInput from "../../components/inputs/HiddenInput";
import {Accommodation} from "../../components/refrences/input/RelatedInputs";
import {VendorSubsAccommodation} from "../services/VendorSubsAccommodation";
import AccommodationRoomServiceLine from "./AccommodationRoomServiceLine";

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

function recordToTitle(record) {
    if (record && record.Name && record.Accommodation)
        return 'Remove RoomType (' + (record.Name) + '[' + record.Accommodation.Name + ']) from Accommodation?';
    return '';
}

function recordEditTitle(record) {
    if (record && record.Name && record.Accommodation)
        return record.Name + ' [' + record.Accommodation.Name + ']';
    return '';
}

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton/>
        <CustomDeleteButton recordToTitle={recordToTitle}/>
    </Toolbar>
));

export default props => {
    const validator = new Validator().g_up_var_f_ui(props.id)
    return <Edit
        title={<EditTitle EntityName='Accommodation Room Type' id={props.id} recordToTitle={recordEditTitle} resource='accommodationRoomTypes'/>}
        undoable={false} {...props} >

        <TabbedForm  validate ={validator} toolbar={<CustomToolbar/>}>
            <FormTab label="General">
                <DateTimeInput disabled label='Created' source="Created_Time"/>
                <DateTimeInput disabled label='Modified' source="Modified_Time"/>
                <TextInput source="Name" required/>
                <Accommodation/>
                <VendorSubsAccommodation source="Service.Vendor_ID" label='Vendor Name'/>
                <BooleanInput source='Is_Default_for_Accommodation' label='Is Default For Accommodation'/>
                <TextInput source='Room_Type_Code' label='Room Type Code'/>
                <AccommodationRoomServiceLine/>
            </FormTab>
            <FormTab label="Service">
                <HiddenInput source="Service.ID" label="ID" required disabled/>
                <TextInput source="Service.Name" label="Name" required/>
                <TextInput source="Service.Service_Type_Code" label='Service Type Code' defaultValue='AC' disabled/>
                <BooleanInput source="Service.Priced" label='Priced' />
                <BooleanInput source="Service.Needs_Update" label='Needs Update'/>
            </FormTab>
            <FormTab label="Rates">
                <Rates />
            </FormTab>
        </TabbedForm>
    </Edit>
}


