import React from "react";
import {UPDATE,DateInput} from 'react-admin'
import dataProvider from "../../../rest-data-provider";
import {Milestone} from "../../../components/refrences/input/NoneStoreRelatedInputs";
import {notifyError} from "../../../ec-admin-adapters/notifyF";
import Validator from '../../../validators/packagesToMilestonesValidator';
import RelatedAddOrEditButton from "../../../components/Buttons/RelatedAddOrEditButton";


const MilestoneEditButton =(props)=>{
    const {record} = props;
    const submitEvent = ({notify,refresh,values,setShow})=>{
        dataProvider(UPDATE, 'packagesToMilestones', {data: values,id:values.id}).then(() => {
            refresh();
            setShow(false);
        }).catch(error => {
            notifyError(notify,error);
        })
    };

    const validator = new Validator().g_up_var_f_ui(record.ID);
    return <RelatedAddOrEditButton record={record}  title='Add Milestone' submitEvent={submitEvent} validator={validator}>
        <Milestone disabled/>
        <DateInput source='Date'/>
    </RelatedAddOrEditButton>
};


export default MilestoneEditButton;
