import React from 'react'
import {
    Edit, TextInput, BooleanInput, DateTimeInput, SimpleForm
} from 'react-admin'
import Validator from '../../validators/animalValidator'
import EditTitle from "../../components/forms/EditTitle";

export default props => {
    const validator = new Validator().g_up_var_f_ui(props.id)
    return <Edit title={<EditTitle EntityName='Animal' id={props.id} resource='animals'/>} undoable={false} {...props} >
        <SimpleForm  validate ={validator}>
            <DateTimeInput disabled label='Created' source="Created_Time"/>
            <DateTimeInput disabled label='Modified' source="Modified_Time"/>
            <TextInput source="Name" required/>
            <TextInput source="Code" required/>
            <BooleanInput source="Is_Big_Five" label='Is Big Five'/>
            <BooleanInput source="Is_Bird_Six" label='Is Bird Six'/>
            <TextInput source="Sequence"/>
        </SimpleForm>

    </Edit>
}
