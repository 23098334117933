import React, {Fragment, useEffect, useState} from 'react'
import {
    List, Datagrid, TextField, useRefresh,
    Filter, EditButton,useNotify, TopToolbar, SelectInput
} from 'react-admin'
import {
    Destination
} from "../../components/refrences/field/RelatedFields";
import {Trip} from "../../components/refrences/input/RelatedInputs";
import {ArrowUpward, ArrowDownward, Add, CardTravel} from "@material-ui/icons";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {Button} from "@material-ui/core";
import axios from "axios";
import {basePath} from "../../rest-data-provider/paths";
import StatedTable from "../../components/tables/StatedTable";
import ReservationEditButton from "./reservations/ReservationEditButton";
import {ReservationAddButton} from "./reservations/ReservationAddButton";
import {useFormState} from "react-final-form";
import DateZoneField from "../../components/Fields/DateZoneField";
import CustomDeleteButton from "../../components/Buttons/CustomDeleteButton";
import ReservationDeleteButton from "./reservations/ReservationDeleteButton";
import {notifyError} from "../../ec-admin-adapters/notifyF";
import auth from "../auth/AuthProvider";

const TripFilter = props => {
    const {setTripID, ...others} = props;
    const formState = useFormState();
    const tripID = formState.values['Trip_ID'];
    useEffect(() => {
        setTripID(tripID);
    }, [setTripID, tripID]);
    const ownerID = formState.values['Trip_Owner_ID'];
    return <Trip {...others} filter={{role: auth.getRole(), agency: auth.getAgency(), owner: auth.getUserID(), Trip_Owner_ID: ownerID}} />
}

const TripStepFilter = (props) => {
    const {setTripID, ...others} = props;
    return (
        <Filter {...others}>
            <TripFilter setTripID={setTripID} source='Trip_ID' label='Trip' alwaysOn/>
            <SelectInput source='Trip_Owner_ID' label='Trip Owner' emptyText='Show all trips' choices={[{name:'Show my trips',id: auth.getUserID()}]} alwaysOn />
        </Filter>
    )
};


const UpButton = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const {record} = props;
    const {Step_Number, Trip_ID} = record;
    return <Button style={{color: "#3F51B5"}} onClick={() => {
        axios.put(basePath + "/tripSteps/upStep", {Step_Number, Trip_ID}).then((() => {
                refresh();
            }
        )).catch(error => {
            let msg = (error.response && error.response.data)||error.message;
            notifyError(notify,msg);
        })
    }}>
        <ArrowUpward/>
    </Button>
}

const DownButton = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const {record} = props;
    const {Step_Number, Trip_ID} = record;
    return <Button style={{color: "#3F51B5"}} onClick={() => {
        axios.put(basePath + "/tripSteps/upStep", {Step_Number: (Step_Number + 1), Trip_ID}).then((() => {
                refresh();
            }
        )).catch(error => {
            let msg = (error.response && error.response.data)||error.message;
            notifyError(notify,msg);
        })
    }}>
        <ArrowDownward/>
    </Button>
}

const ReservationUpButton = (props) => {
    const notify = useNotify();
    const {row,Trip_Step_ID,steps,setSteps} = props;
    const {Sequence} = row;
    return <Button style={{color: "#3F51B5"}} onClick={() => {
        axios.put(basePath + "/reservations/upReservation", {Sequence, Trip_Step_ID}).then((() => {
                axios.get(basePath + '/reservations/getStepList/' + Trip_Step_ID).then((response) => {
                    steps[Trip_Step_ID]['Reservations']=response.data;
                    setSteps({...steps});
                })
            }
        )).catch(error => {
            notifyError(notify,error.message);
        })
    }}>
        <ArrowUpward/>
    </Button>
}

const ReservationDownButton = (props) => {
    const notify = useNotify();
    const {row,Trip_Step_ID,steps,setSteps} = props;
    const {Sequence} = row;
    return <Button style={{color: "#3F51B5"}} onClick={() => {
        axios.put(basePath + "/reservations/upReservation", {Sequence:(Sequence+1), Trip_Step_ID}).then((() => {
                axios.get(basePath + '/reservations/getStepList/' + Trip_Step_ID).then((response) => {
                    steps[Trip_Step_ID]['Reservations']=response.data;
                    setSteps({...steps});
                })
            }
        )).catch(error => {
            notifyError(notify,error.message);
        })
    }}>
        <ArrowDownward/>
    </Button>
}


const ReservationTable = (props) => {
    const {steps,setSteps} = props;
    const {Trip_ID, ID, Destination_ID,  Accommodation_Tier_Code} = props.record;
    if(!steps)
        return <div/>;
    const Reservations = steps[ID]['Reservations'];
    const rows = Reservations.filter(reservation => reservation.ID);
    return <StatedTable stateParams={{Trip_ID, Trip_Step_ID: ID, Destination_ID, Accommodation_Tier_Code}}
                        fields={["Sequence", "Start_Date", "Service_Type_Code", "Service_Name", "PAX"]}
                        components={
                            [<ReservationUpButton steps={steps} setSteps={setSteps}/>,<ReservationDownButton steps={steps} setSteps={setSteps}/>,<ReservationEditButton steps={steps} setSteps={setSteps}/> ,<ReservationDeleteButton  steps={steps} setSteps={setSteps}/> ]
                        }
                        selectCallBack={props.selectCallBack} rows={rows}
    />
}


const AddStep = (props) => {
    const {history, record} = props;

    return <Button color="primary" onClick={() => {
        history.push("/tripSteps/create?Step_Number=" + (record.Step_Number + 1) + "&Trip_ID=" + record.Trip_ID + "&From_Destination_ID=" + record.Destination_ID)
    }} label="ra.action.create">
        <Add/>Add Step
    </Button>
}

function recordToTitle(record) {
    return 'Remove Step (' + (record.Step_Number) + ') from Trip?';
}

const TripStepTable = props => {

    const [selectedChildrenGroup, setSelectedChildrenGroup] = useState({});
    const [steps, setSteps] = useState(undefined);
    const selectCallBack = (childrenGroup) => {
        // seems no need for this.
        selectedChildrenGroup[childrenGroup.stateParams] = childrenGroup.selected;
        setSelectedChildrenGroup(selectedChildrenGroup);
    }
    const {history, data, ...others} = props;
    useEffect(() => {
        setSteps(data);
    }, [data]);

    return (
        <Datagrid {...others} data={data}
                  expand={<ReservationTable selectCallBack={selectCallBack} steps={steps} setSteps={setSteps}/>}
        >
            <TextField source='Step_Number' sortable={false}/>
            <DateZoneField source='Start_Date' label='Start Date' sortable={false}/>
            <TextField source='Days' label='Nights' sortable={false}/>
            <Destination source='Destination_ID' label='Destination' sortable={false}/>
            <TextField source='Country_Name' label='Country' sortable={false}/>
            <UpButton label='Step Up' sortable={false}/>
            <DownButton label='Step Down' sortable={false}/>
            <EditButton/>
            <AddStep history={history}/>
            <ReservationAddButton setSteps={setSteps} steps={steps}/>
            <CustomDeleteButton recordToTitle={recordToTitle}/>
        </Datagrid>
    )
}


const BulkActions = () => {
    return <Fragment>
    </Fragment>
};

const AddFirstStep = (props) => {
    const {history, tripID} = props;
    const notify = useNotify();
    if (!tripID) {
        notify('No Trip specified!')
    }
    return <Button color="primary" onClick={() => {
        history.push("/tripSteps/create?Step_Number=1&Trip_ID=" + tripID)
    }} label="ra.action.create">
        <Add/>Add First Step
    </Button>
}

const Packages = props => {
    const {history, tripID} = props;
    return <Button color="primary"
                   onClick={() => history.push('/reservationsToPackages?filter=%7B"Trip_ID"%3A' + tripID + '%7D')}>
        <CardTravel/>packages
    </Button>
}

const Actions = props => {
    const {className, ...others} = props;
    return <TopToolbar className={className}>
        <AddFirstStep {...others}/>
        <Packages {...others}/>
    </TopToolbar>
}


const TripStepList = props => {
    const [tripID, setTripID] = useState(null);
    const {history} = props;
    return (
        <List {...props} filters={<TripStepFilter setTripID={setTripID}/>} exporter={false}
              actions={
                  tripID
                      ? <Actions tripID={tripID} history={history}/>
                      : null}
              bulkActionButtons={<BulkActions/>}
              sort={{field: 'Step_Number', order: 'ASC'}}
              title='Trip Steps'
        >
            <TripStepTable history={history}/>
        </List>
    )
};

export default TripStepList;








