import React, {useEffect, useState} from "react";
import {TextInput, NumberInput, CREATE, UPDATE, GET_ONE} from 'react-admin'
import dataProvider from "../../rest-data-provider";
import {notifyError, notifyShow} from "../../ec-admin-adapters/notifyF";
import RelatedAddOrEditButton from "../../components/Buttons/RelatedAddOrEditButton";
import auth from "../auth/AuthProvider";

const PDFSettingAddOrEditButton = ()=>{
    const [data, setData] = useState({
        Code: "PDF-" + auth.getAgency(),
        Top: 96,
        Left: 96,
        Right: 48,
        Bottom: 0,
        Width: 820,
        Height: 1060,
        Footer_Height: 78,
        Agency_ID: auth.getAgency(),
    });
    const [isEdit, setIsEdit] = useState(false);
    const [refresh, setRefresh] = useState(0);
    useEffect(() => {
        dataProvider(GET_ONE, 'pdfSettings', {id: 'PDF-' + auth.getAgency()}).then((res) => {
            setData(res.data);
            setIsEdit(true);
        }).catch(error => {
            setIsEdit(false);
        })
    }, [refresh]);
    const submitEvent=({notify,values,setShow})=>{
        dataProvider(isEdit ? UPDATE : CREATE, 'pdfSettings', {data: values, id: 'PDF-' + auth.getAgency()}).then(() => {
            setRefresh(refresh + 1);
            setShow(false);
            setIsEdit(true);
            notifyShow(notify, isEdit ? "Element updated." : "New element added.");
        }).catch(error => {
            notifyError(notify,error);
        })
    }
    return <RelatedAddOrEditButton title='PDF Settings' record={data} submitEvent={submitEvent}>
            <div >
                <TextInput source="Code" required disabled/>
                <NumberInput source="Top" required/>
                <NumberInput source="Left" required/>
                <NumberInput source="Right" required/>
                <NumberInput source="Bottom" required/>
                <NumberInput source="Width" required disabled/>
                <NumberInput source="Height" required disabled/>
                <NumberInput source="Footer_Height" label='Footer Height' required/>
            </div>
    </RelatedAddOrEditButton>
}

export default PDFSettingAddOrEditButton;
