import React from 'react'
import {
    Edit, TextInput, NumberInput, DateTimeInput, SimpleForm
} from 'react-admin'
import Validator from '../../validators/increaseRateValidator'
import EditTitle from "../../components/forms/EditTitle";
import SaveButtonToolbar from "../../components/forms/SaveButtonToolbar";

export default props => {
    const validator = new Validator().g_up_var_f_ui(props.id)
    return <Edit title={<EditTitle EntityName='Increase Rate' id={props.id} resource='increaseRate'/>} undoable={false} {...props} >
        <SimpleForm validate={validator} toolbar={<SaveButtonToolbar/>} >
            <DateTimeInput disabled label='Created' source="Created_Time"/>
            <DateTimeInput disabled label='Modified' source="Modified_Time"/>
            <TextInput source="Name" required/>
            <NumberInput source="Year" required/>
            <NumberInput source="Rate" required label={"Increase Rate (Percent)"}/>
        </SimpleForm>

    </Edit>
}
