import DestinationsToActivitiesList from './DestinationsToActivitiesList';
import DestinationsToActivitiesCreate from './DestinationsToActivitiesCreate';
import DestinationsToActivitiesEdit from './DestinationsToActivitiesEdit';

export default {
    list: DestinationsToActivitiesList,
    create: DestinationsToActivitiesCreate,
    edit: DestinationsToActivitiesEdit

};
