import React, {Fragment} from 'react'
import {
    List, downloadCSV, Datagrid, TextField, EditButton, DeleteButton,
    Filter
} from 'react-admin'
import {unparse as convertToCSV} from 'papaparse/papaparse.min'
import idReducer from '../../ec-admin-adapters/idReducer'
import BooleanFieldWithNumber from "../../components/Fields/BooleanFieldWithNumber";
import ChangePasswordButton from "./ChangePasswordButton";
import { AgencyName } from '../../components/refrences/field/RelatedFields'

const UserFilter = (props) => (
    <Filter {...props}>
    </Filter>
);

const exporter = users => {
    users = idReducer(users)
    const csv = convertToCSV({
        data: users,
        // fields: ['Final_Transfer_Origin', 'Name', 'Final_Transfer_Destination'] // order fields in the export
    });
    downloadCSV(csv, 'users  ' + new Date()); // download as 'users.csv` file
}

const UserTable = props => {
    return (
        <Datagrid {...props} >
            <TextField source="Email"/>
            <TextField source="Name"/>
            <TextField source="Role"/>
            <AgencyName source="Agency_ID" label="Agency"/>
            <BooleanFieldWithNumber source="Is_Active" label='Is Active' />
            <ChangePasswordButton />
            <EditButton/>
        </Datagrid>
    )
}

export default props => {

    return (
        <List {...props} filters={<UserFilter/>} exporter={exporter}
              sort={{ field: 'Name', order: 'ASC' }}
              bulkActionButtons={<Fragment/>}
        >
            <UserTable/>
        </List>
    )
}







