import baseValidator from './baseValidator'

const commonValidate = {
        From_Destination_ID: ['Required',{isInt:{min: 1,max:4294967295}}],
        To_Destination_ID: ['Required',{isInt:{min: 1,max:4294967295}}],
        Status_Code: ['Required',{isLength:{max: 15}}],
    }
class RouteValidator extends baseValidator {
    meta = {commonValidate}
    uiMeta={
        resources: "routes",
    }

    constructor() {
        super();
        this.g_cr_var_fui = super.g_cr_var_fui.bind(this)
        this.g_up_var_f_ui = super.g_up_var_f_ui.bind(this)
    }
}

export default RouteValidator;




