import React from 'react'
import {
    Create, TextInput, TabbedForm, FormTab,BooleanInput
} from 'react-admin';
import Validator from '../../validators/accommodationsToActivitiesValidator';
import ActivitySelectInput from './ActivitySelectInput'
import Rates from "../rates";
import {Accommodation, Status} from "../../components/refrences/input/RelatedInputs";
import UIContentFormTab from "../../components/forms/UIContentFormTab";
import {VendorSubsAccommodation} from "../services/VendorSubsAccommodation";
import AccommodationActivityServiceType from "./AccommodationActivityServiceType";
import AccommodationActivityServiceLine from "./AccommodationActivityServiceLine";
import AccommodationActivityServiceCode from "./AccommodationActivityServiceCode";
import DisabledBooleanInput from "../../components/inputs/DisabledBooleanInput";

export default props => {
    const validator = new Validator().g_cr_var_fui();
    return <Create title='Create Accommodation Activity' {...props} >
        <TabbedForm  validate ={validator} redirect='list'>
            <FormTab label="General">
                <Accommodation />
                <BooleanInput source="Auto_Assign" label="Auto Assign " defaultValue={0} />
                <ActivitySelectInput/>
                <TextInput source="Name"/>
                <AccommodationActivityServiceCode/>
                <AccommodationActivityServiceLine/>
                <VendorSubsAccommodation source="Service.Vendor_ID" label='Vendor Name'/>
                <Status source="Status_Code" label='Status' defaultValue='Published'/>
            </FormTab>
            <UIContentFormTab/>
            <FormTab label="Service" >
                <TextInput source="Service.Name" label="Name" required/>
                <AccommodationActivityServiceType  defaultValue='AT'/>
                <DisabledBooleanInput source="Service.Priced" label='Priced' defaultValue={false}/>
                <BooleanInput source="Service.Needs_Update" label='Needs Update' defaultValue={false}/>

            </FormTab>
            <FormTab label="Rates">
                <Rates/>
            </FormTab>
        </TabbedForm>
    </Create>
}

