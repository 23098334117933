import {CREATE,GET_MANY,GET_LIST, GET_MANY_REFERENCE, GET_ONE} from 'react-admin';

export default (response,type,primaryKey)=>{
    const {data} = response;
    switch (type) {
        case CREATE:
            data.id = data[primaryKey];
            break;
        case GET_MANY:
        case GET_LIST:
        case GET_MANY_REFERENCE:
            data.map(item => item.id = item[primaryKey]);
            break;
        case GET_ONE:
            data.id = data[primaryKey];
            break;
        default:
    }
}
