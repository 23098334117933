import React, {useEffect, useState} from 'react'
import {
    Create,TextInput, SimpleForm,BooleanInput,NumberInput
} from 'react-admin';

import Validator from '../../validators/reservationsToPackagesValidator'

import {getParameters} from "../../utils/paramtersUtil";
import HiddenInput from "../../components/inputs/HiddenInput";
import {VendorChange} from "./VendorChangePackageCode";
import {CustomBackToolbar} from "../../components/forms/CustomBackToolbar";


export default props => {
    const validator = new Validator().g_cr_var_fui();
    const {history} = props;
    const {search} = props.location;
    const parameters = getParameters(search);
    const {Trip_ID} = parameters;
    const [redirect, setRedirect] = useState('/reservationsToPackages');
    useEffect(()=>{
        setRedirect( '/reservationsToPackages?filter=%7B"Trip_ID"%3A'+Trip_ID+'%7D');
    },[Trip_ID]);


    function back() {
        history.push(redirect)
    }

    return <Create title='Create Reservation Package' {...props} >
        <SimpleForm  validate ={validator} redirect={redirect} toolbar={<CustomBackToolbar back={back} redirect={redirect}/>}>
            <HiddenInput disabled  source="Trip_ID" label='Trip ID' defaultValue={Trip_ID}/>
            <VendorChange source='Billing_Vendor_ID'/>
            <TextInput source='Code' disabled/>
            <NumberInput source='Price' defaultValue={0.0} disabled/>
            <BooleanInput source='Is_Gratuity_Included' label='Is Gratuity Included'/>
            <TextInput source='Vendor_Invoice_Number' label='Vendor Invoice Number'/>
            <TextInput source='Quote_Number' label='Quote Number'/>
            <TextInput fullWidth multiline  source='Remarks'/>
        </SimpleForm>
    </Create>
}
