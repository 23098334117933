export default (props,defaultSource,defaultLabel)=>{
    const {source,label}=props;
    const newProps = Object.assign({selected: false}, props);
    if(!source&&defaultSource){
        newProps.source=defaultSource;
    }
    if(!label&&defaultLabel){
            newProps.label=defaultLabel;
    }
    return newProps;
}
