import Slide from "@material-ui/core/Slide";
import React from "react";
import {makeStyles} from "@material-ui/core";
import {green} from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    success:{
        backgroundColor: green[600],
    }
}));

export function ErrorSlideTransition(props) {
    const classes = useStyles();
    return <Slide {...props} direction="up" className={classes.error}/>;
}

export function SuccessSlideTransition(props) {
    const classes = useStyles();
    return <Slide {...props} direction="up" className={classes.success}/>;
}
