import React, {Fragment, useState} from "react";
import {Button, Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {TripOrigin} from "@material-ui/icons";
import PropTypes from "prop-types";
import TripRequestForm from './TripRequestForm'
import DialogActions from "@material-ui/core/DialogActions";
import SaveButton from "../../components/Buttons/SaveButton";
import CancelButton from "../../components/Buttons/CancelButton";

const TripRequestButton = props=>{
    const [show,setShow] = useState(false);
    const [form,setForm] = useState(undefined);
    return  <Fragment>
        <Button color="primary" onClick={()=>{setShow(true)}}>
            <TripOrigin/>Trip Request
        </Button>
        <Dialog
            disableEnforceFocus
            fullWidth
            open={show}
            onClose={()=>{setShow(false)}}
        >
            <DialogTitle>Create New Trip</DialogTitle>
            <DialogContent>
                <TripRequestForm tripTemplateID={props.tripTemplateID} tripTemplateName={props.tripTemplateName} saveEvent={()=>{setShow(false)}} cancelEvent={()=>{setShow(false)}} setForm={setForm}/>
            </DialogContent>
            <DialogActions>
                <SaveButton clickEvent={()=>{
                    form.submit();
                }}/>
                <CancelButton clickEvent={()=>{setShow(false)}}/>
            </DialogActions>
        </Dialog>
    </Fragment>

}

TripRequestButton.propTypes = {
    tripTemplateID: PropTypes.number.isRequired,
    tripTemplateName:PropTypes.string.isRequired,
}

export default TripRequestButton;
