import React from 'react'
import {
    List, downloadCSV, Datagrid, TextField, EditButton, DeleteButton, Filter,
} from 'react-admin'
import {unparse as convertToCSV} from 'papaparse/papaparse.min'
import idReducer from '../../ec-admin-adapters/idReducer'
import {Status} from "../../components/refrences/input/RelatedInputs";


const RegionFilter = (props) => (
    <Filter {...props}>
       <Status source="Status_Code" label='Status' alwaysOn  allowEmpty={true}/>
    </Filter>
);

const exporter = regions => {
    regions = idReducer(regions)
    const csv = convertToCSV({
        data: regions,
        // fields: ['Final_Transfer_Origin', 'Name', 'Final_Transfer_Destination'] // order fields in the export
    });
    downloadCSV(csv, 'regions  ' + new Date()); // download as 'regions.csv` file
}

const RegionTable = props => {
    return (
        <Datagrid {...props} >
            <TextField source="Sequence"/>
            <TextField source="Name"/>
            <TextField source="Status_Code" label="Status"/>
            <EditButton/>
            <DeleteButton undoable={false}/>
        </Datagrid>
    )
}

export default props => {

    return (
        <List {...props} filters={<RegionFilter/>} exporter={exporter}
              sort={{ field: 'Sequence', order: 'ASC' }}
              filterDefaultValues={{Status_Code: 'Published'}}
        >
            <RegionTable/>
        </List>
    )
}







