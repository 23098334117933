

// Passengers:(value)=>{if(!Array.isArray(value)||value.length===0){
//     return "Required";
// }}
export default {
    primaryKey:'ID',
    commonValidate: {
        Start_Date:['Required',{LTT:'End_Date'}],
        Reference_Number:[{isLength:{min:1,max:100 }}],
        Airline:[{isLength:{min:1,max:100 }}],
        Flight_Number:[{isLength:{min:1,max:100 }}],
        Departure_Location:[{isLength:{min:1,max:100 }}],
        Arrival_Location:[{isLength:{min:1,max:100 }}],
        Meal_Basis_Code:[{isLength:{min:1,max:15 }}],
        Drinks_Basis_Code:[{isLength:{min:1,max:15 }}],
        Included_Note:[{isLength:{min:1,max:1000 }}],
        PAX:['Required',{isInt:{min:1,max:4294967295 }}],
        Service_ID:['Required',{isInt:{min:1,max:4294967295 }}],
    }
}



