export default {
    primaryKey:'Code',
    uniqueFields: ['Name','Code'],
    commonValidate: {
        Code: ['Required',{isLength:{max: 15}}],
        Name: ['Required',{isLength:{max: 100}}],
        Sequence: [{isInt:{min:0,max: 255}}],
    },
}



