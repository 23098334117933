import RestoreButton from "./RestoreButton";
import CustomDeleteButton from "./CustomDeleteButton";
import React from "react";

export default (props)=>{
    const {restoreRecordToTitle,removeRecordToTitle,...others}=props;
    if (props.record && props.record.Status_Code === 'Deleted')
        return <RestoreButton {...others} recordToTitle={restoreRecordToTitle}/>
    return <CustomDeleteButton {...others} recordToTitle={removeRecordToTitle}/>
}
