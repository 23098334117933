import uiContentValidateMeta from "./uiContentValidateMeta";

export default {
    primaryKey:'ID',
    commonValidate: {
        Name: ['Required',{isLength:{max: 100}}],
        Sequence: [{isInt:{min:0,max: 255}}],
        Price_From: [{isFloat:{min:-9999999999.99,max:9999999999.99}}],
        Age_Minimum:[{isInt:{min:0,max: 255}}],
        Start_Date: [{LTT:'End_Date'}],
        ...uiContentValidateMeta
    }
}



