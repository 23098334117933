import baseValidator from './baseValidator'
import meta from '../validateMetas/userValidateMeta'

class UserValidator extends baseValidator {
    meta = meta
    uiMeta={
        resources: "users",
    }

    constructor() {
        super();
        this.g_cr_var_fui = super.g_cr_var_fui.bind(this)
        this.g_up_var_f_ui = super.g_up_var_f_ui.bind(this)
    }
}

export default UserValidator;

export const loginValidator = ({Email,Password})=>{
    if(!Email){
        return'Email is required'
    }
    if(Email.length>120){
        return'Email should be less than 120 characters'
    }
    if(!Password){
        return'Password is required'
    }
    if(Password.length>20){
        return'Password should be less than 20 characters'
    }
}

// export default ({Email,Password,Name,Role})=>{
//     let loginMsg = loginValidator({Email,Password});
//     if(loginMsg){
//         return loginMsg;
//     }
//     if(!Name){
//         return'Name is required'
//     }
//     if(Name.length>20){
//         return'The length of Name should not over 20'
//     }
//     if(!Role){
//         return'Role is required'
//     }
//     if(Role.length>20){
//         return'The length of Role should not over 20'
//     }
// }

export const changePasswordValidator = ({Email,Password,NewPassword,ConfirmPassword})=>{
    let loginMsg = loginValidator({Email,Password});
    if(loginMsg){
        return loginMsg;
    }
    if(!NewPassword){
        return'New password is required'
    }
    if(NewPassword.length>20){
        return'Password should be less than 20 characters'
    }
    if(ConfirmPassword!==NewPassword){
        return 'New and old passwords must be different'
    }
}
