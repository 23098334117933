import baseValidator from './baseValidator'
import meta from '../validateMetas/countryValidateMeta'

class CountryValidator extends baseValidator {
    meta = meta;
    uiMeta={
        resources: "countries",
    }
    constructor() {
        super();
        this.g_cr_var_fui = super.g_cr_var_fui.bind(this)
        this.g_up_var_f_ui = this.g_up_var_f_ui.bind(this)
    }
}



export default CountryValidator;




