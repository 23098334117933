import React, {Fragment, useState} from "react";
import {Button, DialogActions, DialogContent, DialogTitle, Dialog} from "@material-ui/core";
import {Schedule, Cancel, Save} from "@material-ui/icons";
import {useNotify, useRefresh} from 'react-admin'
import axios from "axios";
import {basePath} from "../../rest-data-provider/paths";
import parse from 'html-react-parser';
import {notifyError} from "../../ec-admin-adapters/notifyF";

const DaysButton = props => {
    const [show, setShow] = useState(false);
    const [days, setDays] = useState(undefined);
    const notify = useNotify();
    const refresh = useRefresh();
    const {record} = props;
    const handleOpenClick = () => {
        axios.get(basePath + '/tripTemplates/days/' + record.ID).then((response) => {
            setShow(true);
            setDays(response.data);
        }).catch(error => {
            notifyError(notify,error);
        })
    };

    const handleSaveClick = () => {
        axios.put(basePath + '/tripTemplates/days/' + record.ID, {Days: days.total}).then((response) => {
            setShow(false);
            setDays(undefined);
            refresh();
        }).catch(error => {
            notifyError(notify,error);
        })
    };

    return (
        <Fragment>
            <Button color="primary" onClick={handleOpenClick} label="ra.action.create">
                <Schedule/>Days
            </Button>
            <Dialog
                disableEnforceFocus
                fullWidth
                open={show}
                onClose={()=>setShow(false)}
                aria-label="Add Step"
            >
                <DialogTitle>Step Days</DialogTitle>
                <DialogContent>
                    {days ?
                        <div>
                            {parse(days.html)}
                            <div>total:{days.total}</div>
                        </div>
                        : null
                    }

                </DialogContent>
                <DialogActions>
                    <Button color="primary"
                            onClick={handleSaveClick}
                    >
                        <Save/>update
                    </Button>
                    <Button
                        onClick={()=>setShow(false)}
                    >
                        <Cancel/>Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default DaysButton;
