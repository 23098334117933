import React, {Fragment, useState} from "react";
import {Button, DialogActions, DialogContent, DialogTitle, Dialog} from "@material-ui/core";
import {AttachMoneySharp, Cancel, Save} from "@material-ui/icons";
import {useNotify, useRefresh} from 'react-admin'
import axios from "axios";
import {basePath} from "../../rest-data-provider/paths";
import parse from 'html-react-parser';
import {notifyError} from "../../ec-admin-adapters/notifyF";
import TextField from "@material-ui/core/TextField";
import {_is_null} from "../../validators/simpleValidateGenerator";

const CostsButton = props => {
    const [show, setShow] = useState(false);
    const [costs, setCosts] = useState({Price:0});
    const notify = useNotify();
    const refresh = useRefresh();
    const {record} = props;
    const handleOpenClick = () => {
        axios.get(basePath + '/reservationsToPackages/costs/' + record.ID).then((response) => {
            setShow(true);
            setCosts(response.data);
        }).catch(error => {
            notifyError(notify,error);
        })
    };

    const handleSaveClick = () => {
        if(_is_null(costs.Price_Error)){
            axios.put(basePath + '/reservationsToPackages/costs/' + record.ID, {Price: costs.Price}).then((response) => {
                setShow(false);
                refresh();
            }).catch(error => {
                notifyError(notify,error);
            })
        }
    };

    return (
        <Fragment>
            <Button color="primary" onClick={handleOpenClick} label="ra.action.create">
                <AttachMoneySharp/>Costs
            </Button>
            <Dialog
                disableEnforceFocus
                fullWidth
                maxWidth='xl'
                open={show}
                onClose={()=>setShow(false)}
            >
                <DialogTitle>Calculated Costs</DialogTitle>
                <DialogContent>
                    {costs.html ?
                        <div>
                            {parse(costs.html)}
                        </div>
                        : null
                    }

                    <div>
                        <TextField
                            required
                            id="filled-required"
                            label="Price"
                            value={costs.Price}
                            variant="filled"
                            error={!_is_null(costs.Price_Error)}
                            helperText={costs.Price_Error}
                            style={{margin:"16px 0px 0px 16px"}}
                            onChange={(e)=>{
                                const value = e.target.value;
                                if(_is_null(value))
                                    setCosts({...costs,Price:0});
                                else if(!isNaN(value)&&value>=0){
                                    const Price_Error = (value>=1000000)?'Price should not over 1000000':"";
                                    setCosts({...costs,Price:parseFloat(value),Price_Error});
                                }
                            }}
                        />
                    </div>

                </DialogContent>
                <DialogActions>

                    <Button color="primary"
                            onClick={handleSaveClick}
                    >
                        <Save/>update
                    </Button>
                    <Button
                        onClick={()=>setShow(false)}
                    >
                        <Cancel/>Cancel
                    </Button>

                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default CostsButton;
