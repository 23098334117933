import React, {useEffect, useState} from 'react'
import {
    Edit,
    TabbedForm,
    FormTab,
    NumberInput,
    TextInput,
    BooleanInput,
    DateTimeInput,
    GET_ONE,
    ReferenceManyField,
} from 'react-admin';

import Validator from '../../validators/reservationsToPackagesValidator'
import EditTitle from "../../components/forms/EditTitle";

import dataProvider from "../../rest-data-provider";
import Milestones from "./milestones";
import HiddenInput from "../../components/inputs/HiddenInput";
import {VendorChange} from "./VendorChangePackageCode";
import {CustomBackToolbar} from "../../components/forms/CustomBackToolbar";



export default props => {
    const validator = new Validator().g_up_var_f_ui(props.id)
    const {history} = props;
    const [redirect, setRedirect] = useState('/reservationsToPackages');
    useEffect(() => {
        if (props.id) {
            dataProvider(GET_ONE, 'reservationsToPackages', {
                id: props.id
            }).then(
                (step) => {
                    if (step && step.data) {
                        const tripID = step.data.Trip_ID;
                        setRedirect('/reservationsToPackages?filter=%7B"Trip_ID"%3A' + tripID + '%7D');
                    }
                }
            )
        }
    }, [props.id]);

    function back() {
        history.push(redirect)
    }

    return <Edit title={<EditTitle EntityName='Reservation Package' id={props.id} resource='reservationsToPackages'/>}
                 undoable={false} {...props} >
        <TabbedForm  validate ={validator} redirect={redirect} toolbar={<CustomBackToolbar back={back} redirect={redirect}/>}>
            <FormTab label="General">
                <DateTimeInput disabled label='Created' source="Created_Time"/>
                <DateTimeInput disabled label='Modified' source="Modified_Time"/>
                <HiddenInput disabled source="Trip_ID" label='Trip ID'/>
                <VendorChange source='Billing_Vendor_ID'/>
                <TextInput source='Code' disabled/>
                <NumberInput source='Price' defaultValue={0.0} disabled/>
                <BooleanInput source='Is_Gratuity_Included' label='Is Gratuity Included'/>
                <TextInput source='Vendor_Invoice_Number' label='Vendor Invoice Number'/>
                <TextInput source='Quote_Number' label='Quote Number'/>
                <TextInput fullWidth multiline  source='Remarks'/>
            </FormTab>
            <FormTab label="Milestones">
                <ReferenceManyField reference="packagesToMilestones" target="Reservation_Package_ID" addLabel={false}
                                    sort={{order: 'ASC', field: 'ID'}}>
                    <Milestones packageID={props.id}/>
                </ReferenceManyField>
            </FormTab>
        </TabbedForm>
    </Edit>
}

