import React from 'react'
import {
    Edit, TextInput,DateInput,NumberInput,TabbedForm,FormTab,ReferenceManyField,DateTimeInput,BooleanInput
} from 'react-admin';

import Validator from '../../validators/tripValidator'
import EditTitle from "../../components/forms/EditTitle";
import TripDays from "./tripDays";
import {Guest, Trip_Template, TripTypes, TripOwnerMode, AgencyMode} from "../../components/refrences/input/RelatedInputs";
import auth from '../auth/AuthProvider';

export default props => {
    const validator = new Validator().g_up_var_f_ui(props.id)
    return <Edit  title={<EditTitle EntityName='Trip' id={props.id} resource='trips'/>} undoable={false} {...props} >
        <TabbedForm  validate ={validator} submitOnEnter={false}>
            <FormTab label="General">
            <TextInput source="Name" required/>
            <Guest/>
            <Trip_Template label='Based on' required/>
            <AgencyMode resource="Agency" label="Agency" required/>
            {/*<TextInput source='Trip_Template.Name' label='Based on' disabled/>*/}
            <DateInput source='Start_Date'/>
            <NumberInput source='Passenger_Count' label='Number of PAX' required/>
            <NumberInput source='Price_Per_Pax' label='Price Per Person'/>
            <TripTypes required/>
            {auth.getRole().toLowerCase() === 'admin' && <TripOwnerMode source='Trip_Owner_ID' label='Trip Owner' /> }
            <BooleanInput source='Update_Salesforce' label='Update Salesforce'/>
            <DateTimeInput disabled label='Created' source="Created_Time"/>
            <DateTimeInput disabled label='Modified' source="Modified_Time"/>
            </FormTab>
            <FormTab label="Trip Days">
                <ReferenceManyField reference="tripDays" target="Trip_ID" addLabel={false} sort={{order:'ASC',field:'Day_Number'}}>
                    <TripDays tripID={props.id}/>
                </ReferenceManyField>
            </FormTab>
        </TabbedForm>
    </Edit>
}
