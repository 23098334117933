import React from "react";
import {
    FormTab,TextInput,BooleanInput
} from 'react-admin'

export default (props)=>{
    return <FormTab label="UI Content" {...props}>
        <BooleanInput source='UI_Content.Show_on_Web' label='Show on Web'/>
        <TextInput fullWidth multiline  source='UI_Content.Image_URL_1' label='Image URL 1'/>
        <TextInput fullWidth multiline  source='UI_Content.Image_URL_2' label='Image URL 2'/>
        <TextInput fullWidth multiline  source='UI_Content.URL' label='Image URL'/>
        <TextInput fullWidth multiline  source="UI_Content.Page_Title" label="Page Title"/>
        <TextInput fullWidth multiline  source="UI_Content.Subtitle" label='Subtitle' />
        <TextInput fullWidth multiline  source="UI_Content.Meta_Description" label="PWA Description" />
        <TextInput fullWidth multiline  source="UI_Content.Description" label='Website Description'/>
        <TextInput fullWidth multiline  source="UI_Content.Comments" label='Comments'/>
        <TextInput fullWidth multiline  source="UI_Content.Keywords" label='Keywords' />
        <TextInput fullWidth multiline  source="UI_Content.Included_Note" label="Included Note" />
        <TextInput fullWidth multiline  source="UI_Content.Available_Note" label="Available Note" />
    </FormTab>
}
