import React from 'react'
import {
    Edit, NumberInput, DateTimeInput,BooleanInput,TabbedForm, FormTab,TextInput
} from 'react-admin'
import Validator from '../../validators/transferValidator';
import EditTitle from "../../components/forms/EditTitle";
import Rates from "../rates";

import {Destination, Status, TransferMode} from "../../components/refrences/input/RelatedInputs";
import HiddenInput from "../../components/inputs/HiddenInput";
import {VendorSubsServiceLine} from "../services/VendorSubsServiceLine";


const recordToTitle = (record, resources) => {
    const {From_Destination_ID, To_Destination_ID} = record;
    const destinations = resources.destinations.data;
    return ((destinations[From_Destination_ID] && destinations[From_Destination_ID].Name) || 'Unknown') + " To " + ((destinations[To_Destination_ID] && destinations[To_Destination_ID].Name) || 'Unknown');
}

// can not abstract TransferForm since a validation tab warning bug of react-admin.
const EditForm = props => {
    const {record} = props;
    if(record.Source_ID)
        return <div>Reverse Transfer is not Editable</div>
    return (
        <TabbedForm  {...props}  submitOnEnter={false} >
            <FormTab label='General'>
                <DateTimeInput disabled label='Created' source="Created_Time"/>
                <DateTimeInput disabled label='Modified' source="Modified_Time"/>
                <Destination label='From Destination' source='From_Destination_ID'/>
                <Destination label='To Destination' source='To_Destination_ID'/>
                <TransferMode label='Transfer Mode'source='Transfer_Mode_Code'/>
                <TextInput source='Service_Line_Identifier' label='Service Line Identifier'/>
                <VendorSubsServiceLine source="Service.Vendor_ID" label='Vendor Name'/>
                <NumberInput source="Duration_Minutes"/>
                <Status source="Status_Code" label='Status' defaultValue='Published'/>
                <BooleanInput source='Is_Bidirectional' label='Is Bidirectional'/>
                <HiddenInput source="Service.ID" label="ID" required disabled/>
                <TextInput source="Service.Name"  label="Name"  required/>
                <TextInput source="Service.Service_Type_Code" label='Service Type Code' defaultValue='TF' disabled />
                <NumberInput source="Service.Capacity" label="Capacity" />
                <BooleanInput source="Service.Priced" label='Priced' />
                <BooleanInput source="Service.Needs_Update" label='Needs Update' defaultValue={false}/>
            </FormTab>
            <FormTab label="Rates">
                <Rates/>
            </FormTab>
        </TabbedForm>
    )
}


export default props => {
    const validator = new Validator().g_up_var_f_ui(props.id);
    return <Edit
        title={<EditTitle EntityName='Transfer' id={props.id} resource='transfers' recordToTitle={recordToTitle}/>}
        undoable={false} {...props}>
        <EditForm  validate ={validator}/>
    </Edit>
}


