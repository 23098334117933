export const notifyError=(notify, error)=>{
    let msg = (error.response&&error.response.data)||(error+'');
    if(Object.prototype.toString.call(msg)==='[object Object]') {
        msg = JSON.stringify(msg);
    }
    notify(msg,'warning');
}

export const notifyShow=(notify,msg)=>{
    notify(msg,'info');
}
