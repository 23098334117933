import React from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    listItem: {
        paddingLeft: '1rem',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    listItemIcon: {
        minWidth: '43.2px',
    },
    listItemText: {
        paddingLeft: 2,
        fontSize: '1rem',
        color:'red'
    },
    sidebarIsOpen: {
        paddingLeft: 25,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
    sidebarIsClosed: {
        paddingLeft: 0,
        transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
}));

const SubMenu = ({
    handleToggle,
    sidebarIsOpen,
    isOpen,
    name,
    icon,
    children,
}) => {
    const classes = useStyles();
    return (
        <div>
            <ListItem
                dense
                button
                onClick={handleToggle}
                className={classes.listItem}
            >
                <ListItemIcon className={classes.listItemIcon}>{isOpen ? <ExpandMore/> : icon}</ListItemIcon>

                {name}
                {/*<ListItemText*/}
                {/*    primary={name}*/}
                {/*    className={classes.listItemText}*/}
                {/*/>*/}
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                    dense
                    component="div"
                    disablePadding
                    className={
                        sidebarIsOpen
                            ? classes.sidebarIsOpen
                            : classes.sidebarIsClosed
                    }
                >
                    {children}
                </List>
                <Divider/>
            </Collapse>
        </div>)
};


export default SubMenu;
