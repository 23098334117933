export default {
    primaryKey:'ID',
    uniqueFields: ['Email'],
    commonValidate: {
        Email: ['Required',{isLength:{max: 120}}],
        Name: ['Required',{isLength:{max: 20}}],
        Role: ['Required',{isLength:{max: 20}}],
        Agency_ID: ['Required'],
    },
    createValidate:{
        Password: ['Required',{isLength:{max: 100}}]
    }
}



