import uiContentValidateMeta from "./uiContentValidateMeta";

export default {
    primaryKey:'ID',
    commonValidate: {
        Name: ['Required',{isLength:{max: 100}}],
        Latitude: [{isFloat:{min:-999999.999999,max:999999.999999}}],
        Longitude: [{isFloat:{min:-999999.999999,max:999999.999999}}],
        Supplier_Code: ['Required',{isLength:{min:6,max: 6}}],
        Sequence:[{isInt:{min:1,max:4294967295 }}],
        Res_Request_Identifier:[{isLength:{max: 100}}],
        Status_Code: ['Required',{isLength:{max: 15}}],
        Vendor_ID:[{isInt:{min:1}}],
        Destination_ID:[{isInt:{min:1}}],
        Default_Airport_ID:[{isInt:{min:1}}],

        Lodge_Price_Code:[{isLength:{max:100}}],
        Affiliate_Code:[{isLength:{max:15}}],
        Affiliate_Internal_Code:[{isLength:{max:100}}],
        Affiliate_Interface:[{isInt:{min:1}}],

        Ecosystem_Code:[{isLength:{max:15}}],
        Age_Minimum:[{isInt:{max:255}}],
        Age_Minimum_Note:[{isLength:{max:100}}],
        Bed_Count:[{isInt:{min:0}}],
        Bed_Count_Note:[{isLength:{max:100}}],


        Final_Transfer_Mode_Code:[{isLength:{max:15}}],
        Final_Transfer_Origin:[{isLength:{max:100}}],
        Final_Transfer_Destination:[{isLength:{max:100}}],
        Duration_Minutes:[{isInt:{min:1}}],
        Price_Code:[{isLength:{max:100}}],
        Price_From: [{isFloat:{min:0,max:9999999999.99}}],
        ...uiContentValidateMeta
    }
}







