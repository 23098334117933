import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    span: {
        marginRight:'8px',
        display:'inline-block',
        fontSize:'1rem'
    },
});


export default useStyles;
