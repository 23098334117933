import axios from "axios";
import {basePath} from "../../rest-data-provider/paths";

export default (history)=>{
    axios.delete(basePath + "/users/loginOut").then(()=>{})
    localStorage.removeItem('Email');
    setTimeout(() => {
        history.push("/#login")
    }, 2000);
}
