import React from 'react';
import {List, Datagrid, TextField, EditButton, Filter,NullableBooleanInput ,TextInput} from 'react-admin';
import CustomDeleteButton from "../../components/Buttons/CustomDeleteButton";
import {Destination as DestinationInput, Status} from "../../components/refrences/input/RelatedInputs";
import {Activity, Destination} from "../../components/refrences/field/RelatedFields";
import BooleanFieldWithNumber from "../../components/Fields/BooleanFieldWithNumber";

const DestinationsToActivitiesFilter = (props) => (
    <Filter {...props}>
        <DestinationInput label="Destination" source="Destination_ID" alwaysOn/>
        <TextInput label="Name" source="Name" alwaysOn/>
       <Status source="Status_Code" label='Status' alwaysOn  allowEmpty={true}/>
        <NullableBooleanInput label="Priced" source="Service.Priced" alwaysOn />
        <NullableBooleanInput label="Needs Update" source="Service.Needs_Update" alwaysOn />
    </Filter>
);

function recordToTitle(record){
    if(record&&record.Name&&record.Destination)
        return 'Remove Activity ('+record.Name+'['+record.Destination.Name+']) from Destination?';
    return '';
}

const DestinationsToActivitiesTable = props=>{
    return (
        <Datagrid {...props}>
            <TextField source="ID" label="ID"/>
            <TextField source="Name"/>
            <Destination source="Destination_ID"  label='Destination'/>
            <Activity source="Activity_Code" label='Activity'/>
            <BooleanFieldWithNumber source="Service.Priced" label="Priced" sortable={false}/>
            <BooleanFieldWithNumber source="Service.Needs_Update" label="Needs Update" sortable={false}/>
            <TextField source="Status_Code" label='Status'/>
            <EditButton/>
            <CustomDeleteButton recordToTitle={recordToTitle}/>
        </Datagrid>
    )
}

export default props => (
    <List {...props} filters={<DestinationsToActivitiesFilter/>} exporter={false}
          sort={{ field: 'ID', order: 'ASC' }}
          filterDefaultValues={{Status_Code: 'Published'}}
          title='Destination Activities'
    >
        <DestinationsToActivitiesTable/>
    </List>
)








