import React from 'react'
import {
    Edit, TextInput,  DateTimeInput, SimpleForm
} from 'react-admin'
import Validator from '../../validators/amenityValidator'
import EditTitle from "../../components/forms/EditTitle";

export default props => {
    const validator = new Validator().g_up_var_f_ui(props.id)
    return <Edit title={<EditTitle EntityName='Amenity' id={props.id} resource='amenities'/>} undoable={false} {...props} >
        <SimpleForm  validate ={validator}>
                <DateTimeInput disabled label='Created' source="Created_Time"/>
                <DateTimeInput disabled label='Modified' source="Modified_Time"/>
                <TextInput source="Name" required/>
                <TextInput source="Code" required/>
                <TextInput source="Sequence" />
        </SimpleForm>

    </Edit>
}
