import React from 'react'
import {
    SimpleForm, TextInput, Create,DateInput
} from 'react-admin'

import Validator from '../../validators/accommodationsToSpecialsValidator'
import SpecialRelatedChangeInput from './SpecialSelectInput'
import {Accommodation} from '../../components/refrences/input/RelatedInputs'



export default props => {
    const validator = new Validator().g_cr_var_fui()
    return (
        <Create title='Create Accommodation Special' {...props} >
            <SimpleForm  validate ={validator} redirect='list'>
                <Accommodation source='Accommodation_ID'/>
                <SpecialRelatedChangeInput />
                <TextInput source='Name'/>
                <DateInput source='Start_Date' label='Start Date'/>
                <DateInput source='End_Date' label='End Date'/>
                <TextInput fullWidth multiline source='Description'/>
                <TextInput fullWidth multiline source='Terms_Conditions' label='Terms & Conditions'/>
            </SimpleForm>
        </Create>
    )
};
