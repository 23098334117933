import React from 'react'
import {
    SimpleForm, TextInput, Create,NumberInput
} from 'react-admin'

import Validator from '../../validators/accommodationTierValidator'

export default props => {
    const validator = new Validator().g_cr_var_fui()
    return (
        <Create title='Create Accommodation Tier' {...props} >
            <SimpleForm  validate ={validator} redirect='list'>
                <TextInput source="Name" required/>
                <TextInput source="Code" required/>
                <NumberInput source="Sequence" />
            </SimpleForm>
        </Create>
    )
};
