import React, {Fragment, useEffect, useState} from "react";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {
    SimpleForm,
    useNotify,
    CREATE,
    GET_ONE,
    UPDATE,
    useRefresh,
    DateInput,
    NumberInput,
    BooleanInput
} from 'react-admin'
import dataProvider from "../../rest-data-provider";
import PropTypes from "prop-types";
import Validator from "../../validators/rateValidator";
import {RackRateSubsValues} from "./RackRateSubsValues";
import {Currency, RateStatus, Status} from "../../components/refrences/input/RelatedInputs";
import SaveCancelButtons from "../../components/Buttons/SaveCancelButtons";
import AddButton from "../../components/Buttons/AddButton";
import ConversionRateSubsCurrency from "./ConversionRateSubsCurrency";
import EditButton from "../../components/Buttons/EditButton";
import {notifyError} from "../../ec-admin-adapters/notifyF";
import {GetForm} from "../../components/forms/GetForm";
import DialogActions from "@material-ui/core/DialogActions";
import SaveButton from "../../components/Buttons/SaveButton";
import CancelButton from "../../components/Buttons/CancelButton";


const RateAddOrEditButton = props => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [show, setShow] = useState(false);
    const {vendorID, serviceID, accommodationID, record} = props;
    const [rate, setRate] = useState(undefined);
    const [vendorCurrency,setVendorCurrency] = useState('USD');
    const [form,setForm] = useState(undefined);

    useEffect(() => {
        if (vendorID) {
            dataProvider(GET_ONE, 'vendors', {id: vendorID}).then(result => {
                const currencyCode = result.data.Default_Currency_Code;
                // handleCurrencyChange(currencyCode)
                setVendorCurrency(currencyCode);
            })
        }
    }, [vendorID])
    // get related currency for the related Vendor.

    useEffect(() => {
        if (record && record.id) {
            dataProvider(GET_ONE, 'rates', {id: record.id}).then((result) => {
                setRate(result.data);
            }).catch(error => {
                notifyError(notify,error);
            })
        }
    }, [record,notify])

    const handleOpenClick = () => {
        setShow(true);
    };

    const handleCloseClick = () => {
        setShow(false);
    };

    const handleSubmit = (values) => {
        if (record && record.id) {
            dataProvider(UPDATE, 'rates', {data: values, id: record.id}).then(() => {
                refresh();
            }).catch(error => {
                handleCloseClick();
                notifyError(notify,error);
            })
        } else {
            const data = {Service_ID: serviceID, Accommodation_ID: accommodationID, ...values};
            dataProvider(CREATE, 'rates', {data: data}).then(() => {
                refresh();
            }).catch(error => {
                handleCloseClick();
                notifyError(notify,error);
            })
        }
    }


    // have to initValue through record , or the SimpleForm cannot get the right values of form while validate.
    const validator = (record&&record.id&&new Validator().g_up_var_f_ui())||new Validator().g_cr_var_fui();
    return (
        <Fragment>
            {record && record.id ? <EditButton clickEvent={handleOpenClick}/> :
                <AddButton clickEvent={handleOpenClick}/>}
            <Dialog
                disableEnforceFocus
                fullWidth
                open={show}
                onClose={handleCloseClick}
                aria-label="Add Rate"
            >
                <DialogTitle>Add Rate</DialogTitle>
                <DialogContent>
                    <SimpleForm save={handleSubmit}
                                 validate ={validator}
                                record={rate || {Currency_Code: vendorCurrency, Status_Code: 'Published',Rate_Status_Code:'Confirmed'}}
                                toolbar={null}
                    >
                        <DateInput source='Start_Date' label='Start Date'/>
                        <DateInput source='End_Date' label='End Date'/>
                        <Currency/>
                        <ConversionRateSubsCurrency/>
                        <NumberInput source="Base_Net" label='Base Net' />
                        <NumberInput source="Base_Rack" label='Base Rack' />
                        <RackRateSubsValues/>
                        <BooleanInput source='Is_Gratuity_Included' label='Is Gratuity Included'/>
                        <Status source="Status_Code" label='Status'/>
                        <RateStatus  source="Rate_Status_Code" label='Rate Status'/>
                        <GetForm setForm={setForm}/>
                    </SimpleForm>
                </DialogContent>
                <DialogActions>
                    <SaveButton clickEvent={()=>{
                        form.submit();
                    }}/>
                    <CancelButton clickEvent={handleCloseClick}/>
                </DialogActions>
            </Dialog>
        </Fragment>
    );

};

// required when create.
RateAddOrEditButton.propTypes = {
    serviceID: PropTypes.number,
    accommodationID: PropTypes.number,
    vendorID: PropTypes.number,
}

export default RateAddOrEditButton;
