import React from 'react'
import {
    Edit, TextInput, DateTimeInput, SimpleForm,NumberInput,PasswordInput
} from 'react-admin'
import Validator from '../../validators/emailConfValidator'
import SaveButtonToolbar from "../../components/forms/SaveButtonToolbar";

export default props => {
    const validator = new Validator().g_up_var_f_ui(props.id)
    return <Edit title='Editing Email Configuration' undoable={false} {...props} >
        <SimpleForm validate={validator} toolbar={<SaveButtonToolbar/>} >
            <DateTimeInput disabled label='Created' source="Created_Time"/>
            <DateTimeInput disabled label='Modified' source="Modified_Time"/>
            <TextInput source="Host" required/>
            <NumberInput source="Port" required/>
            <TextInput source="Sender" required/>
            <PasswordInput source="Auth_Code" required/>
        </SimpleForm>

    </Edit>
}
