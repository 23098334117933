import axios from "axios";
import {basePath} from "../../rest-data-provider/paths";

export default {
    login: (values) => {
        return axios.post(basePath + "/users/login", values).then(((res) => {
                const {Email} = values;
                localStorage.setItem('Email', Email);
                localStorage.setItem('Role', res.data.Role);
                localStorage.setItem('Agency', res.data.Agency);
                localStorage.setItem('UserID', res.data.ID);
                return Promise.resolve();
            }
        ))
    },
    checkAuth: () => {
        return localStorage.getItem('Email') ? Promise.resolve()
            : Promise.reject();
    },
    getPermissions: () => {
        const role = localStorage.getItem('Role');
        return role ? Promise.resolve(role) : Promise.reject();
    },
    getAgency: () => {
        const agency = localStorage.getItem('Agency');
        return agency;
    },
    getUserID: () => {
        const id = localStorage.getItem('UserID');
        return id;
    },
    getRole: () => {
        const role = localStorage.getItem('Role');
        return role;
    },
    checkError: () => {
        return Promise.resolve();
    },
    logout: () => {
        axios.delete(basePath + "/users/loginOut");
        localStorage.removeItem('Email');
        localStorage.removeItem('Role');
        localStorage.removeItem('Agency');
        return Promise.resolve();
    },
}




