import axios from "axios";
import {basePath} from "../paths";

export const findMasterDestinationAccommodations=async (destinationID,tierCode)=>{
    let tierCodeParam ="";
    if(tierCode){
        tierCodeParam = "&tierCode="+tierCode;
    }
    if(destinationID)
        return axios.get(basePath + "/accommodations/findMasterDestinationAccommodations?destinationID="+destinationID+tierCodeParam).then(((services) => {
                return services.data.map(accommodation=>{
                    return {id:accommodation.ID,value:accommodation.Name};
                })
            }
        ))
    return [];
}
