import React from 'react'
import {
    SimpleForm, TextInput, Create, PasswordInput, BooleanInput, DateTimeInput, NumberInput
} from 'react-admin'

import Validator from '../../validators/increaseRateValidator'

export default props => {
    const validator = new Validator().g_cr_var_fui()
    return (
        <Create {...props} >
            <SimpleForm  validate ={validator}>
                <TextInput source="Name" required/>
                <NumberInput source="Year" required/>
                <NumberInput source="Rate" required label={"Increase Rate (Percent)"}/>
            </SimpleForm>
        </Create>
    )
};
