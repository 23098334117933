import dataProvider from "../index";
import {GET_LIST,GET_ONE} from 'react-admin';
import axios from "axios";
import {basePath} from "../paths";
import {notifyError} from "../../ec-admin-adapters/notifyF";

export const dmcVendorFetch= (value)=>{
    return commonVendorFetch({Name: value,Is_DMC:true});
}

export const masterVendorFetch= (value)=>{
    return commonVendorFetch({Name: value,Is_Master_Vendor:true});
}

function commonVendorFetch(filter){
    return  dataProvider(GET_LIST, 'vendors', {
        filter: filter,
        pagination: {page: 1, perPage: 25},
        sort: {field: "id", order: "ASC"}
    }).then(
        (result) => {
            return  result.data.map(record => {
                return {id: record.id + '', value: record.Name}
            })
        }
    )
}

export async function tripVendorFetch(tripID,name){
    if(tripID)
        return  axios.get(basePath + `/vendors/getTripVendors/${tripID}?name=${name?name:""}` ).then((response) => {
            return  response.data.map(record => {
                return {id: record.id + '', value: record.name}
            })
        })
    return [];
}


export function commonVendorFetchOne(id){
    return  dataProvider(GET_ONE, 'vendors', {
        id: id,
    }).then(
        (result) => {
            if(result&&result.data&&result.data){
                return {id:result.data.id,value:result.data.Name};
            }
        }
    )
}
