import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    destination: {
        width:'320px'
    },
    destinationL:{
        width:'480px'
    }
});


export default useStyles;
