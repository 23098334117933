import React from "react";
import {Save} from "@material-ui/icons";
import {Button} from "@material-ui/core";
import PropTypes from "prop-types";

const SaveButton  = ({clickEvent,label,disabled}) =>{
    return  (
        <Button
            disabled={disabled}
            label="accommodation.action.cancel"
            onClick={clickEvent}
            color="primary"
        >
            <Save/>{label||'Save'}
        </Button>
    )
}




SaveButton.propTypes = {
    clickEvent: PropTypes.func.isRequired,
    label: PropTypes.string
}

export default SaveButton;
