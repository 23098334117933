import React from 'react'
import {
    List, downloadCSV, Datagrid, TextField, EditButton, DeleteButton, Filter,TextInput
} from 'react-admin'
import {unparse as convertToCSV} from 'papaparse/papaparse.min'
import idReducer from '../../ec-admin-adapters/idReducer'
import PriceButton from "./PriceButton";
import TripRequestButton from "./TripRequestButton";
import DaysButton from "./DaysButton";
import {Country, Region} from "../../components/refrences/input/RelatedInputs";
import MapButton from "./MapButton";

const TripTemplateFilter = (props) => (
    <Filter {...props}>
        <TextInput source="Name" alwaysOn />
        <Country source="Country_Code" label='Country' alwaysOn/>
        <Region source="Region_Code" label='Region' alwaysOn/>
    </Filter>
);

const exporter = tripTemplates => {
    tripTemplates = idReducer(tripTemplates)
    const csv = convertToCSV({
        data: tripTemplates,
        // fields: ['Final_Transfer_Origin', 'Name', 'Final_Transfer_Destination'] // order fields in the export
    });
    downloadCSV(csv, 'tripTemplates  ' + new Date()); // download as 'tripTemplates.csv` file
}

const TheTripRequestButton = props=>{
    return <TripRequestButton tripTemplateID={props.record.ID} tripTemplateName={props.record.Name}/>
}

const TripTemplateTable = props => {
    return (
        <Datagrid {...props}>
            <TextField source="Name"/>
            <TextField source="Sequence"/>
            <TextField source='Days' label='Number of Nights'/>
            <TextField source='Price_From' label='Price Per Person'/>
            <TextField source='UI_Content.Subtitle' label='Subtitle'/>
            <MapButton/>
            <DaysButton/>
            <PriceButton/>
            <TheTripRequestButton/>
            <EditButton/>
            <DeleteButton undoable={false}/>
        </Datagrid>
    )
}

export default props => {

    return (
        <List title='Trip Template' {...props} filters={<TripTemplateFilter/>} exporter={exporter}
              sort={{ field: 'Sequence', order: 'ASC' }}
        >
            <TripTemplateTable/>
        </List>
    )
}







