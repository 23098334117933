import React from 'react';
import {
    List, Datagrid, TextField, EditButton, Filter,NullableBooleanInput,TextInput
} from 'react-admin';
import CustomDeleteButton from "../../components/Buttons/CustomDeleteButton";
import {Accommodation as AccommodationInput, Status} from "../../components/refrences/input/RelatedInputs";
import {Accommodation, Activity} from "../../components/refrences/field/RelatedFields";
import BooleanFieldWithNumber from "../../components/Fields/BooleanFieldWithNumber";

const AccommodationsToActivitiesFilter = (props) => (
    <Filter {...props}>
        <AccommodationInput label="Accommodation" source="Accommodation_ID" alwaysOn/>
        <TextInput source='Name' label='Name' alwaysOn/>
       <Status source="Status_Code" label='Status' alwaysOn  allowEmpty={true}/>
        <NullableBooleanInput label="Priced" source="Service.Priced" alwaysOn />
        <NullableBooleanInput label="Needs Update" source="Service.Needs_Update" alwaysOn />
    </Filter>
);

function recordToTitle(record){
    if(record&&record.Name&&record.Accommodation)
        return 'Remove Activity ('+record.Name+'['+record.Accommodation.Name+']) from Accommodation?';
    return '';
}

const AccommodationsToActivitiesTable = props=>{
    return (
        <Datagrid {...props}>
            <TextField source="ID" label="ID"/>
            <TextField source="Name"/>
            <Accommodation source="Accommodation_ID" label='Accommodation'/>
            <Activity source="Activity_Code" label='Activity'/>
            <BooleanFieldWithNumber source="Service.Priced" label="Priced" sortable={false}/>
            <BooleanFieldWithNumber source="Service.Needs_Update" label="Needs Update" sortable={false}/>
            <TextField source="Status_Code" label='Status'/>
            <EditButton/>
            <CustomDeleteButton recordToTitle={recordToTitle}/>
        </Datagrid>
    )
}

export default props => (
    <List {...props} filters={<AccommodationsToActivitiesFilter/>} exporter={false}
          sort={{ field: 'ID', order: 'ASC' }}
          filterDefaultValues={{Status_Code: 'Published'}}
          title='Accommodation Activities'
    >
        <AccommodationsToActivitiesTable/>
    </List>
)








