export default {
    primaryKey:'ID',
    commonValidate: {
        Name: ['Required',{isLength:{max: 120}}],
        Year: ['Required',{isInt:{min: 1900, max: 9999}}],
        Rate: ['Required',{isFloat:{min: -1, max: 1000}}],
    },
    createValidate:{
    }
}



