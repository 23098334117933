import React from 'react'
import {
    List, Datagrid, TextField, EditButton, DeleteButton,
    TextInput,
    Filter,
} from 'react-admin'
// import {unparse as convertToCSV} from 'papaparse/papaparse.min'
// import idReducer from '../../ec-admin-adapters/idReducer'


const AirportFilter = (props) => (
    <Filter {...props}>
        <TextInput source="Name" alwaysOn />
        <TextInput source="IATA_Code" label='IATA Code' alwaysOn />
    </Filter>
);

// const exporter = airports => {
//     airports = idReducer(airports)
//     const csv = convertToCSV({
//         data: airports,
//         // fields: ['Final_Transfer_Origin', 'Name', 'Final_Transfer_Destination'] // order fields in the export
//     });
//     downloadCSV(csv, 'airports  ' + new Date()); // download as 'airports.csv` file
// }

const AirportTable = props => {
    return (
        <Datagrid {...props} >
            <TextField source="ID" label="ID"/>
            <TextField source="Name"/>
            <TextField source="IATA_Code" label='IATA Code'  />
            <EditButton/>
            <DeleteButton undoable={false}/>
        </Datagrid>
    )
}

export default props => {
    return (
        <List {...props} filters={<AirportFilter/>} exporter={false}
              sort={{ field: 'ID', order: 'ASC' }}
        >
            <AirportTable/>
        </List>
    )
}







