import React, {Fragment, useState} from "react";
import {Button, DialogActions, DialogContent, DialogTitle, Dialog, Link} from "@material-ui/core";
import {AttachMoneySharp, Cancel, Save} from "@material-ui/icons";
import {useNotify, useRefresh} from 'react-admin'
import axios from "axios";
import {basePath} from "../../rest-data-provider/paths";
import parse from 'html-react-parser';
import {notifyError} from "../../ec-admin-adapters/notifyF";

const PricePerPersonButton = props => {
    const [show, setShow] = useState(false);
    const [price, setPrice] = useState(undefined);
    const notify = useNotify();
    const refresh = useRefresh();
    const {record} = props;
    const handleOpenClick = () => {
        axios.get(basePath + '/trips/price/' + record.ID).then((response) => {
            setShow(true);
            setPrice(response.data);
        }).catch(error => {
            notifyError(notify,error);
        })
    };

    const handleSaveClick = () => {
        axios.put(basePath + '/trips/price/' + record.ID, {Price: price.total}).then((response) => {
            setShow(false);
            setPrice(undefined);
            refresh();
        }).catch(error => {
            notifyError(notify,error);
        })
    };



    return (
        <Fragment>
            <Button color="primary" onClick={handleOpenClick} label="ra.action.create">
                <AttachMoneySharp/>Price Per Person
            </Button>
            <Dialog
                disableEnforceFocus
                fullWidth
                maxWidth={'xl'}
                open={show}
                onClose={()=>setShow(false)}
            >
                <DialogTitle>Price Per Person - {record.Name}</DialogTitle>
                <DialogContent>
                    {price ?
                        <div>
                            {parse(price.html)}
                        </div>
                        : null
                    }

                </DialogContent>
                <DialogActions>
                    <Button color="primary"
                            onClick={handleSaveClick}
                    >
                        <Save/>update
                    </Button>
                    <Button color="primary" onClick={()=>{
                        const previewPath = `${basePath}/trips/priceCsv/${record.ID}`;
                        window.open(previewPath);
                    }}>
                        spreadsheet
                    </Button>
                    <Button
                        onClick={()=>setShow(false)}
                    >
                        <Cancel/>Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default PricePerPersonButton;
