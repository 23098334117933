import dataProvider from "../index";
import {GET_ONE} from 'react-admin';

function getOneTemplateStep(id){
    return  dataProvider(GET_ONE, 'tripTemplateSteps', {
        id
    }).then(
        (result) => {
            return  result.data;
        }
    )
}

export {getOneTemplateStep};
