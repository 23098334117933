import {Vendor} from "../../components/refrences/input/NoneStoreRelatedInputs";
import React, {useEffect} from "react";
import dataProvider from "../../rest-data-provider";
import {GET_ONE,useNotify} from 'react-admin';
import {useForm, useFormState} from "react-final-form";
import {notifyError} from "../../ec-admin-adapters/notifyF";

export const  VendorSubsAccommodation= props => {
    const formState = useFormState();
    const form = useForm();
    const notify = useNotify();
    const Accommodation_ID = formState.values['Accommodation_ID'];
    useEffect(() => {
        if (Accommodation_ID) {
            dataProvider(GET_ONE, 'accommodations', {
                id: Accommodation_ID
            }).then(accommodationResponse => {
                    const vendorID = accommodationResponse.data.Vendor_ID;
                    form.change('Service.Vendor_ID', vendorID);
            }).catch(error=>{
                notifyError(notify,error);
            })
        }else{
            form.change('Service.Vendor_ID', null);
        }
    }, [Accommodation_ID]);
    return <Vendor {...props} disabled helperText='No Vendor for the selected Accommodation'/>;
}
