import React, {Fragment} from 'react'
import {
    List, downloadCSV, Datagrid, TextField, EditButton, DeleteButton,
    Filter
} from 'react-admin'
import {unparse as convertToCSV} from 'papaparse/papaparse.min'
import idReducer from '../../ec-admin-adapters/idReducer'


const AgencyFilter = (props) => (
    <Filter {...props}>
    </Filter>
);

const exporter = agencies => {
    agencies = idReducer(agencies)
    const csv = convertToCSV({
        data: agencies,
        // fields: ['Final_Transfer_Origin', 'Name', 'Final_Transfer_Destination'] // order fields in the export
    });
    downloadCSV(csv, 'agencies  ' + new Date()); // download as 'users.csv` file
}

const AgencyTable = props => {
    return (
        <Datagrid {...props} >
            <TextField source="Name" />
            <TextField source="Street_Address"/>
            <TextField source="City"/>
            <TextField source="State"/>
            <TextField source="Zip"/>
            <TextField source="Phone"/>
            <TextField source="Email"/>
            <EditButton/>
        </Datagrid>
    )
}

export default props => {

    return (
        <List {...props} filters={<AgencyFilter/>} exporter={exporter}
              sort={{ field: 'Name', order: 'ASC' }}
              bulkActionButtons={<Fragment/>}
        >
            <AgencyTable/>
        </List>
    )
}







