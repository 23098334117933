import React, {Component} from 'react'
import {Button} from '@material-ui/core'
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {changePasswordValidator} from '../../validators/userValidator'
import axios from 'axios';
import {basePath} from "../../rest-data-provider/paths";
import {ErrorSlideTransition, SuccessSlideTransition} from '../../components/customNotification'
import FormField from '../../components/Fields/FormField'
import loginOut from "./loginOut";

const theme = createMuiTheme();


class ChangePasswordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    submit = (e) => {
        e.preventDefault();
        const {history} = this.props;
        const Email = localStorage.getItem('Email');
        const {Password, NewPassword, ConfirmPassword} = this.state;
        const newPasswordJson = {Email, Password, NewPassword, ConfirmPassword};
        const error = changePasswordValidator(newPasswordJson);
        if (error) {
            this.setState({msg: error, error: true})
            return;
        }

        axios.put(basePath + "/users/changePassword", newPasswordJson).then((() => {
                this.setState({msg: 'success change password!', error: false});
                loginOut(history);
            }
        )).catch(error => {
            this.setState({msg: error.response.data, error: true})
        })

    }


    render() {
        const {msg, error} = this.state;
        return (
            <MuiThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline/>
                    <div className={theme.paper}>
                        <Typography component="h1" variant="h5">
                            Change password
                        </Typography>
                        <form onSubmit={this.submit} noValidate>
                            <FormField
                                name="Password"
                                type="password"
                                onChange={(e) => {
                                    this.setState({Password: e.target.value, msg: undefined, error: false})
                                }}
                            />
                            <FormField
                                name="NewPassword"
                                type="password"
                                onChange={(e) => {
                                    this.setState({NewPassword: e.target.value, msg: undefined, error: false})
                                }}
                            />
                            <FormField
                                name="ConfirmPassword"
                                type="password"
                                onChange={(e) => {
                                    this.setState({ConfirmPassword: e.target.value, msg: undefined, error: false})
                                }}
                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className=""
                            >
                                Update password
                            </Button>
                        </form>
                    </div>
                    <Snackbar
                        open={msg !== undefined}
                        onClose={() => {
                            this.setState({msg: undefined, error: false})
                        }}
                        autoHideDuration={2000}
                        TransitionComponent={(error === true ? ErrorSlideTransition : SuccessSlideTransition)}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{msg}</span>}
                    />
                </Container>
            </MuiThemeProvider>
        );
    }
}

export default ChangePasswordPage;
