import React from 'react'
import {
    Edit, TextInput, SimpleForm
} from 'react-admin'
import Validator from '../../validators/agencyValidator';
import EditTitle from "../../components/forms/EditTitle";
import SaveButtonToolbar from "../../components/forms/SaveButtonToolbar";

export default props => {
    const validator = new Validator().g_up_var_f_ui(props.id)
    return <Edit title={<EditTitle EntityName='Agency' id={props.id} resource='agencies'/>} undoable={false} {...props} >
        <SimpleForm validate={validator} toolbar={<SaveButtonToolbar/>} >
            <TextInput source="Name" required/>
            <TextInput source="Street_Address" required/>
            <TextInput source="City" required/>
            <TextInput source="State" required/>
            <TextInput source="Zip" required/>
            <TextInput source="Phone" required/>
            <TextInput source="Email" required/>
        </SimpleForm>

    </Edit>
}
