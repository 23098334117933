import React, {Fragment} from 'react'
import {
    List, downloadCSV, Datagrid, TextField, EditButton
} from 'react-admin'
import {unparse as convertToCSV} from 'papaparse/papaparse.min'
import idReducer from '../../ec-admin-adapters/idReducer'
import TestConnectionButton from "./TestConnectionButton";



const exporter = users => {
    users = idReducer(users)
    const csv = convertToCSV({
        data: users,
        // fields: ['Final_Transfer_Origin', 'Name', 'Final_Transfer_Destination'] // order fields in the export
    });
    downloadCSV(csv, 'email_configuration  ' + new Date()); // download as 'users.csv` file
}

const EmailConfTable = props => {
    return (
        <Datagrid {...props} >
            <TextField source="Host"/>
            <TextField source="Port"/>
            <TextField source="Sender"/>
            <TestConnectionButton />
            <EditButton/>
        </Datagrid>
    )
}

export default props => {

    return (
        <List {...props} exporter={exporter}
              bulkActionButtons={<Fragment/>}
              sort={{field: 'Code', order: 'ASC'}}
        >
            <EmailConfTable/>
        </List>
    )
}







