export default {
    primaryKey:'ID',
    commonValidate: {
        Name: ['Required',{isLength:{max: 100}}],
        Code: ['Required',{isLength:{min:6,max: 6}}],
        Email_Address: ['Required','isEmail'],
        Primary_Phone: ['Required',{isLength:{min:1,max: 50}}],
        After_Hours_Phone: [{isLength:{max: 50}}],
        Secondary_Phone: [{isLength:{max: 50}}],
        Second_After_Hours_Phone: [{isLength:{max: 50}}],
        Mail_Address_1: [{isLength:{max: 100}}],
        Mail_Address_2: [{isLength:{max: 100}}],
        Mail_Address_3: [{isLength:{max: 100}}],
        Mail_Address_4: [{isLength:{max: 100}}],
        Mail_Address_5: [{isLength:{max: 100}}],
        Mail_Post_Code: [{isLength:{max: 20}}],
        Chain_Code: [{isLength:{max: 20}}],
        Default_Currency_Code: [{isLength:{max: 15}}],
        Default_Language_Code: [{isLength:{max: 15}}],
        Remarks:[{isLength:{max: 250}}],
        DMC_ID: [{isInt:{min: 1}}],
    }
}



