import React from 'react'
import {
    TextInput, NumberInput,
    Create, SimpleForm,
} from 'react-admin'

import Validator from '../../validators/currencyValidator'

export default props => {
    const validator = new Validator().g_cr_var_fui()
    return (
        <Create {...props} >
            <SimpleForm  validate ={validator} redirect='list'>
                <TextInput source="Code" required/>
                <TextInput source="Name" required/>
                <NumberInput source="Sequence"/>
                <NumberInput source="Conversion_Rate"/>
            </SimpleForm>
        </Create>
    )
};
