import React from 'react'
import {
    SimpleForm, TextInput,BooleanInput,
    Create,
} from 'react-admin'

import Validator from '../../validators/animalValidator'

export default props => {
    const validator = new Validator().g_cr_var_fui()
    return (
        <Create {...props} >
            <SimpleForm  validate ={validator} redirect='list'>
                <TextInput source="Name" required/>
                <TextInput source="Code" required/>
                <BooleanInput source="Is_Big_Five" label='Is Big Five'/>
                <BooleanInput source="Is_Bird_Six" label='Is Big Six'/>
                <TextInput source="Sequence" />
            </SimpleForm>
        </Create>
    )
};
