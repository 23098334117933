import React from 'react'
import {
    Edit, TextInput, DateTimeInput
    , SimpleForm,NumberInput
} from 'react-admin'
import Validator from '../../validators/currencyValidator'
import EditTitle from "../../components/forms/EditTitle";

const Context = (props)=>{
    return <h2 style={{marginBottom:'30px',fontWeight:'lighter'}}>Changing the conversion rate will only affect current and future Accommodation and Transfer Rate calculations</h2>
}

export default props => {
    const validator = new Validator().g_up_var_f_ui(props.id)
    return <Edit  title={<EditTitle EntityName='Currency' id={props.id} resource='currencies'/>} undoable={false} {...props} >

        <SimpleForm  validate ={validator}>
            <Context/>
            <DateTimeInput disabled label='Created' source="Created_Time"/>
            <DateTimeInput disabled label='Modified' source="Modified_Time"/>
            <TextInput source="Code" required/>
            <TextInput source="Name" required/>
            <NumberInput source="Sequence"/>
            <NumberInput source="Conversion_Rate"/>
        </SimpleForm>
    </Edit>
}
