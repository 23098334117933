import dataProvider from "./rest-data-provider"
import React from 'react'
import {Admin, Resource} from 'react-admin'

import vendors from './routes/vendors'
import users from './routes/users'
import agencies from './routes/agencies'
import pricingFactors from './routes/pricingFactors'
import emailConf from './routes/emailConf'
import pdfSettings from './routes/pdfSettings'
import increaseRate from './routes/increaseRate'

import accommodations from './routes/accommodations'
import accommodationRoomTypes from './routes/accommodationRoomTypes'
import accommodationsToActivities from './routes/accommodationsToActivities'
import accommodationsToSpecials from './routes/accommodationsToSpecials'

import destinations from './routes/destinations'
import regions from './routes/regions'
import airports from './routes/airports'
import countries from './routes/countries'
import destinationsToActivities from './routes/destinationsToActivities'

import activities from './routes/activities'
import animals from './routes/animals'
import lodgingTypes from './routes/lodgingTypes'
import accommodationTiers from './routes/accommodationTiers'

import routes from './routes/routes'
import transfers from './routes/transfers'
import transferModes from './routes/transferModes'

import serviceTypes from './routes/serviceTypes'
import rates from './routes/rates'
import ecosystems from './routes/ecosystems'
import affiliates from './routes/affiliates'
import amenities from './routes/amenities'
import currencies from './routes/currencies'

import {Home} from '@material-ui/icons'
import AuthProvider from './routes/auth/AuthProvider'
import Dashboard from './routes/Dashboard'
import MyLoginPage from "./routes/auth/login/MyLoginPage";
import MyLogoutButton from './routes/auth/login/MyLogoutButton';
import customReducer from "./reducers"
import BushLayout from './BushLayout'
import customRoutes from './routes/customRoutes'

import tripTemplates from './routes/tripTemplates'
import tripTemplateSteps from './routes/tripTemplateSteps'
import trips from './routes/trips'
import tripSteps from './routes/tripSteps'

import reservationsToPackages from './routes/reservationsToPackages'
import {JssProvider, SheetsRegistry} from 'react-jss';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

const messages = {en: {myroot: 'Hello, %{name}!', ...englishMessages}};
const i18nProvider = polyglotI18nProvider(locale => messages[locale],
    'en',
    {
        allowMissing: true
    }
);

const sheets = new SheetsRegistry()
//JssProvider to disable duplicate css version caused by different  dependency version from react-admin for material-ui.
const App = () => (
    <JssProvider registry={sheets}>
        <Admin layout={BushLayout} title="BUSHBPS" loginPage={MyLoginPage}
               logoutButton={MyLogoutButton} authProvider={AuthProvider}
               dashboard={Dashboard} dataProvider={dataProvider}
               customReducers={customReducer}
               customRoutes={customRoutes}
               i18nProvider={i18nProvider}

        >
            {permissions => [
                // Restrict access to the edit and remove views to admin only
                <Resource name="vendors" {...vendors}/>,

                <Resource name="accommodations" icon={Home} {...accommodations}/>,
                <Resource name="accommodationRoomTypes" {...accommodationRoomTypes} options={{label: 'Room Types'}}/>,
                <Resource name="accommodationsToActivities" {...accommodationsToActivities}
                          options={{label: 'Activities'}}/>,
                <Resource name="accommodationsToSpecials" {...accommodationsToSpecials} options={{label: 'Specials'}}/>,

                <Resource name="destinations" {...destinations}/>,
                <Resource name="regions" {...regions}/>,
                <Resource name="airports" {...airports}/>,
                <Resource name="countries" {...countries}/>,
                <Resource name="destinationsToActivities" {...destinationsToActivities}
                          options={{label: 'Activities'}}/>,

                <Resource name="activities" {...activities}/>,
                <Resource name="animals" {...animals}/>,
                <Resource name="lodgingTypes" {...lodgingTypes} options={{label: 'Lodging Types'}}/>,
                <Resource name="specials" />,
                <Resource name="accommodationTiers"  {...accommodationTiers} options={{label: 'Accommodation Tiers'}}/>,

                <Resource name="routes" {...routes}/>,
                <Resource name="transfers" {...transfers}/>,
                <Resource name="transferModes" {...transferModes} options={{label: 'Transfer Modes'}}/>,

                <Resource name="tripTemplates" {...tripTemplates} options={{label: 'Trip Templates'}}/>,
                <Resource name="tripTemplateSteps" {...tripTemplateSteps}/>,
                <Resource name="tripTemplateAccommodations"/>,
                <Resource name="trips" {...trips}/>,

                <Resource name="tripSteps" {...tripSteps}/>,
                <Resource name="guests"/>,
                <Resource name="reservationsToPackages" {...reservationsToPackages} options={{label: 'Packages'}}/>,


                <Resource name="currencies" {...currencies}/>,
                <Resource name="serviceTypes" {...serviceTypes} options={{label: 'Service Types'}}/>,
                <Resource name="rates" {...rates}/>,
                <Resource name="ecosystems" {...ecosystems}/>,
                <Resource name="affiliates" {...affiliates}/>,
                <Resource name="amenities" {...amenities}/>,

                <Resource name="destinationsToAirports"/>,
                <Resource name="accommodationsToAnimals"/>,
                <Resource name="accommodationsToAmenities"/>,
                <Resource name="accommodationsToLodgingTypes"/>,
                <Resource name="routesToTransfers"/>,
                <Resource name="languages"/>,
                <Resource name="services"/>,
                <Resource name="mealBases"/>,
                <Resource name="drinksBases"/>,
                <Resource name="roomConfigurations"/>,
                <Resource name="status"/>,
                <Resource name="rateStatus"/>,
                <Resource name="reservationMilestoneTypes"/>,
                <Resource name="packagesToMilestones"/>,
                <Resource name="tripDays"/>,
                <Resource name="tripTypes"/>,
                <Resource name="doc_template" options={{label: 'Doc Templates'}}/>,
                <Resource name="integration" options={{label: 'Integration'}}/>,
                <Resource name="agencies" {...agencies} />,
                // Only include the categories resource for admin users
                permissions.toLowerCase() === 'admin'
                    ? <Resource name="users" {...users} />
                    : null,
                permissions.toLowerCase() === 'admin'
                    ? <Resource name="pricingFactors" {...pricingFactors} options={{label: 'Pricing Factors'}}/>
                    : null,
                permissions.toLowerCase() === 'admin'
                    ? <Resource name="emailConf" {...emailConf} options={{label: 'Email Configuration'}}/>
                    : null,
                permissions.toLowerCase() === 'admin'
                    ? <Resource name="pdfSettings" {...pdfSettings} options={{label: 'PDF Settings'}}/>
                    : null,
                permissions.toLowerCase() === 'admin'
                    ? <Resource name="increaseRate" {...increaseRate} options={{label: 'Increase Rate'}}/>
                    : null,

            ]}

        </Admin>
    </JssProvider>
);

export default App;
