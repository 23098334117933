import {basePath} from "./paths";
import responseIDConverter from '../ec-admin-adapters/responseIDConverter'
import {stringify} from 'query-string';
import axios from 'axios';
import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
} from 'react-admin';

axios.defaults.withCredentials = true
const __assign = Object.assign;

class Base {
    primaryKey = "Code";
    constructor() {
        this.callApi = this.callApi.bind(this);
    }

    convertDataRequestToHTTP(type, resource, params) {

        let url = '';
        let method = 'get';
        let data = {};


        switch (type) {
            case GET_LIST: {
                const query = {...params.filter};
                if(params.pagination){
                    const {page,perPage}=params.pagination;
                    query.offset=(page - 1) * perPage;
                    query.limit = perPage;
                }
                if(params.sort){
                    const{field,order} = params.sort;
                    query.sort = field;
                    query.order = order;
                }

                Object.keys(query).forEach(key=>{
                    const value = query[key];
                    if(Object.prototype.toString.call(value)==='[object Object]'){
                        query[key] = JSON.stringify(value)
                    }
                })
                url = basePath + "/" + resource + "/getList?" + stringify(query);
                break;
            }
            case GET_ONE:
                url = basePath + "/" + resource + "/" + params.id;
                break;
            case GET_MANY: {
                const query = {
                    ids: params.ids,
                };
                url = basePath + "/" + resource + "/getMany?" + stringify(query);
                break;
            }
            case UPDATE:
                url = basePath + "/" + resource + "/" + params.id;
                method = 'PUT';
                data = params.data;
                break;
            case CREATE:
                url = basePath + "/" + resource;
                method = 'POST';
                data = params.data;
                break;
            case DELETE:
                url = basePath + "/" + resource + "/" + params.id;
                method = 'DELETE';
                break;
            case DELETE_MANY: {
                const query = {
                    ids: params.ids,
                };
                method = 'DELETE';
                url = basePath + "/" + resource + "?" + stringify(query);
                break;
            }
            case GET_MANY_REFERENCE:{
                const _c = params.sort, field = _c.field, order = _c.order;
                const query = {
                    sort: field,
                    order: order,
                    ...params.filter
                };
                query[params.target]=params.id;
                url = basePath + "/" + resource + "/getReference?" +stringify(query);
                break;
            }
            default:
                throw new Error("Unsupported fetch action type " + type);
        }

        return {url,method,data};
    }

    convertHTTPResponse(response, type, resource, params) {
        const {headers,data} = response;
        responseIDConverter(response,type,this.primaryKey)
        switch (type) {
            case GET_LIST:
            case GET_MANY_REFERENCE:
                if (!headers['x-total-count']) {
                    throw new Error('The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?');
                }
                return {
                    data: data,
                    total: parseInt(headers['x-total-count'])
                };
            case UPDATE:
                return {data: {id: params.data.id}}
            case CREATE:
                return {data: __assign({}, params.data, {id: data.id})};
            case DELETE:
                return {data: {id: params.id}}
            case DELETE_MANY:
                if(data==="OK"){
                    return {data:[]}
                }else{
                    throw new Error("unknown error with server response"+ data)
                }

            default:
                return {data: data};
        }
    }

    callApi(type, resource, params) {
        if (type === UPDATE_MANY) {
            console.error("unsupported operation for now")
            return {data:[]};
        }
        const rest_config = this.convertDataRequestToHTTP(type, resource, params);
        return axios(rest_config)
            .then((response) => {
                return this.convertHTTPResponse(response, type, resource, params);
            }).catch(error=>{
                let msg = (error.response&&error.response.data)||'server error';
                if(Object.prototype.toString.call(msg)==='[object Object]') {
                    msg = JSON.stringify(msg);
                }
                    throw new Error(msg);
            })
    };

}

export default Base;

