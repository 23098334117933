import Rates from "./Rates";
import React,{Fragment} from "react";
import {
    ReferenceManyField
} from 'react-admin'
export default (props)=>{
    if(!props.record.Service||!props.record.Service.ID)
        return (
            <Fragment>Add a Service First, then Add Rates to Service</Fragment>
        )
    return (
        <ReferenceManyField {...props} reference="rates" source='Service.ID' target="Service_ID" addLabel={false} >
            <Rates serviceID={props.record.Service.ID} accommodationID={props.record.Accommodation_ID} vendorID={props.record.Service.Vendor_ID}/>
        </ReferenceManyField>
    )
}
