import React, {useEffect, useState} from "react";
import Downshift from "downshift";
import {TextField, MenuItem, Popper, Paper} from '@material-ui/core';
import {useFormState} from "react-final-form";
import {getSourceValue} from "../../../../utils/renderUtil";
import {emptyText} from "../../../../constants/constant";


const AdaptorDownShift = (props) => {
    const {input, label, fetch, allowEmpty, fetchOne,selectedCallback,disabled,className,fetchTrigger,meta,helperText,required} = props;
    const formState = useFormState();
    const values = formState.values;
    const value = getSourceValue(values,input.name);
    const [items, setItems] = useState([])
    const [initItems, setInitItems] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const emptyItem = {id: null, value: emptyText};
    useEffect(() => {
        setInitItems([{id:null,value:'loading...'}]);
        fetch().then(items => {
                setInitItems(items || []);
        });
    }, []);

    useEffect(() => {
        if(fetchTrigger){
            setInitItems([{id:null,value:'loading...'}]);
            fetch().then(items => {
                setInitItems(items || []);
                let changeFlag = true;
                for(let i =0 ; i<items.length;i++){
                    if(items[i].id===value){
                        changeFlag = false;
                        break;
                    }
                }
                if(changeFlag){
                    setSelectedItem(emptyItem);
                }
            });
        }
    }, [fetchTrigger]);


    const _renderOne = (value) => {
        fetchOne(value).then(item => {
            if (item) {
                setItems([item]);
                setSelectedItem(item);
            }
        })
    }

    useEffect(() => {
        if (!value) {
            setSelectedItem(emptyItem);
        } else {
            _renderOne(value);
        }
    },[value])

    function getSuggestions(selectedItem, inputValue) {
        let suggestions = (allowEmpty && [emptyItem]) || [];
        //no inputValue change.
        if (!inputValue || (selectedItem && inputValue === selectedItem.value)) {
            return suggestions.concat(initItems);
        }
        return suggestions.concat(items);
    }
    return (
        <Downshift
            {...input}
            key={input.name + '-downshift'}
            selectedItem={selectedItem}
            onInputValueChange={inputValue => {
                if(!selectedItem&&inputValue){
                    fetch(inputValue).then(setItems);
                    return ;
                }
                // reduce duplicate request on the same input value.
                if(inputValue&&inputValue!==selectedItem.value){
                    fetch(inputValue).then(setItems);
                }
            }}
            onChange={selection => {
                if(selection){
                    if(selectedItem&&(selection.id===selectedItem.id)){
                        return ;
                    }
                    input.onChange(selection.id);
                    if(typeof selectedCallback==='function'){
                        selectedCallback(selection);
                    }
                }
            }}
            itemToString={item => (item&&item.id ? item.value : '')}
        >
            {({
                  getInputProps,
                  getItemProps,
                  isOpen,
                  inputValue,
                  highlightedIndex,
                  selectedItem,
                  getToggleButtonProps,
              }) => (
                <div style={{flexGrow: 1, position: 'relative'}}>
                    <TextField  required={required} variant='filled' margin='dense' className={className} disabled={disabled} label={label} error={meta.touched && !!meta.error}  helperText={(meta.touched  && meta.error &&  helperText)||( meta.touched && meta.error )} {...getInputProps({
                        onFocus: (event) => {
                            getToggleButtonProps().onClick(event);
                            setAnchorEl(event.currentTarget)
                            input.onFocus(event);
                        },
                        onBlur: input.onBlur,
                        id: input.name
                    })} >

                    </TextField>
                    <Popper open={isOpen} style={{zIndex: 200000}}
                            anchorEl={anchorEl}
                            key={input.name + '-popper'}
                    >
                        <Paper square={true}
                               style={{
                                   marginTop: 8,
                                   minWidth: ((anchorEl && anchorEl.clientWidth) || null),
                                   maxHeight: '50vh',
                                   overflowY: 'auto'
                               }}>

                            {getSuggestions(selectedItem, inputValue)
                                .map((item, index) => (
                                    <MenuItem
                                        {...getItemProps({
                                            key: input.name + '-' + index,
                                            index,
                                            item,
                                            style: {
                                                backgroundColor:
                                                    highlightedIndex === index ? 'rgba(0, 0, 0, 0.14)' : null
                                            },
                                        })}
                                    >
                                        {item.value}
                                    </MenuItem>
                                ))
                            }
                        </Paper>
                    </Popper>
                </div>
            )}
        </Downshift>
    )
}

export default AdaptorDownShift;
