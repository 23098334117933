import baseValidator from './baseValidator'
import meta from '../validateMetas/regionValidateMeta'

class RegionValidator extends baseValidator {
    meta = meta
    uiMeta={
        resources: "regions",
    }

    constructor() {
        super();
        this.g_cr_var_fui = super.g_cr_var_fui.bind(this)
        this.g_up_var_f_ui = super.g_up_var_f_ui.bind(this)
    }
}

export default RegionValidator;




