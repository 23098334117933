import React from 'react'
import {
    Edit, TextInput, NumberInput, DateTimeInput
    , TabbedForm, FormTab, ReferenceManyField, Datagrid, EditButton, TextField
} from 'react-admin'

import Validator from '../../validators/regionValidator'
import UIContentFormTab from "../../components/forms/UIContentFormTab";
import EditTitle from "../../components/forms/EditTitle";
import {Status} from "../../components/refrences/input/RelatedInputs";


export default props => {
    const validator = new Validator().g_up_var_f_ui(props.id)
    return <Edit title={<EditTitle EntityName='Region' id={props.id} resource='regions'/>} undoable={false} {...props} >
        <TabbedForm  validate ={validator}>
            <FormTab label="General">
                <DateTimeInput disabled label='Created' source="Created_Time"/>
                <DateTimeInput disabled label='Modified' source="Modified_Time"/>
                <TextInput source="Name" required/>
                <TextInput source="Code" required/>
                <NumberInput source="Latitude"/>
                <NumberInput source="Longitude"/>
                <Status source="Status_Code" label='Status' defaultValue='Published'/>
                <NumberInput source="Sequence" />
            </FormTab>
            <UIContentFormTab/>
            <FormTab label="Countries">
                <ReferenceManyField reference="countries" target="Region_Code" addLabel={false}  sort={{ field: 'Sequence', order: 'ASC' }}>
                    <Datagrid style={{"width":"100%"}}>
                        <TextField source="Sequence"/>
                        <TextField source="Name"/>
                        <TextField source="Status_Code" label="Status"/>
                        <EditButton/>
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
        </TabbedForm>
    </Edit>
}
