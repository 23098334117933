import React from 'react'
import {
    List, Datagrid, TextField, EditButton, DeleteButton,
    Filter,TextInput,NullableBooleanInput
} from 'react-admin'
import {Country as CountryInput, Status, Region, Airport} from "../../components/refrences/input/RelatedInputs";
import {Country, Destination} from "../../components/refrences/field/RelatedFields";
import BooleanFieldWithNumber from "../../components/Fields/BooleanFieldWithNumber";


const DestinationFilter = (props) => (
    <Filter {...props}>
        <Region label='Region' source='Country.Region_Code' alwaysOn/>
        <CountryInput label="Country" source="Country_Code" alwaysOn/>
        <TextInput source="UI_Content.Description" label='Website Description' alwaysOn />
        <TextInput source="Name" alwaysOn />
        <TextInput source="Destination_Code" label='Destination Code' alwaysOn />
        <TextInput source="Master_Destination.Destination_Code" label='Master Destination Code' alwaysOn/>
        <TextInput source="Master_Destination.Name" label='Master Destination Name' alwaysOn/>
        <NullableBooleanInput source="Is_Master_Destination" label='Is Master'/>
        <Airport source='Destination_Airport.Airport_ID' label='Airport'alwaysOn/>
       <Status source="Status_Code" label='Status' alwaysOn  allowEmpty={true}/>
    </Filter>
);

const DestinationTable = props => {
    return (
        <Datagrid {...props} >
            <TextField source="ID" label="ID"/>
            <TextField source="Name"/>
            <Country source="Country_Code" label="Country"/>
            <BooleanFieldWithNumber source="Is_Master_Destination" label='Is Master' />
            <Destination source='Master_Destination_ID' label='Master Destination'/>
            <TextField source="Status_Code" label="Status"/>
            <EditButton/>
            <DeleteButton undoable={true}/>
        </Datagrid>
    )
}


export default props => {
    return <List {...props} filters={<DestinationFilter/>} exporter={false}
                 sort={{ field: 'ID', order: 'ASC' }}
                 filterDefaultValues={{Status_Code: 'Published'}}
    >
        <DestinationTable/>
    </List>
}







