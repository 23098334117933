import React from 'react';
import {Button} from '@material-ui/core/index'
import { makeStyles } from '@material-ui/core/styles/index';
const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
}));


export default props=>{
    const {onClick} = props;
    const classes = useStyles();
    return <Button variant="contained" color="primary" className={classes.button} onClick={onClick}>Back</Button>
}
