import React, {Fragment, useState} from "react";
import {Button, DialogActions, DialogContent, DialogTitle, Dialog} from "@material-ui/core";
import {Cancel, Save,Place} from "@material-ui/icons";
import {useNotify, useRefresh} from 'react-admin'
import axios from "axios";
import {basePath} from "../../rest-data-provider/paths";
import {notifyError} from "../../ec-admin-adapters/notifyF";
import TextField from "@material-ui/core/TextField";
import {getParameters, replaceParamVal} from "../../utils/paramtersUtil";

const MapButton = props => {
    const [show, setShow] = useState(false);
    const [map, setMap] = useState(undefined);
    const [zoom,setZoom] = useState(0);
    const notify = useNotify();
    const refresh = useRefresh();
    const {record} = props;
    const handleOpenClick = () => {
        axios.get(basePath + '/tripTemplates/map/' + record.ID).then((response) => {
            setShow(true);
            setMap(response.data);
            const params = getParameters(response.data);
            if(params)
            setZoom(params.zoom);
        }).catch(error => {
            notifyError(notify,error);
        })
    };

    const handleSaveClick = () => {
        axios.put(basePath + '/tripTemplates/map/' + record.ID, {map: map}).then((response) => {
            setShow(false);
            setMap(undefined);
            refresh();
        }).catch(error => {
            notifyError(notify,error);
        })
    };

    return (
        <Fragment>
            <Button color="primary" onClick={handleOpenClick} label="ra.action.create">
                <Place/>Map this Trip
            </Button>
            <Dialog
                maxWidth='xl'
                disableEnforceFocus
                fullWidth={false}
                open={show}
                onClose={()=>setShow(false)}
            >
                <DialogTitle>Map this Trip</DialogTitle>
                <DialogContent>
                    {map ?
                        <div>
                            <img src={map}/>
                        </div>
                        : null
                    }

                </DialogContent>
                <DialogActions>
                    <div>
                        <TextField id="zoom" label="zoom" type='number' value={zoom} onChange={e=>{
                            const value = e.target.value;
                            if(value>0&& value<13){
                                setZoom(value);
                                setMap(replaceParamVal("zoom",value,map));
                            }
                        }}/>
                    </div>
                    <Button color="primary"
                            onClick={handleSaveClick}
                    >
                        <Save/>update
                    </Button>
                    <Button
                        onClick={()=>setShow(false)}
                    >
                        <Cancel/>Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}
export default MapButton;
