import React, {useEffect, useState} from "react";
import {
    ReferenceInput as RelatedInput,
    AutocompleteInput,
    SelectInput,
    SelectArrayInput,
    GET_LIST,
    TextInput,
    GET_ONE,
    useNotify
} from 'react-admin';
import copyPropertiesUtil from "../copyPropertiesUtil";
import PropTypes from "prop-types";
import dataProvider from "../../../rest-data-provider";
import {normalFilter} from "./statusFilter";
import inputsStyle from "../../../theme/InputsStyle";
import {useForm} from "react-final-form";
import {AllButDeleted, emptyText} from "../../../constants/constant";
import {notifyError} from "../../../ec-admin-adapters/notifyF";
import auth from "../../../routes/auth/AuthProvider";


//!!! important in the filter of list page, must pass source and label props or it not work.
export const Accommodation = (props) => {
    return (
        <RelatedInput {...copyPropertiesUtil(props, "Accommodation_ID", "Accommodation")} reference="accommodations"
                      sort={{field: 'Name', order: 'ASC'}}
                      filter={{...normalFilter, ...props.filter}}
                      perPage={100}
                      filterToQuery={searchText => ({Name: searchText})}
        >
            <AutocompleteInput optionText="Name" emptyText={emptyText}/>
        </RelatedInput>
    )
}

export const Activity = (props) => {
    return (
        <RelatedInput {...copyPropertiesUtil(props, "Activity_Code", "Activity")} reference="activities"
                      sort={{field: 'Name', order: 'ASC'}}
                      filter={normalFilter}
                      perPage={100}
                      filterToQuery={searchText => ({Name: searchText})}
        >
            <AutocompleteInput optionText="Name" emptyText={emptyText}/>
        </RelatedInput>
    )
}

export const Amenity = (props) => {
    return (
        <RelatedInput {...copyPropertiesUtil(props, "Amenity_Code", "Amenity")} reference="amenities"
                      sort={{field: 'Name', order: 'ASC'}}
                      perPage={100}
        >
            <SelectInput optionText="Name" emptyText={emptyText}/>
        </RelatedInput>
    )
}



export const AccommodationTier = props => {
    return (
        <RelatedInput {...copyPropertiesUtil(props, "Accommodation_Tier_Code", "Accommodation Tier")}
                      reference="accommodationTiers"
                      sort={{field: 'Name', order: 'ASC'}}
        >
            <SelectInput optionText="Name" emptyText={emptyText}/>
        </RelatedInput>
    )
}

export const Airport = (props) => {
    return (
        <RelatedInput {...copyPropertiesUtil(props, "Airport_ID", "Airport")} reference="airports"
                      sort={{field: 'Name', order: 'ASC'}}
                      perPage={100}
                      filterToQuery={searchText => ({Name: searchText})}
        >
            <AutocompleteInput optionText="Name" emptyText={emptyText}/>
        </RelatedInput>
    )
}

export const Animal = (props) => {
    return <RelatedInput {...copyPropertiesUtil(props, "Animal_Code", "Animal")} reference="animals"
                         sort={{field: 'Name', order: 'ASC'}}
                         perPage={100}
                         filterToQuery={searchText => ({Name: searchText})}
    >
        <AutocompleteInput optionText="Name" emptyText={emptyText}/>
    </RelatedInput>
}

export const Affiliate = (props) => {
    return <RelatedInput {...copyPropertiesUtil(props, "Affiliate_Code", "Availability System")} reference="affiliates"
                         sort={{field: 'Name', order: 'ASC'}}
    >
        <SelectInput optionText="Name" emptyText={emptyText}/>
    </RelatedInput>
}


export const Country = (props) => {
    return <RelatedInput {...copyPropertiesUtil(props, "Country_Code", "Country")} reference="countries"
                         sort={{field: 'Name', order: 'ASC'}}
                         perPage={100}
                         filter={normalFilter}
                         filterToQuery={searchText => ({Name: searchText})}
    >
        <AutocompleteInput optionText="Name" emptyText={emptyText}/>
    </RelatedInput>
}

export const Currency = (props) => {
    return <RelatedInput {...copyPropertiesUtil(props, "Currency_Code", "Currency")} reference="currencies"
                         sort={{field: 'Code', order: 'ASC'}}
                         filter={normalFilter}
                         defaultValue='USD'
    >
        <SelectInput optionText="Code" emptyText={emptyText}/>
    </RelatedInput>
}

export const Destination = (props) => {
    return (
        <RelatedInput {...copyPropertiesUtil(props, "Destination_ID", "Destination")} reference="destinations"
                      sort={{field: 'Name', order: 'ASC'}}
                      filter={{...normalFilter,...props.filter}}
                      perPage={2000}
                      filterToQuery={searchText => ({Name: searchText})}
                      className={props.customClass||inputsStyle().destination}

        >
            <AutocompleteInput optionText="Name"  className={inputsStyle().destination} emptyText={emptyText}/>
        </RelatedInput>
    )
}

export const ReadonlyDestination = (props)=>{
    let {record,source,label}=props;
    source=source||'Destination_ID';
    const ID = record[source];
    const form = useForm();
    const readonlySource= 'Readonly_Destination';
    const notify = useNotify();
    useEffect(()=>{
        if(ID){
            dataProvider(GET_ONE, 'destinations', {
                id: ID
            }).then(
                (resp) => {
                    if (resp && resp.data) {
                        form.change(readonlySource,resp.data.Name);
                    }
                }
            ).catch(error=>{
                notifyError(notify,error);
            })
        }
    },[ID,form])

    return <TextInput source={readonlySource} label={(label||'Destination')} disabled className={inputsStyle().destination} />
}

export const Ecosystem = (props) => {
    return <RelatedInput {...copyPropertiesUtil(props, "Ecosystem_Code", "Ecosystem")} reference="ecosystems"
                         sort={{field: 'Name', order: 'ASC'}}
    >
        <SelectInput optionText="Name" emptyText={emptyText}/>
    </RelatedInput>
}

export const Guest = props => {
    const guestRender = choice => {
        if(choice&&choice.id){
            return choice.First_Name+" "+choice.Last_Name;
        }else{
            return "";
        }
    }
    return (
        <RelatedInput {...copyPropertiesUtil(props, "Requester_Guest_ID", "Primary Contact")} reference="guests"
                      sort={{field: 'ID', order: 'ASC'}}
                      perPage={100}
                      filterToQuery={searchText => ({Name: searchText})}
        >
            <AutocompleteInput optionText={guestRender} emptyText={emptyText}/>
        </RelatedInput>
    )
}

export const Language = (props) => {
    return <RelatedInput {...copyPropertiesUtil(props, "Language_Code", "Language")} reference="languages"
                         sort={{field: 'Name', order: 'ASC'}}
                         defaultValue='ENG'
    >
        <SelectInput optionText="Name" emptyText={emptyText}/>
    </RelatedInput>
}

export const LodgingType = (props) => {
    return <RelatedInput {...copyPropertiesUtil(props, "Lodging_Type_Code", "Lodging Type")} reference="lodgingTypes"
                         sort={{field: 'Name', order: 'ASC'}}
    >
        <SelectInput optionText="Name" emptyText={emptyText}/>
    </RelatedInput>
}



export const MealBasis = (props) => {
    return <RelatedInput {...copyPropertiesUtil(props, "Meal_Basis", "Meal Basis")} reference="mealBases"
                         sort={{field: 'Name', order: 'ASC'}}
                         perPage={100}
    >
        <SelectInput optionText="Name" emptyText={emptyText}/>
    </RelatedInput>
}

export const DrinksBasis = (props) => {
    return <RelatedInput {...copyPropertiesUtil(props, "Drinks_Basis", "Drinks Basis")} reference="drinksBases"
                         sort={{field: 'Name', order: 'ASC'}}
                         perPage={100}
    >
        <SelectInput optionText="Name" emptyText={emptyText}/>
    </RelatedInput>
}

export const RoomConfiguration = (props) => {
    return <RelatedInput {...copyPropertiesUtil(props, "Room_Configuration_Code", "Room Configuration")}
                         reference="roomConfigurations"
                         sort={{field: 'Sequence', order: 'ASC'}}
    >
        <SelectInput optionText="Name" emptyText={emptyText}/>
    </RelatedInput>
}

export const Region = (props) => {
    return <RelatedInput {...copyPropertiesUtil(props, "Region_Code", "Region")} reference="regions"
                         sort={{field: 'Name', order: 'ASC'}}
                         filter={normalFilter}
                         perPage={100}
    >
        <SelectInput optionText="Name" emptyText={emptyText}/>
    </RelatedInput>
}

export const Special = (props) => {
    return (
        <RelatedInput {...copyPropertiesUtil(props, "Special_ID", "Special")} reference="specials"
                      sort={{field: 'Name', order: 'ASC'}}
                      filter={normalFilter}
                      perPage={100}
                      filterToQuery={searchText => ({Name: searchText})}
        >
            <AutocompleteInput optionText="Name" emptyText={emptyText} />
        </RelatedInput>
    )
}

export const ServiceType = (props) => {
    return <RelatedInput {...copyPropertiesUtil(props, "Service_Type_Code", "Service Type")} reference="serviceTypes"
                         sort={{field: 'Name', order: 'ASC'}}
                         perPage={100}
    >
        <SelectInput optionText="Name" emptyText={emptyText}/>
    </RelatedInput>
}

export const TransferMode = (props) => {
    return <RelatedInput {...copyPropertiesUtil(props, "Final_Transfer_Mode_Code", "Final Transfer Mode")}
                         reference="transferModes"
                         sort={{field: 'Name', order: 'ASC'}}
    >
        <SelectInput optionText="Name" emptyText={emptyText}/>
    </RelatedInput>
}

export const AgencyMode = (props) => {
    return <RelatedInput {...copyPropertiesUtil(props, "Agency_ID", "Agency")}
                         reference="agencies"
                         sort={{field: 'Name', order: 'ASC'}}
                         disabled={auth.getRole().toLowerCase() === 'standard' ? true : false}
                         defaultValue={auth.getRole().toLowerCase() === 'standard' ? auth.getAgency() : null}
    >
        <SelectInput optionText="Name" emptyText={emptyText}/>
    </RelatedInput>
}

export const TripOwnerMode = (props) => {
    return <RelatedInput {...copyPropertiesUtil(props, "Trip_Owner_ID", "Trip_Owner")}
                        reference="users"
                        sort={{field: 'Name', order: 'ASC'}}
                        filterToQuery={() => ({Is_Active: 1})}
    >
        <SelectInput optionText="Name" emptyText="None" />
    </RelatedInput>
}

export const Trip = props => {
    return (
        <RelatedInput {...copyPropertiesUtil(props, "Trip_ID", "Trip")} reference="trips"
                      sort={{field: 'Name', order: 'ASC'}}
                      perPage={100}
                      filterToQuery={searchText => ({Name: searchText})}
        >
            <AutocompleteInput optionText="Name" emptyText={emptyText}/>
        </RelatedInput>
    )
}

export const Trip_Template = props => {
    return (
        <RelatedInput {...copyPropertiesUtil(props, "Trip_Template_ID", "Trip Template")} reference="tripTemplates"
                      sort={{field: 'Name', order: 'ASC'}}
                      perPage={100}
                      filterToQuery={searchText => ({Name: searchText})}
        >
            <AutocompleteInput optionText="Name" emptyText={emptyText}/>
        </RelatedInput>
    )
}

export const Vendor = (props) => {
    return <RelatedInput {...copyPropertiesUtil(props, "Vendor_ID", "Vendor")} reference="vendors"
                         sort={{field: 'Name', order: 'ASC'}}
                         filter={{ ...props.filter}}
                         perPage={100}
                         filterToQuery={searchText => ({Name: searchText})}

    >
        <AutocompleteInput optionText="Name" emptyText={emptyText} />
    </RelatedInput>
}



export const Status = (props) => {
    return <RelatedInput {...copyPropertiesUtil(props, "Status_Code", "Status")} reference="status"
                         sort={{field: 'Sequence', order: 'ASC'}}
    >
        <SelectInput optionText="Name"  emptyText={AllButDeleted}/>
    </RelatedInput>
}

export const TripTypes = (props) => {
    return <RelatedInput {...copyPropertiesUtil(props, "Type", "Type")} reference="tripTypes"
                         sort={{field: 'Sequence', order: 'ASC'}}
    >
        <SelectInput optionText="Name"  emptyText={emptyText}/>
    </RelatedInput>
}

export const RateStatus = (props) => {
    return <RelatedInput {...copyPropertiesUtil(props, "Rate_Status_Code", "Rate Status")} reference="rateStatus"
                         sort={{field: 'Sequence', order: 'ASC'}}
    >
        <SelectInput optionText="Name"  emptyText={emptyText}/>
    </RelatedInput>
}

export const Passengers = (props) => {
    const {tripID,className, ...others} = props;
    const [passengers, setPassengers] = useState([]);
    const notify = useNotify();
    function _getPassengers(tripID) {
        return dataProvider(GET_LIST, 'passengers', {
            filter: {Trip_ID: tripID},
            pagination: {page: 1, perPage: 100},
            sort: {field: "id", order: "ASC"}
        }).then(
            (result) => {
                if (result && result.data) {
                    return result.data.map(item => {
                        const {ID, First_Name, Last_Name} = item;
                        return {id: ID, name: First_Name + " " + Last_Name};
                    })
                }
                return []
            }).catch(error=>{
          notifyError(notify,error);
        })

    }

    useEffect(() => {
        _getPassengers(tripID).then(choices => {
            setPassengers(choices);
        })
    }, [tripID]);
    if (passengers.length > 0) {
        return <SelectArrayInput
            className={className}
            style={{"width":"400px"}}

            {...copyPropertiesUtil(others, 'passengerIDs', 'Passengers')} choices={passengers}
                                 optionText="name"
                                 optionValue="id" />
    }
    return <TextInput className={className} disabled  source='Passengers_Warning' label='Passengers Warning' defaultValue='No Passengers'/>
}

Passengers.propTypes = {
    tripID: PropTypes.number.isRequired
}










