import {Field, useForm} from "react-final-form";
import React,{useEffect,Fragment} from  "react";
import PropTypes from "prop-types";

export const GetForm = (props)=>{
    const {setForm} = props;
    const form = useForm();
    useEffect(()=>{
        setForm(form);

    },[]);
    return <Fragment/>
}

GetForm.propTypes = {
    setForm: PropTypes.func.isRequired
}
