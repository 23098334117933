import React from 'react'
import {TextInput,Create,NumberInput,TabbedForm,FormTab} from 'react-admin'
import UIContentFormTab from '../../components/forms/UIContentFormTab'
import Validator from '../../validators/activityValidator'
import {Status} from "../../components/refrences/input/RelatedInputs";

export default props => {
    const validator = new Validator().g_cr_var_fui()
    return (
        <Create {...props}>
            <TabbedForm  validate ={validator} redirect='list'>
                <FormTab label="General">
                <TextInput source="Name" required/>
                <TextInput source="Code" required/>
                <TextInput fullWidth multiline  source="Subtitle" />
                <TextInput fullWidth multiline  label="Description" source="Description"/>
                <Status source="Status_Code" label='Status' defaultValue='Published'/>
                <NumberInput source="Sequence" />
                </FormTab>
               <UIContentFormTab/>
            </TabbedForm>
        </Create>
    )
};
