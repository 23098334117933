import {useForm, useFormState} from "react-final-form";
import React, {useEffect} from "react";
import axios from "axios";
import {basePath} from "../../rest-data-provider/paths";
import {notifyError} from "../../ec-admin-adapters/notifyF";
import {useNotify,TextInput, GET_ONE} from 'react-admin';
import dataProvider from "../../rest-data-provider";

export default props=>{
    const formState = useFormState();
    const form = useForm();
    const notify = useNotify();
    const {Accommodation_ID,Room_Type_Code,Service , Destination_Code,Vendor_Code, Name} = formState.values;
    let Service_Type_Code ;
    if(Service){
        Service_Type_Code = Service.Service_Type_Code;
    }
    useEffect(()=>{
        if(Accommodation_ID){
            axios.get(basePath + '/services/findServiceLineRelatedCodeForAccommodationActivity/' + Accommodation_ID).then((resp) => {
                if(resp.data.length>0){
                    const codes = resp.data[0];
                    form.change('Destination_Code',codes['Destination_Code']);
                    form.change('Vendor_Code',codes['Vendor_Code']);
                }
            }).catch(error => {
                notifyError(notify,error);
            })
            dataProvider(GET_ONE, 'accommodations', {
                id: Accommodation_ID
            }).then(accommodationResponse => {
                const accommodationName = accommodationResponse.data.Name;
                form.change('Service.Name', accommodationName + '-' + (Name || ''));
            }).catch(error=>{
                notifyError(notify,error);
            })
        }else{
            form.change('Destination_Code',null);
            form.change('Vendor_Code',null);
            form.change('Service.Name', null);
        }
    },[Accommodation_ID, Destination_Code, Vendor_Code, Room_Type_Code, Name]);

    useEffect(()=>{
        if(Room_Type_Code&&Service_Type_Code&&Destination_Code&&Vendor_Code){
            form.change('Service_Line_Identifier',Destination_Code+Service_Type_Code+Vendor_Code+Room_Type_Code);
        }else{
            form.change('Service_Line_Identifier',null);
        }
    },[Room_Type_Code,Service_Type_Code,Destination_Code,Vendor_Code]);

    return  <TextInput {...props} source="Service_Line_Identifier" label='Service Line Identifier' />
}
