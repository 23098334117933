import uiContentValidateMeta from "./uiContentValidateMeta";
import serviceValidateMeta from "./serviceValidateMeta";
export default {
    primaryKey:'ID',
    commonValidate: {
        Name: [{isLength:{max: 100}}],
        Activity_Code: [{isLength:{max: 15}}],
        Status_Code: ['Required',{isLength:{max: 15}}],
        Accommodation_ID:['Required',{isInt:{min:1}}],
        Service_Code:['Required',{isLength:{min:6,max: 6}}],
        Service_Line_Identifier: [{isLength:{max: 100}}],
       ...serviceValidateMeta,
        ...uiContentValidateMeta,

    }
}



