import baseValidator from "../../validators/baseValidator";

const  commonValidate = {
    NewDestination: ['Required',{isInt:{min: 1,max: 4294967295}}],
    ThroughHub: ['Required',{isInt:{min: 1,max: 4294967295}}],
    Service_Line_Identifier:  ['Required',{isLength:{max:100}}],
}


export default class WrapRouteValidator extends baseValidator {
    meta = {commonValidate}
    uiMeta={
        resources: "routes",
    }
    constructor() {
        super();
        this.g_cr_var_fui = super.g_cr_var_fui.bind(this)
        this.g_up_var_f_ui = super.g_up_var_f_ui.bind(this)
    }
}
