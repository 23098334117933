import {parseFromTimeZone} from "date-fns-timezone";
import React from "react";
import Typography from "@material-ui/core/Typography";
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export default (props)=>{
    const {record,className,source}=props;
    if(record === undefined) return null;
    const value = record[source];
    const date = parseFromTimeZone(value, { timeZone });
    const dateString = date.toLocaleDateString();

    return <Typography component={'span'} variant='body2' className={className}>{dateString}</Typography>
}
