import React from "react";
import {Field, useForm} from "react-final-form";
import {useNotify} from 'react-admin';
import PropTypes from "prop-types";

const BushField = (props)=>{
    const {subsField,subsEvent,children} = props;
    const notify = useNotify();
    const form = useForm();
    return (
        <Field name={subsField} subscription={{ value: true }}>
            {({ input: { value } }) => {
                if(typeof subsEvent ==='function')
                    subsEvent({value,form,notify})

                return children;
            }}
        </Field>
    )
}

BushField.propTypes = {
    subsField: PropTypes.string.isRequired,
    subsEvent: PropTypes.func

}

export default BushField;
