import React,{Fragment} from 'react'
import {
    List, downloadCSV, Datagrid, TextField, EditButton,
    Filter,TextInput,NullableBooleanInput
} from 'react-admin'
import {unparse as convertToCSV} from 'papaparse/papaparse.min'
import idReducer from '../../ec-admin-adapters/idReducer'
import BooleanFieldWithNumber from '../../components/Fields/BooleanFieldWithNumber'
import {Vendor} from "../../components/refrences/field/RelatedFields";
import {Vendor as VendorInput} from "../../components/refrences/input/RelatedInputs";


const VendorFilter = (props) => (
    <Filter {...props}>
        <TextInput source="Name" alwaysOn />
        <TextInput source="Code" label='Code' alwaysOn />
        <NullableBooleanInput source='Is_Vendor' label = 'Is Vendor' alwaysOn/>
        <NullableBooleanInput source='Is_Master_Vendor' label = 'Is Master' alwaysOn/>
        <NullableBooleanInput source='Is_DMC' label = 'Is DMC' alwaysOn/>
        <VendorInput source='DMC_ID' label='DMC Vendor' filter={{Is_DMC:true}}/>
        <VendorInput source='Master_Vendor_ID' label='Master Vendor' filter={{Is_Master_Vendor:true}}/>
    </Filter>
);

const exporter = vendors => {
    vendors = idReducer(vendors)
    const csv = convertToCSV({
        data: vendors,
        // fields: ['Final_Transfer_Origin', 'Name', 'Final_Transfer_Destination'] // order fields in the export
    });
    downloadCSV(csv, 'vendors  ' + new Date()); // download as 'vendors.csv` file
}

const VendorTable = props => {
    return (
        <Datagrid {...props} >
            <TextField source="ID" label='ID'/>
            <TextField source="Name"/>
            <TextField source="Code" label="Code"/>
            <TextField source="Email_Address" label="Email Address"/>
            <TextField source="Primary_Phone" label="Primary Phone"/>
            <TextField source="Default_Currency_Code" label="Default Currency Code"/>
            <BooleanFieldWithNumber source="Is_Vendor" label="Is Vendor" />
            <BooleanFieldWithNumber source="Is_DMC" label="Is DMC" />
            <BooleanFieldWithNumber source="Is_Master_Vendor" label="Is Master" />
            <Vendor source='DMC_ID' label='DMC Vendor'/>
            <Vendor source='Master_Vendor_ID' label='Master Vendor'/>
            <EditButton/>
        </Datagrid>
    )
}


export default props => {

    return (
        <List {...props} filters={<VendorFilter/>} exporter={exporter}
              bulkActionButtons={<Fragment/>}
              sort={{ field: 'ID', order: 'ASC' }}
        >
            <VendorTable/>
        </List>
    )
}







