import React from "react";
import {connect} from 'react-redux';

const EditTitle = ({title}) => {
    return <span>{title}</span>
}

const mapStateToProps = (state, ownProps) => {
    const{recordToTitle,resource,id,EntityName}=ownProps;
    let title ;
    const prefix =  "Editing " + EntityName + " - ";
    // login out bug with react-admin.
    if(!state.admin.resources[resource]){
        return {title:''}
    }

    const record = state.admin.resources[resource].data[id];
    if (record) {
        if(recordToTitle){
            title = prefix+ recordToTitle(record,state.admin.resources);
        }else{
            title = prefix+ (record.Name||record.ID||'')
        }
    } else {
        title = "";
    }
    return {
        title: title
    }
}

export default connect(mapStateToProps, undefined)(EditTitle)
