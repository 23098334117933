export default {
    primaryKey:'ID',

    commonValidate: {
        Passenger_Count:['Required',{isInt:{min:1,max: 4294967295}}],
        Start_Date:['Required'],
        Type:['Required'],
        Agency_ID:['Required'],
    },
    createValidate:{
        Accommodation_Tier_Code:[{isLength:{max: 15}}],
        Requester_Email:[{isLength:{max: 100}},'isEmail'],
        Requester_Phone:[{isLength:{max: 100}}],
        Requester_First_Name:[{isLength:{max: 100}}],
        Requester_Last_Name:['Required',{isLength:{max: 100}}],
    },
    updateValidate:{
        Name: ['Required',{isLength:{max: 100}}],
        Price_Per_Pax:[{isFloat:{min:0.0,max:999999.999999}}],
        Trip_Template_ID:['Required'],
    }



}



