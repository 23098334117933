import React from 'react'
import {
    Edit, TextInput, TabbedForm, FormTab, DateTimeInput,Toolbar,SaveButton,BooleanInput
} from 'react-admin';
import Validator from '../../validators/destinationsToActivitiesValidator';
import EditTitle from "../../components/forms/EditTitle";
import { withStyles } from '@material-ui/core';
import CustomDeleteButton from "../../components/Buttons/CustomDeleteButton";
import Rates from "../rates";
import {Destination, Status,ServiceType} from "../../components/refrences/input/RelatedInputs";
import ActivitySelectInput from "../activities/ActivitySelectInput";
import HiddenInput from "../../components/inputs/HiddenInput";
import UIContentFormTab from "../../components/forms/UIContentFormTab";
import {VendorSubsServiceLine} from "../services/VendorSubsServiceLine";
import DisabledBooleanInput from "../../components/inputs/DisabledBooleanInput";

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

function recordToTitle(record){
    if(record&&record.Name)
        return 'Remove Activity ('+(record.Name||(record.Activity&&record.Activity.Name)||record.ID)+'['+record.Destination.Name+']) from Destination?';
    return '';
}

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
        <CustomDeleteButton recordToTitle={recordToTitle}/>
    </Toolbar>
));


const EditTabs = (props) => {
    const validator = new Validator().g_up_var_f_ui(props.id);
    return (
        <TabbedForm  validate ={validator} {...props} toolbar={<CustomToolbar />}>
            <FormTab label="General">
                <DateTimeInput disabled label='Created' source="Created_Time"/>
                <DateTimeInput disabled label='Modified' source="Modified_Time"/>
                <Destination source='Destination_ID' label='Destination'/>
                <ActivitySelectInput/>
                <TextInput source="Name"/>
                <TextInput source="Service_Line_Identifier" label='Service Line Identifier'/>
                <VendorSubsServiceLine source="Service.Vendor_ID" label='Vendor Name'/>
                <Status source="Status_Code" label='Status' defaultValue='Published'/>
            </FormTab>
            <UIContentFormTab/>
            <FormTab label="Service" >
                <HiddenInput source="Service.ID" label="ID" required disabled/>
                <TextInput source="Service.Name"  label="Name"  required/>
                <ServiceType source='Service.Service_Type_Code' defaultValue='AT'/>
                <DisabledBooleanInput source="Service.Priced" label='Priced' defaultValue={false}/>
                <BooleanInput source="Service.Needs_Update" label='Needs Update' defaultValue={false}/>
            </FormTab>
            <FormTab label="Rates">
                <Rates/>
            </FormTab>
        </TabbedForm>
    )
}

const DestinationsToActivitiesEdit = props => {
    return <Edit {...props} undoable={false} title={<EditTitle EntityName='Destination Activity' id={props.id} resource='destinationsToActivities'/>}>
        <EditTabs/>
    </Edit>
}


export default DestinationsToActivitiesEdit;
