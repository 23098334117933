import React, {Fragment, useState} from "react";
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {
    SimpleForm,useNotify,useRefresh
} from 'react-admin'
import PropTypes from "prop-types";
import SaveCancelButtons from "../../components/Buttons/SaveCancelButtons";
import EditButton from "../../components/Buttons/EditButton";


const MergeButton = props => {
    const notify = useNotify();
    const refresh = useRefresh();
    const [show, setShow] = useState(false);
    const {record,children,submitEvent,title,validator} = props;

    const handleOpenClick = () => {
        setShow(true);
    };
    const handleCloseClick = () => {
        setShow(false);
    };

    const handleSubmit = (values) => {
        submitEvent({notify,refresh,values});
    }

    return (
        <Fragment>
            <EditButton clickEvent={handleOpenClick}/>
            <Dialog
                disableEnforceFocus
                fullWidth
                open={show}
                onClose={handleCloseClick}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <SimpleForm save={handleSubmit}
                                 validate ={validator}
                                record={record}
                                toolbar={<SaveCancelButtons cancelEvent={handleCloseClick}/>}
                    >
                       {children}
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </Fragment>
    );

};

// required when create.
MergeButton.propTypes = {
    submitEvent: PropTypes.func.isRequired,
    record: PropTypes.object.isRequired,
    validator:PropTypes.func

}

export default MergeButton;
