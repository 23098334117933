import React, {Fragment} from "react";
import {
    Datagrid,TextField
} from 'react-admin';
import PropTypes from 'prop-types'
import TripDayEditButton from "./TripDayEditButton";





const TripDays = (props) => {
    const {tripID,...others} = props;
    return (
        <Fragment>
            <Datagrid style={{"width":"100%"}} {...others} >
                <TextField source='Day_Number' label='Day Number' />
                <TextField source='Notes' label='Notes'/>
                <TextField source='Remarks' label='Remarks'/>
                <TripDayEditButton />
            </Datagrid>
        </Fragment>
    )
}

TripDays.propTypes ={
    tripID: PropTypes.string.isRequired
}


export default TripDays;
