import React, {useEffect, useState, Fragment} from 'react'
import {
    List, Datagrid, TextField,
    Filter, EditButton, useNotify, SelectInput
} from 'react-admin'
import {
    Vendor
} from "../../components/refrences/field/RelatedFields";
import {Trip} from "../../components/refrences/input/RelatedInputs";
import BooleanFieldWithNumber from "../../components/Fields/BooleanFieldWithNumber";
import {CardTravel, Add} from '@material-ui/icons';
import {Button} from "@material-ui/core";
import StatedTable from "../../components/tables/StatedTable";
import {notifyError, notifyShow} from "../../ec-admin-adapters/notifyF";
import axios from "axios";
import {basePath} from "../../rest-data-provider/paths";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import VendorEmailButton from "./VendorEmailButton";
import CostsButton from "./CostsButton";
import CustomDeleteButton from "../../components/Buttons/CustomDeleteButton";
import auth from "../auth/AuthProvider";

const ReservationsToPackagesFilter = (props) => {
        const {setTripID, ...others} = props;
        const {filterValues} = others;
        setTripID(filterValues['Trip_ID']);
        const ownerID = filterValues['Trip_Owner_ID'];
        return <Filter {...others}>
            <Trip source='Trip_ID' label='Trip' alwaysOn 
                filter={{role: auth.getRole(), agency: auth.getAgency(), owner: auth.getUserID(), Trip_Owner_ID: ownerID}}
            />
            <SelectInput source='Trip_Owner_ID' label='Trip Owner' emptyText='Show all trips' choices={[{name:'Show my trips',id: auth.getUserID()}]} alwaysOn />
        </Filter>
    }
;

const Actions = props => {
    const {setSelectedIds, selectedIds, history, Trip_ID} = props;
    useEffect(() => {
        setSelectedIds(selectedIds)
    }, [selectedIds, setSelectedIds]);
    return (Trip_ID ?
        <div>
            <Button color="primary" onClick={() => {
                history.push("/reservationsToPackages/create?Trip_ID=" + Trip_ID)
            }} label="ra.action.create">
                <Add/>Create
            </Button>
            <Button color="primary" onClick={() => {
                history.push('/tripSteps?filter=%7B"Trip_ID"%3A' + Trip_ID + '%7D')
            }} label="ra.action.create">
                View Trip
            </Button>
        </div>
        : null)
};

const BulkActionButtons = props => {
    const notify = useNotify();
    const {selectedIds, selectedReservations, trigger, setTrigger, history} = props;
    return (<Fragment>
        {selectedIds.length < 2 ?
            <Fragment>
                <Button color="primary" onClick={() => {
                    if (!selectedReservations || !selectedReservations.length > 0) {
                        notifyShow(notify, "No reservation selected !");
                        return;
                    }
                    const itemIDs = selectedReservations.map(item => item.ID);
                    const data = {'itemIDs': itemIDs, ID: selectedIds[0]};
                    axios.put(basePath + "/reservations/pkg", data).then(() => {
                        notify('Allocated successfully');
                        setTrigger(!trigger);
                    }).catch(error => {
                        notifyError(notify, error);
                    })
                }} label="ra.action.allocate">
                    <CardTravel/>Package
                </Button>
                <ButtonGroup variant="text" color="primary" aria-label="contained primary button group">
                    <VendorEmailButton packageID={selectedIds[0]} history={history}/>
                </ButtonGroup>
            </Fragment>
            : null
        }

    </Fragment>)
};

// pass setData to get data outside.
const ReservationsToPackagesTable = props => {
    const {data, setData, ...others} = props;
    setData(data);
    return (
        <Datagrid data={data} {...others}>
            <Vendor source="Billing_Vendor_ID" label="Vendor" sortable={false}/>
            <TextField source='Code' sortable={false}/>
            <TextField source='Price' sortable={false}/>
            <BooleanFieldWithNumber source="Is_Gratuity_Included" label="Is Gratuity Included"/>
            <TextField source='Vendor_Invoice_Number' sortable={false}/>
            <TextField source='Quote_Number' sortable={false}/>
            <CostsButton/>
            <EditButton/>
            <CustomDeleteButton buttonLabel='Delete'/>
        </Datagrid>
    )
};

const ReservationsToPackagesList = props => {
    const [tripID, setTripID] = useState(undefined);
    const [reservations, setReservations] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [reservationRows, setReservationRows] = useState([]);
    const [selectedReservations, setSelectedReservations] = useState([]);
    const [data, setData] = useState([]);
    const [trigger, setTrigger] = useState(false);
    const [filterTrigger, setFilterTrigger] = useState(false);


    useEffect(() => {
        if (tripID) {
            axios.get(basePath + '/ReservationsToPackages/getReservationList/' + tripID).then(
                (response) => {
                    setReservations(response.data);
                    setReservationRows(response.data);
                    setFilterTrigger(!filterTrigger);
                }
            )
        }
    }, [tripID, trigger]);

    //BPS-785 comment out.
    // useEffect(() => {
    //     setSelectedReservations([]);
    //     if (selectedIds.length === 0) {
    //         setReservationRows(reservations);
    //     } else if (selectedIds.length === 1) {
    //         const selectedPackage = data[selectedIds[0]];
    //         const packageVendorId = selectedPackage['Billing_Vendor_ID'];
    //         setReservationRows(reservations.filter(reservation => (
    //                 reservation['DMC_ID'] === packageVendorId
    //             || reservation['Master_ID'] === packageVendorId
    //             || reservation['Vendor_ID'] === packageVendorId
    //             )
    //         ));
    //     } else {
    //         setReservationRows([]);
    //     }
    // }, [selectedIds, filterTrigger, reservations, data]);

    function selectCallBack(params) {
        setSelectedReservations(params.selectedRows)
    }

    return (
        <div>
            <List {...props} filters={<ReservationsToPackagesFilter setTripID={setTripID}/>}
                  actions={<Actions history={props.history} Trip_ID={tripID} setSelectedIds={setSelectedIds}/>}
                  bulkActionButtons={<BulkActionButtons selectedReservations={selectedReservations} trigger={trigger}
                                                        setTrigger={setTrigger}/>}
                  sort={{field: 'ID', order: 'ASC'}}
                  title='Reservation Packages'
            >
                <ReservationsToPackagesTable history={props.history} setData={setData}/>
            </List>
            {tripID ?
                <StatedTable stateParams={{Trip_ID: tripID}}
                             isCheckbox={true}
                             fields={["Sequence", "Vendor/DMC-pkg", 'Service_Vendor', 'DMC_Vendor', 'Master_Vendor', "Start_Date", "End_Date", "Destination", "Country", 'Service_Type', 'Service_Name', 'PAX']}
                             selectCallBack={selectCallBack} rows={reservationRows}
                />
                : null}

        </div>
    )
};

export default ReservationsToPackagesList;








