import {withStyles} from "@material-ui/core/index";
import BackButton from "../Buttons/BackButton";
import React from "react";
import {Toolbar, SaveButton, DeleteButton} from 'react-admin';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const GroupDiv = ({children})=>{
    return <div>{children}</div>
}

export const CustomBackToolbar = withStyles(toolbarStyles)(props => {
    const {back, redirect, ...others} = props;
    const {classes, className, ...saveProps} = others;
    return (<Toolbar {...others}>
        <GroupDiv>
            <SaveButton {...saveProps} redirect={redirect}/>
            <BackButton onClick={back}/>
        </GroupDiv>
        <DeleteButton undoable={false} redirect={redirect}/>
    </Toolbar>)
});
