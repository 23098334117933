import React from "react";
import {DateInput} from 'react-admin';
import {useForm} from "react-final-form";

export default props => {
    const form = useForm();

    return <DateInput {...props} source='Start_Date' label='Start Date' onChange={(e) => {
        form.change('End_Date',e.target.value);
    }
    }/>;
}
