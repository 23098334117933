import React from 'react'
import {
    Edit, TextInput, DateTimeInput, SimpleForm,BooleanInput
} from 'react-admin'
import Validator from '../../validators/userValidator'
import EditTitle from "../../components/forms/EditTitle";
import RoleSelect from "./RoleSelect";
import SaveButtonToolbar from "../../components/forms/SaveButtonToolbar";
import { AgencyMode } from '../../components/refrences/input/RelatedInputs';

export default props => {
    const validator = new Validator().g_up_var_f_ui(props.id)
    return <Edit title={<EditTitle EntityName='User' id={props.id} resource='users'/>} undoable={false} {...props} >
        <SimpleForm validate={validator} toolbar={<SaveButtonToolbar/>} >
            <DateTimeInput disabled label='Created' source="Created_Time"/>
            <DateTimeInput disabled label='Modified' source="Modified_Time"/>
            <TextInput source="Email" required/>
            <TextInput source="Name" required/>
            <RoleSelect/>
            <AgencyMode resource="Agency" label="Agency" />
            <BooleanInput source="Is_Active" label='Is Active' required/>
        </SimpleForm>

    </Edit>
}
