import React from 'react';
import {connect} from 'react-redux';
import {MenuItemLink, getResources, DashboardMenuItem,usePermissions} from 'react-admin';
import {withRouter} from 'react-router-dom';
import LabelIcon from '@material-ui/icons/Label';
import SubMenu from "./components/SubMenu";
import {Tram,Place,House,Favorite,TripOrigin,AccountCircle,Assignment} from '@material-ui/icons'

const adminMenus = ['users', 'agencies', 'integration', 'pricingFactors', 'emailConf', 'increaseRate']
const accommodationsMenus = ['accommodations','accommodationsToActivities','accommodationRoomTypes','accommodationsToSpecials']
const destinationsMenus = ['destinations','regions','airports','countries','destinationsToActivities']
const listMenus = ['activities','animals','lodgingTypes','accommodationTiers','currencies','transferModes'];
const routesAndTransfersMenus = ['routes','transfers'];
const tripMenus = ['tripTemplates','trips','tripSteps','reservationsToPackages'];
//,'trips','reservations'
const menus = {
    'Admin':adminMenus,
    'Accommodations':accommodationsMenus,
    'Destinations':destinationsMenus,
    'Lists':listMenus,
    'Routes&Transfers':routesAndTransfersMenus,
    'Trips':tripMenus,
}

const MenuItem = ({resource,onMenuClick,icon})=>{
    if(!resource)
        return null;
    return (
        <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={(resource.options && resource.options.label) || capitalize(resource.name)}
            leftIcon={(icon && icon) || (resource.icon && React.createElement(resource.icon)) || React.createElement(LabelIcon)}
            onClick={onMenuClick}
        />
    )
}

function capitalize(str){
return str.replace(/^\S/, s => s.toUpperCase())
}

const MenuItems =(props)=>{
    const {menu,menuState,resources,onMenuClick,handleToggle,icon} = props;

    return (
        <SubMenu
            handleToggle={() => handleToggle(menu)}
            isOpen={menuState[menu]}
            sidebarIsOpen={menuState[menu]}
            name={menu}
            icon={icon}
        >
            {
               menus[menu].map(resourceName => {
                        return (
                            <MenuItem resource={findResource(resources,resourceName)} onMenuClick={onMenuClick} key={resourceName}/>
                        )
                    })
            }
        </SubMenu>
    )
}

function findResource(resources,name){
    for(const resource of resources){
        if(resource.name ===name )
            return resource;
    }
}

const AdminMenus = (props)=>{
    const permissions = usePermissions();
    if(permissions &&permissions.loaded &&  permissions.permissions.toLowerCase() === 'admin'){
        return <MenuItems {...props} menu='Admin'  icon={<AccountCircle/>}/>
    }
    return null;
}

const BushMenu = (props) => {
    const {resources, onMenuClick} = props;
    const [menuState, setMenuState] = React.useState({});


    // console.log(props);
    function handleToggle(menu){
        setMenuState({...menuState,[menu]:!menuState[menu]});
    };
    const commonProps = {
        menuState,resources,onMenuClick,handleToggle
    }
    //icons : https://material.io/resources/icons/?style=baseline

    return (
        <div>
            <DashboardMenuItem onClick={onMenuClick}/>
            <MenuItem onMenuClick={onMenuClick} resource={findResource(resources,'vendors')}/>
            <MenuItem onMenuClick={onMenuClick} resource={findResource(resources,'doc_template')} icon={<Assignment/>} />
            <AdminMenus {...commonProps}/>
            <MenuItems menu='Accommodations' {...commonProps} icon={<House/>}/>
            <MenuItems menu='Destinations' {...commonProps} icon={<Place/>}/>
            <MenuItems menu='Lists' {...commonProps} icon={<Favorite/>}/>
            <MenuItems menu='Routes&Transfers' {...commonProps} icon={<Tram/>}/>
            <MenuItems menu='Trips' {...commonProps} icon={<TripOrigin/>}/>
        </div>
    )
}


const mapStateToProps = state => ({
    resources: getResources(state)
});

export default withRouter(connect(mapStateToProps)(BushMenu));
