import React from 'react'
import {
    List, Datagrid, TextField, EditButton, DeleteButton,NullableBooleanInput,
    Filter,NumberField
} from 'react-admin'
import {
    Destination as DestinationInput,
    Status,
    TransferMode as TransferModeInput,
    Vendor as VendorInput
} from "../../components/refrences/input/RelatedInputs";
import {Destination, TransferMode, Vendor} from "../../components/refrences/field/RelatedFields";
import BooleanFieldWithNumber from "../../components/Fields/BooleanFieldWithNumber";


const TransferFilter = (props) => (
    <Filter {...props}>
        <Status source="Status_Code" label='Status' alwaysOn  allowEmpty={true}/>
        <NullableBooleanInput label="Bidirectional" source="Is_Bidirectional" alwaysOn />
        <DestinationInput source='From_Destination_ID' label='From Destination' alwaysOn/>
        <DestinationInput source='To_Destination_ID' label='To Destination' alwaysOn/>
        <TransferModeInput source='Transfer_Mode_Code' label='Transfer Mode' alwaysOn/>
        <VendorInput source='Service.Vendor_ID' label = 'Vendor' alwaysOn/>
        <NullableBooleanInput label="Priced" source="Service.Priced" alwaysOn />
        <NullableBooleanInput source="Service.Needs_Update" label='Needs Update' alwaysOn/>
    </Filter>
);

const TransferTable = props => {
    return (
        <Datagrid {...props} >
            <TextField source="ID" label='ID'/>
            <Vendor source='Service.Vendor_ID' label='Vendor'/>
            <Destination source="From_Destination_ID" label="From Destination" />
            <Destination source="To_Destination_ID" label="To Destination" />
            <TransferMode source="Transfer_Mode_Code" label="Transfer Mode"/>
            <NumberField source="Duration_Minutes" label="Duration Minutes"/>
            <BooleanFieldWithNumber source="Service.Priced" label="Priced" sortable={false}/>
            <BooleanFieldWithNumber source="Service.Needs_Update" label='Needs Update'sortable={false}/>
            <TextField source="Status_Code" label='Status'/>
            <EditButton/>
            <DeleteButton undoable={false}/>
        </Datagrid>
    )
}


export default props => {
    return <List {...props} filters={<TransferFilter/>} exporter={false}
                 filter={{Source_ID:null}}
                 sort={{field: 'ID', order: 'ASC'}}
                 filterDefaultValues={{Status_Code: 'Published'}}
    >
        <TransferTable/>
    </List>
}







