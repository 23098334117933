import React, {Fragment} from 'react'
import {
    List, downloadCSV, Datagrid, TextField, NumberField, EditButton, DeleteButton, CreateButton,
    Filter, TextInput, NumberInput, FunctionField
} from 'react-admin'
import {unparse as convertToCSV} from 'papaparse/papaparse.min'
import idReducer from '../../ec-admin-adapters/idReducer'
import DateZoneField from "../../components/Fields/DateZoneField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";


const IncreaseRateFilter = (props) => (
    <Filter {...props}>
        <TextInput source='Name' alwaysOn/>
        <NumberInput source='Year' alwaysOn/>
    </Filter>
);

const exporter = data => {
    data = idReducer(data)
    const csv = convertToCSV({
        data: data,
    });
    downloadCSV(csv, 'increase-rate-' + new Date());
}

const IncreaseRateTable = props => {
    return (
        <Datagrid {...props}>
            <TextField source="ID" label={"#"} />
            <TextField source="Name" label={"Name"}/>
            <TextField source="Year" label={"Year"}/>
            <FunctionField label={"Increase Rate"} render={record => `${record.Rate}%`}/>
            <DateZoneField source="Created_Time" label={"Created"} alwaysOn />
            <DateZoneField source="Modified_Time" label={"Modified"} alwaysOn/>
            <EditButton />
            <DeleteButton undoable={false}/>
        </Datagrid>
    )
}

const Empty = () => {
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h4" paragraph>
                No increase rates available
            </Typography>
            <CreateButton basePath={"increaseRate"}/>
        </Box>
    );
};

export default props => {

    return (
        <List {...props} filters={<IncreaseRateFilter/>} exporter={exporter} empty={<Empty />}
              sort={{ field: 'ID', order: 'DESC' }}
              bulkActionButtons={<Fragment/>}
        >
            <IncreaseRateTable/>
        </List>
    )
}







