import React from "react";
import {GET_ONE,NumberInput} from 'react-admin';
import dataProvider from "../../rest-data-provider";
import BushField from "../../components/forms/BushField";
import {notifyError} from "../../ec-admin-adapters/notifyF";


const subsEvent = ({value,form,notify})=>{
    form.change('Conversion_Rate', null);
    if (value)
        dataProvider(GET_ONE, 'currencies', {id: value}).then(currency => {
            form.change("Conversion_Rate", currency.data.Conversion_Rate)
        }).catch(error=>{
            notifyError(notify,error);
        })
}
const ConversionRateSubsCurrency = (props)=>{
    return <BushField  subsEvent={subsEvent} subsField={'Currency_Code'}>
        <NumberInput source='Conversion_Rate' label='Conversion Rate' disabled {...props}/>
    </BushField>
}


export default ConversionRateSubsCurrency;



