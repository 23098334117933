import React from "react";
import copyPropertiesUtil from "../copyPropertiesUtil";
import BushDownShift from "./BushDownShift";
import {getList, getOne} from "../../../rest-data-provider/fetchs/generateBushDownShiftFetch";
import PropTypes from "prop-types";
import {normalFilter} from "./statusFilter";
import inputsStyle from "../../../theme/InputsStyle";

export const Accommodation = (props) => {
    const {filter, ...others} = props;
    const _props = {
        referenceObj: "accommodations",
        defaultSource: "Accommodation_ID",
        defaultLabel: "Accommodation",
        sort: {field: "Name", order: 'ASC'},
        primaryKey: "ID"
    };
    return <Common filter={{...normalFilter, ...filter}} {..._props} {...others}/>
}

export const Animal = (props) => {
    const _props = {
        referenceObj: "animals",
        defaultSource: "Animal_Code",
        defaultLabel: "Animal",
        sort: {field: "Sequence", order: 'ASC'},
        primaryKey: "Code"
    };
    return <Common {..._props} {...props}/>
}


export const AccommodationTier = (props) => {
    const _props = {
        referenceObj: "accommodationTiers",
        defaultSource: "Accommodation_Tier_Code",
        defaultLabel: "Accommodation Tier",
        sort: {field: "Sequence", order: 'ASC'},
        primaryKey: "Code"
    };
    return <Common {..._props} {...props}/>
}

export const Amenity = (props) => {
    const _props = {
        referenceObj: "amenities",
        defaultSource: "Amenity_Code",
        defaultLabel: "Amenity",
        sort: {field: "Sequence", order: 'ASC'},
        primaryKey: "Code"
    };
    return <Common {..._props} {...props}/>
}

export const LodgingType = (props) => {
    const _props = {
        referenceObj: "lodgingTypes",
        defaultSource: "Lodging_Type_Code",
        defaultLabel: "Lodging Type",
        sort: {field: "Sequence", order: 'ASC'},
        primaryKey: "Code"
    };
    return <Common {..._props} {...props}/>
}

export const Airport = (props) => {
    const _props = {
        referenceObj: "airports",
        defaultSource: "Airport_ID",
        defaultLabel: "Airport",
        sort: {field: "ID", order: 'ASC'},
        primaryKey: "ID"
    };
    return <Common {..._props} {...props}/>
}

export const Activity = (props) => {
    const _props = {
        referenceObj: "activities",
        defaultSource: "Activity_Code",
        defaultLabel: "Activity",
        sort: {field: "Sequence", order: 'ASC'},
        primaryKey: "Code",
        filter: normalFilter
    };
    return <Common {..._props} {...props}/>
}

export const Destination = (props) => {
    const {filter, ...others} = props;
    const _props = {
        referenceObj: "destinations",
        defaultSource: "Destination_ID",
        defaultLabel: "Destination",
        sort: {field: "ID", order: 'ASC'},
        primaryKey: "ID"
    };
    return <Common filter={{...normalFilter, ...filter}}{..._props} {...others} className={inputsStyle().destination}/>
}
Destination.propTypes = {
    selectedCallback: PropTypes.func
}

export const Service = (props) => {
    const {filter, ...others} = props;
    const _props = {
        referenceObj: "services",
        defaultSource: "Service_ID",
        defaultLabel: "Service",
        sort: ["Service_Type_Code DESC", "Name ASC"],
        primaryKey: "ID"
    };
    return <Common filter={filter}{..._props} {...others}/>
}


export const Vendor = (props) => {
    const {filter, ...others} = props;
    const _props = {
        referenceObj: "vendors",
        defaultSource: "Vendor_ID",
        defaultLabel: "Vendor",
        sort: {field: "ID", order: 'ASC'},
        primaryKey: "ID"
    };
    return <Common filter={filter}{..._props} {...others}/>
}

export const Milestone = (props) => {
    const _props = {
        referenceObj: "reservationMilestoneTypes",
        defaultSource: "Reservation_Milestone_Type_Code",
        defaultLabel: "Milestone",
        sort: {field: "Sequence", order: 'ASC'},
        primaryKey: "Code"
    };
    return <Common {..._props} {...props}/>
}


const Common = (props) => {
    let {referenceObj, sort, fetch, fetchOne, filter, defaultSource, defaultLabel, primaryKey,required, ...others} = props;
    if (!fetch) {
        fetch = getList({
            referenceObj,
            sort,
            primaryKey,
            pagination: {page: 1, perPage: 100},
            getFilter: (searchText) => {
                return {Name: searchText, ...filter}
            }
        })
    }
    return (
        <BushDownShift {...others} {...copyPropertiesUtil(props, defaultSource, defaultLabel)} fetch={fetch}
                       fetchOne={fetchOne || getOne({referenceObj, primaryKey})}/>
    )
}




