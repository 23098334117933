import React from 'react'
import {
    Edit, TextInput, NumberInput, DateTimeInput
    , TabbedForm, FormTab, ReferenceManyField, Datagrid, EditButton, TextField
} from 'react-admin'
import Validator from '../../validators/countryValidator';
import UIContentFormTab from "../../components/forms/UIContentFormTab";
import EditTitle from "../../components/forms/EditTitle";
import {Region, Status} from "../../components/refrences/input/RelatedInputs";
import BooleanFieldWithNumber from "../../components/Fields/BooleanFieldWithNumber";

export default props => {
    const validator = new Validator().g_up_var_f_ui(props.id);
    return <Edit title={<EditTitle EntityName='Country' id={props.id} resource='countries'/>} undoable={false} {...props}>
        <TabbedForm   validate ={validator}>
            <FormTab label="General">
                <DateTimeInput disabled label='Created' source="Created_Time"/>
                <DateTimeInput disabled label='Modified' source="Modified_Time"/>
                <TextInput source="Name" required/>
                <TextInput source="Code" required/>
                <NumberInput source="Latitude"/>
                <NumberInput source="Longitude"/>
                <NumberInput source="Latitude_Offset"/>
                <NumberInput source="Longitude_Offset"/>
                <Region/>
                <Status source="Status_Code" label='Status' defaultValue='Published'/>
                <NumberInput source="Sequence"/>
            </FormTab>
            <UIContentFormTab/>
            <FormTab label="Destinations">
                <ReferenceManyField reference="destinations" target="Country_Code" addLabel={false} sort={{ field: 'ID', order: 'ASC' }}>
                    <Datagrid style={{"width":"100%"}}>
                        <TextField source="ID" label="ID"/>
                        <TextField source="Name"/>
                        <BooleanFieldWithNumber source="Is_Master_Destination" label="Is Master"/>
                        <TextField source="Status_Code" label="Status"/>
                        <EditButton/>
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>


        </TabbedForm>
    </Edit>
}
