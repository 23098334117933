import React from "react";
import TextField from "@material-ui/core/TextField";
import {Field} from "react-final-form";

const TimeInputComponent = props => {
    const {input, label,className, ...others} = props;
    return (
        <TextField
            {...input}
            key={input.name}
            label={label}
            type="time"
            variant="filled"
            margin='dense'
            className={className}
            InputLabelProps={{
                shrink: true,
            }}
            inputProps={{
                step: 60, // 1 min
            }}
            {...others}
        />
    )

};


export default props => {
    const{source,label,...others}=props;
    return <Field
        name={source}
        component={TimeInputComponent}
        label={label||source}
        {...others}
    />
}
