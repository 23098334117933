import React, {useEffect, useState} from 'react'
import {
    Edit, TextInput, SimpleForm, NumberInput, GET_ONE,useNotify
} from 'react-admin';

import Validator from '../../validators/tripStepValidator'
import {ReadonlyDestination} from "../../components/refrences/input/RelatedInputs";
import EditTitle from "../../components/forms/EditTitle";
import dataProvider from "../../rest-data-provider";
import CountrySubsDestination from "./CountrySubsDestination";
import {CustomBackToolbar} from "../../components/forms/CustomBackToolbar";
import {notifyError} from "../../ec-admin-adapters/notifyF";

const recordToTitle = (record, resources) => {
    return "Step[" + ((record && record.Step_Number) || 'Unknown') + "]";
}

export default props => {
    const validator = new Validator().g_up_var_f_ui(props.id)
    const notify = useNotify();
    const {history} = props;
    const [redirect, setRedirect] = useState('/tripSteps');
    useEffect(() => {
        if (props.id) {
            dataProvider(GET_ONE, 'tripSteps', {
                id: props.id
            }).then(
                (step) => {
                    if (step && step.data) {
                        const tripID = step.data.Trip_ID;
                        setRedirect('/tripSteps?filter=%7B"Trip_ID"%3A' + tripID + '%7D');
                    }
                }
            ).catch(error=>{
                notifyError(notify,error);
            })
        }
    }, [props.id]);

    function back() {
        history.push(redirect)
    };

    return <Edit
        title={<EditTitle EntityName='Trip Step' id={props.id} resource='tripSteps' recordToTitle={recordToTitle}/>}
        undoable={false} {...props} >
        <SimpleForm  validate ={validator} redirect={redirect} toolbar={<CustomBackToolbar back={back} redirect={redirect}/>}>
            <TextInput disabled source="Step_Number"/>
            <NumberInput source="Days" label='Nights'/>
            <ReadonlyDestination/>
            <CountrySubsDestination/>
            <TextInput fullWidth multiline  source='Remarks'/>
        </SimpleForm>
    </Edit>
}
