import React from 'react'
import {
    List, Datagrid, TextField, EditButton, Filter,TextInput,NullableBooleanInput
} from 'react-admin'
import CustomDeleteButton from "../../components/Buttons/CustomDeleteButton";
import BooleanFieldWithNumber from "../../components/Fields/BooleanFieldWithNumber";
import {Accommodation as AccommodationInput} from "../../components/refrences/input/RelatedInputs";
import {Accommodation} from "../../components/refrences/field/RelatedFields";

const AccommodationRoomTypeFilter = (props) => (
    <Filter {...props}>
        <TextInput source="Name"  alwaysOn/>
        <AccommodationInput label="Accommodation" source="Accommodation_ID" alwaysOn/>
        <NullableBooleanInput label="Priced" source="Service.Priced" alwaysOn />
        <NullableBooleanInput label="Needs Update" source="Service.Needs_Update" alwaysOn />
    </Filter>
);

function recordToTitle(record){
    if(record&&record.Name&&record.Accommodation&&record.Accommodation.Name)
        return 'Remove RoomType ('+(record.Name)+'['+record.Accommodation.Name+']) from Accommodation?';
    return '';
}

const AccommodationRoomTypeTable = props => {
    return (
        <Datagrid {...props} >
            <TextField source="ID" label="ID"/>
            <TextField source="Name"/>
            <Accommodation source='Accommodation_ID' label='Accommodation' />
            <BooleanFieldWithNumber source="Is_Default_for_Accommodation" label="Is Default For Accommodation" />
            <TextField source="Room_Type_Code" label="Room Type Code" />
            <TextField source="Service_Line_Identifier" label="Service Line Identifier" />
            <BooleanFieldWithNumber source="Service.Priced" label="Priced" sortable={false}/>
            <BooleanFieldWithNumber source="Service.Needs_Update" label="Needs Update" sortable={false}/>
            <EditButton/>
            <CustomDeleteButton recordToTitle={recordToTitle}/>
        </Datagrid>
    )
}


export default props => {
    return <List {...props} title='Accommodation Room Type' filters={<AccommodationRoomTypeFilter/>} exporter={false}
                 sort={{ field: 'ID', order: 'ASC' }}
    >
        <AccommodationRoomTypeTable/>
    </List>
}







