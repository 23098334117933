import React, {Fragment} from "react";
import {
   Datagrid, TextField,DeleteButton
} from 'react-admin';
import AirportAddButton from "./AirportAddButton";
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types'
import {Airport} from "../../../components/refrences/field/RelatedFields";

const useStyles = makeStyles({
    buttons: {
        display:'flex',
        'justify-content':'space-between'
    },
});



const Airports = (props) => {
    const classes = useStyles();
    const {destinationID,...others} = props;
    return (
        <Fragment>
            <div className={classes.buttons}>
                <span/>
                <AirportAddButton {...props}/>
            </div>

            <Datagrid style={{"width":"100%"}} {...others}  >
                <TextField source="Airport_ID" label="Airport ID"/>
                <Airport source='Airport_ID' label='Airport_Name'/>
                <DeleteButton undoable={false} redirect={false}/>
            </Datagrid>
        </Fragment>
    )
}
Airports.propTypes ={
    destinationID: PropTypes.string.isRequired
}


export default Airports;
