import CancelButton from "./CancelButton";
import React from "react";
import SaveButton from "./SaveButton";
import PropTypes from "prop-types";
import {useForm} from "react-final-form";

 const SaveCancelButtons = props=>{
    const {saveEvent,cancelEvent,saveLabel,saveDisabled}=props;
    const form = useForm();

    return <div style={{marginTop:160,float:'right'}}>
        <SaveButton disabled={saveDisabled} label={saveLabel}  clickEvent={()=>{
            if(saveEvent&&typeof saveEvent==='function'){
                saveEvent(form)
            }else{
                form.submit();
            }
            }}/>
        <CancelButton clickEvent={cancelEvent}/>
    </div>
}

SaveCancelButtons.propTypes = {
    saveEvent: PropTypes.func,
    cancelEvent: PropTypes.func.isRequired,
    saveLabel: PropTypes.string,
    saveDisabled: PropTypes.bool
}
export default SaveCancelButtons;
