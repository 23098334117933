import  React from 'react';
import  Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';


export  default ()=>(
    <Card>
        <CardHeader title='Welcome to the Bushtracks Admin'/>
        <CardContent >You are logged in now.</CardContent>
    </Card>
);

