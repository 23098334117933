import React, {Fragment, useEffect} from 'react'
import {
    List, Datagrid, TextField, EditButton,DateInput,
    TextInput, Filter, NumberField, NullableBooleanInput, SelectInput,
} from 'react-admin'
import { FormatField, TripTemplate, AgencyName} from '../../components/refrences/field/RelatedFields'
import {Guest, TripTypes, TripOwnerMode} from "../../components/refrences/input/RelatedInputs";
import {Button} from "@material-ui/core";
import {ViewStream,CardTravel} from "@material-ui/icons";
import DateZoneField from "../../components/Fields/DateZoneField";

import ButtonGroup from "@material-ui/core/ButtonGroup";
import GuestDocsButton from "./GuestDocsButton";
import PricePerPersonButton from "./PricePerPersonButton";
import auth from "../auth/AuthProvider";
import axios from "axios";
import {basePath} from "../../rest-data-provider/paths";

const TripFilter = (props) => (
    <Filter {...props}>
        <Guest source='Requester_Guest_ID' label='Guest' alwaysOn/>
        <TripTypes  source='Type' label='Type' alwaysOn/>
        <TextInput source='Name'alwaysOn/>
        {auth.getRole().toLowerCase() === 'standard' &&
            <SelectInput source='Trip_Owner_ID' label='Trip Owner' emptyText='Show all trips' choices={[{name:'Show my trips',id: auth.getUserID()}]} alwaysOn />
        }
        {auth.getRole().toLowerCase() === 'admin' && <TripOwnerMode source='Trip_Owner_ID' label='Trip Owner' alwaysOn/> }
        <DateInput source='Start_Date' alwaysOn/>
        <NullableBooleanInput source="Update_Salesforce" label='Update Salesforce' alwaysOn/>
    </Filter>
);

const Steps = props=> {
    const {history} = props;
    return <Button color="primary" onClick={()=>history.push('/tripSteps?filter=%7B"Trip_ID"%3A'+props.record.ID+'%7D')}>
        <ViewStream />steps
    </Button>}

const Packages = props=> {
    const {history} = props;
    return <Button color="primary" onClick={()=>history.push('/reservationsToPackages?filter=%7B"Trip_ID"%3A'+props.record.ID+'%7D')}>
        <CardTravel />packages
    </Button>}

const guestFormat = (record)=>{
    if(record&&record.Guest)
       return record.Guest.First_Name+' '+record.Guest.Last_Name;
    return '';
}

const TripTable = props => {
    return (
        <Datagrid {...props} >
            <TextField source="ID" label='ID'/>
            <TextField source="Name"/>
            <FormatField format={guestFormat} label='Guest'/>
            <TripTemplate source="Trip_Template_ID" label="Based on"/>
            <AgencyName source="Agency_ID" label="Agency"/>
            <DateZoneField source='Start_Date'/>
            <NumberField source='Passenger_Count'/>
            <TextField source='Price_Per_Pax' label='Price Per Person'/>
            <PricePerPersonButton/>
            <EditButton />
            <Steps history={props.history}/>
            <Packages history={props.history}/>
        </Datagrid>
    )
}

const BulkActionButtons = props => {
    const {selectedIds} = props;
    const [agency, setAgency] = React.useState(null);
    useEffect(() => {
        if(selectedIds.length === 1) {
            axios.get(basePath + '/trips/' + selectedIds[0]).then((res) => {
                setAgency(res.data.Agency_ID);
            }).catch(error => {
                console.log(error);
            })
        }
    })
    return (<Fragment>
        {selectedIds.length < 2 ?
            <Fragment>
                <ButtonGroup variant="text" color="primary" aria-label="contained primary button group">
                    <GuestDocsButton tripID={selectedIds[0]} agencyID={agency}/>
                </ButtonGroup>
            </Fragment>
            : null
        }

    </Fragment>)
};


export default props => {
    return <List {...props} filters={<TripFilter/>} exporter={false}
                sort={{field: 'ID', order: 'ASC'}}
                filterDefaultValues={{role: auth.getRole(), agency: auth.getAgency(), owner: auth.getUserID()}}
                bulkActionButtons={<BulkActionButtons/>}
    >
        <TripTable history={props.history}/>
    </List>
}







