import {basePath} from "../rest-data-provider/paths";
import {stringify} from "query-string/index";
import axios from "axios";
import SimpleValidateGenerator from './simpleValidateGenerator'
//generate => g , create=>cr ,update=>up , validator =>var forUI => f_ui validate=>vae
class BaseValidator {
    meta = {}
    uiMeta ={}

    async uniqueValidate(values,primaryValue){
        const {uniqueFields,queryFields} = this.meta;
        const {resources} = this.uiMeta;
        const diffPath = primaryValue ? ("/edit/"+primaryValue+"?"):  "/create/?";
        if(uniqueFields){
            const uniqueValues ={};
            uniqueFields.forEach(field=>{
                uniqueValues[field]=values[field];
            })
            const url = basePath + "/" + resources + "/validate"+diffPath + stringify(uniqueValues)+stringify(queryFields);

            return axios.get(url).then(function (response) {
                if (Object.keys(response.data).length > 0) {
                    return response.data;
                }
            })
        }
        return {};
    }

    g_cr_var_fui() {
        const {commonValidate,createValidate,uniqueFields} = this.meta;
        const {resources} = this.uiMeta;
        let validate ;
        if (commonValidate) {
            validate = SimpleValidateGenerator.g_vae(Object.assign({},commonValidate,createValidate));
        }else{
            return ()=>{};
        }
        if (resources && uniqueFields) {
            return (values)=>{
                const errors = validate(values);

                return Object.keys(errors).length ? errors : this.uniqueValidate(values);
            }
        }
        return validate;
    }

    g_up_var_f_ui(primaryValue) {
        const {commonValidate, updateValidate, uniqueFields} = this.meta;
        const {resources} = this.uiMeta;
        let validate ;
        if (commonValidate) {
            validate = SimpleValidateGenerator.g_vae(Object.assign({},commonValidate,updateValidate));
        }else{
            return ()=>{};
        }
        if (resources && uniqueFields) {
            if (primaryValue) {
                return (values)=>{
                    const errors = validate(values);
                    return this._noErrorValues(errors) ?  this.uniqueValidate(values,primaryValue) : errors;
                }
            }
        }
        return validate;
    }

    _noErrorValues(errors){
        return Object.values(errors).every(error=>{
            if(!error)
                return true;
            if(typeof error == 'string'){
                return false;
            }
            return this._noErrorValues(error);
        } )
    }
}


export default BaseValidator;

