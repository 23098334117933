import React from 'react'
import {
    TextInput,Create, SimpleForm,BooleanInput
} from 'react-admin'
import Validator from '../../validators/vendorValidator'
import {commonVendorFetchOne, dmcVendorFetch, masterVendorFetch} from '../../rest-data-provider/fetchs/vendorFetch';
import {Currency, Language} from "../../components/refrences/input/RelatedInputs";
import BushDownShift from "../../components/refrences/input/BushDownShift";

export default props => {
    const validator = new Validator().g_cr_var_fui()
    return (
        <Create {...props} >
            <SimpleForm  validate ={validator} redirect='list'>
                <TextInput source="Name" required/>
                <TextInput source="Code" label='Code' required/>
                <TextInput source="Email_Address" label='Email Address' required/>
                <TextInput source="Primary_Phone" label='Primary Phone' required/>
                <TextInput source="After_Hours_Phone" label='After Hours Phone'/>
                <TextInput source="Secondary_Phone" label='Secondary Phone'/>
                <TextInput source="Second_After_Hours_Phone" label='Second After Hours Phone'/>
                <BooleanInput source='Is_Vendor' label='Is Vendor' defaultValue={true}/>
                <BooleanInput source='Is_DMC' label='Is DMC'/>
                <BooleanInput source='Is_Master_Vendor' label='Is Master'/>
                <BushDownShift source='DMC_ID' label='DMC Vendor' fetch={dmcVendorFetch} fetchOne={commonVendorFetchOne} allowEmpty={true}/>
                <BushDownShift source='Master_Vendor_ID' label='Master Vendor' fetch={masterVendorFetch} fetchOne={commonVendorFetchOne} allowEmpty={true}/>
                <TextInput source="Mail_Name" label="Mail Name"/>
                <TextInput source="Mail_Address_1" label='Mail Address 1'/>
                <TextInput source="Mail_Address_2" label='Mail Address 2'/>
                <TextInput source="Mail_Address_3" label='Mail Address 3'/>
                <TextInput source="Mail_Address_4" label='Mail Address 4'/>
                <TextInput source="Mail_Address_5" label='Mail Address 5'/>
                <TextInput source="Mail_Post_Code" label='Mail Post Code'/>
                <TextInput source="Chain_Code" label='Chain Code'/>
                <Currency source='Default_Currency_Code' label='Default Currency Code'/>
                <Language source="Default_Language_Code" label="Default Language Code"/>
            </SimpleForm>
        </Create>
    )
};
