import {useForm, useFormState} from "react-final-form";
import React, {useEffect} from "react";
import {notifyError} from "../../ec-admin-adapters/notifyF";
import {useNotify , TextInput} from 'react-admin';
import axios from "axios";
import {basePath} from "../../rest-data-provider/paths";
import inputsStyle from "../../theme/InputsStyle";

export const MasterDestinationSubsDestination = props => {
    const formState = useFormState();
    const form = useForm();
    const notify = useNotify();
    const value = formState.values['Destination_ID'];
    const masterValue = formState.values['Master_Destination_Name'];
    useEffect(() => {
        if (value) {
            axios.get(basePath + '/destinations/findMasterDestination/' + value).then((response) => {
                if(response.data){
                    form.change('Master_Destination_Name', response.data.Name);
                }else{
                    form.change('Master_Destination_Name', null);
                }
            }).catch(error => {
                notifyError(notify,error);
            })
        }else{
            form.change('Master_Destination_Name', null);
        }
    },[masterValue, value, form, notify]);

    return (<TextInput {...props} source='Master_Destination_Name' label='Master Destination' disabled className={inputsStyle().destination}/>)

}
