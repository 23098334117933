import React, {Fragment} from "react";
import {
   Datagrid, TextField,EditButton,showNotification,refreshView
} from 'react-admin';
import TripTemplateStepAddButton from "./TripTemplateStepAddButton";
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types'
import CustomDeleteButton from '../../../components/Buttons/CustomDeleteButton'
import {Destination} from "../../../components/refrences/field/RelatedFields";
import {Button} from "@material-ui/core";
import axios from "axios";
import {basePath} from "../../../rest-data-provider/paths";
import {ArrowDownward, ArrowUpward} from "@material-ui/icons";
import {connect} from 'react-redux';


const useStyles = makeStyles({
    buttons: {
        display:'flex',
        'justify-content':'space-between'
    },
});

function recordToTitle(record){
    return 'Remove Step ('+ (record ? record.Step_Number : '')+ ') from TripTemplate?';
}

const UpButton = (props)=>{
    const {record,showNotification,refreshView} = props;
    if(!record) return <div />;
    const {Step_Number,ID,Trip_Template_ID} = record;
    return <Button style={{color:"#3F51B5"}} onClick={()=>{
        axios.put(basePath + "/tripTemplateSteps/upStep", {Step_Number,ID,Trip_Template_ID}).then((() => {
                refreshView();
            }
        )).catch(error=>{
            showNotification(error.message, 'warning')
        })
    }}>
        <ArrowUpward />
    </Button>
}
const UpButtonConnect = connect(undefined,{showNotification,refreshView})(UpButton)

const DownButton = (props)=>{
    const {record,showNotification,refreshView} = props;
    if(!record) return <div />;
    const {Step_Number,ID,Trip_Template_ID} = record;
    return <Button style={{color:"#3F51B5"}} onClick={()=>{
        axios.put(basePath + "/tripTemplateSteps/downStep", {Step_Number,ID,Trip_Template_ID}).then((() => {
                refreshView();
            }
        )).catch(error=>{
            showNotification(error.message, 'warning')
        })
    }}>
        <ArrowDownward />
    </Button>
}
const stateToSteps = state=>{
    return {};
}
const DownButtonConnect = connect(stateToSteps,{showNotification,refreshView})(DownButton)

const Steps = (props) => {
    const classes = useStyles();
    const {tripTemplateID,...others} = props;
    return (
        <Fragment>
            <div className={classes.buttons}>
                <span/>
                <TripTemplateStepAddButton {...props}/>
            </div>

            <Datagrid
                style={{"width":"100%"}} {...others}   >
                <TextField source='Step_Number'/>
                <Destination source='Destination_ID' label='Destination'/>
                <TextField source='Days'/>
                <UpButtonConnect label='Step Up'/>
                <DownButtonConnect label='Step Down'/>
                <EditButton/>
                <CustomDeleteButton recordToTitle={recordToTitle}/>
            </Datagrid>
        </Fragment>
    )
}
Steps.propTypes ={
    tripTemplateID: PropTypes.string.isRequired
}


export default Steps;
