import axios from "axios";
import {basePath} from "../paths";

export const  fromDestinationForNewReservation= async (tripID, stepNumber)=>{
    if (tripID && stepNumber)
        return axios.get(basePath + "/destinations/fromDestinationForNewReservation?tripID=" + tripID + "&stepNumber=" + stepNumber).then(((fromDestination) => {
                return fromDestination.data;
            }
        ))
    return null;
}


export const  fromDestinationForStep= async (tripID, stepNumber)=>{
    if (tripID && stepNumber)
        return axios.get(basePath + "/destinations/fromDestinationForStep?tripID=" + tripID + "&stepNumber=" + stepNumber).then(((fromDestination) => {
                return fromDestination.data;
            }
        ))
    return null;
}
