import React from "react";
import titleStyle from "../../../theme/TitleStyles";

export default (props)=>{
    const {info,isEdit}=props;
    return TemplateAccommodationTitle(info,isEdit);
}

const TemplateAccommodationTitle = (info, isEdit) => {
    const {Destination,Step_Number,Trip_Template} = info||{};
    const obj = {"Step Number":Step_Number,"Destination":Destination.Name,"Trip Template":Trip_Template.Name};
    return <p>{isEdit?'Edit':'Add'} Accommodation <br/>
        {keyValues(obj)}
    </p>
}

function keyValues(obj){
    const spanClassName = titleStyle().span;
    const keys = Object.keys(obj);
    return keys.map(key => {
        return <span className={spanClassName} key={'title-' + key}>{key}:{obj[key]}</span>
    });

}

