import {Edit} from "@material-ui/icons";
import {Button} from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";


const EditButton  = ({clickEvent}) =>{
    return  (
        <Button
            label="accommodation.action.cancel"
            onClick={clickEvent}
            color="primary"
        >
            <Edit/>Edit
        </Button>
    )
}

EditButton.propTypes = {
    clickEvent: PropTypes.func.isRequired
}

export default EditButton;
