import React, {useState} from 'react'
import {Button, MuiThemeProvider, createMuiTheme} from '@material-ui/core'
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import {basePath} from "../../rest-data-provider/paths";
import {notifyError,notifyShow} from "../../ec-admin-adapters/notifyF";
import {useNotify} from 'react-admin';
import loginOut from "./loginOut";

const theme = createMuiTheme();

const UpdateJson = (props) => {
    const [submitting,setSubmitting] = useState(false);
    const notify = useNotify();
    return (
        <MuiThemeProvider theme={theme}>
            <Typography variant="h5" style={{padding: '20px'}}>
                To get the newest data for the web site =>
                <Button style={{marginLeft: '10px'}}
                    variant="contained"
                    color="primary"
                    onClick={()=>{
                        setSubmitting(true);
                        axios.put(basePath + '/tools/updateJson', ).then((response) => {
                            setSubmitting(false);
                            notifyShow(notify,'Update Json successfully')
                        }).catch(error => {
                            if(error.response&&error.response.data==='Unauthorized'){
                                loginOut(props.history)
                            }
                            notifyError(notify,error);
                            setSubmitting(false);
                        })
                    }}
                    disabled={submitting}
                >Update Json</Button>
            </Typography>
        </MuiThemeProvider>
    );
}

export default UpdateJson;