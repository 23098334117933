import serviceValidateMeta from "./serviceValidateMeta";

export default {
    primaryKey:'ID',
    commonValidate: {
        From_Destination_ID: ['Required',{isInt:{min: 1}}],
        To_Destination_ID: ['Required',{isInt:{min: 1}}],
        Transfer_Mode_Code: ['Required',{isLength:{max: 15}}],
        Duration_Minutes: [{isInt:{max: 10000}}],
        Status_Code: ['Required',{isLength:{min:1,max:15}}],
        Service_Line_Identifier: [{isLength:{max:100}}],
        ...serviceValidateMeta
    }
};




