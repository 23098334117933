import uiContentValidateMeta from "./uiContentValidateMeta";

export default {
    primaryKey:'Code',
    uniqueFields: ['Name','Code'],
    commonValidate: {
        Code: ['Required',{isLength:{max: 15}}],
        Name: ['Required',{isLength:{max: 100}}],
        Service_Line_Identifier:[{isLength:{max: 100}}],
        Status_Code: ['Required',{isLength:{max: 15}}],
        Sequence: [{isInt:{min:0,max: 255}}],
        ...uiContentValidateMeta
    }
}



