import React from 'react';
import {
    TabbedForm, FormTab, TextInput,
    Create, NumberInput,
    BooleanInput
} from 'react-admin';
import Validator from '../../validators/accommodationValidator'
import UIContentFormTab from "../../components/forms/UIContentFormTab";
import {
    AccommodationTier,
    Affiliate, Destination,
    Status,
    Vendor
} from "../../components/refrences/input/RelatedInputs";
import AirportSelectInput from "./privateAirport/AirportSelectInput";
import {MasterDestinationSubsDestination} from "../destinations/MasterDestinationSubsDestination";

export default props => {
    const validator = new Validator().g_cr_var_fui();
    return (
        <Create  {...props}>
            <TabbedForm   validate ={validator} redirect='list' >
                <FormTab label="General" >
                    <TextInput source="Name" required/>
                    <AccommodationTier source="Accommodation_Tier_Code" label="Accommodation Tier"/>
                    <Destination/>
                    <MasterDestinationSubsDestination/>
                    <Vendor/>
                    <BooleanInput source='Is_Default_For_Destination' label='Is Default For Destination'/>
                    <BooleanInput source='Has_Triple_Rooms' label='Has Triple Rooms'/>
                    <BooleanInput source='Has_Quad_Rooms' label='Has Quad Rooms'/>
                    <BooleanInput source='Is_Default_For_Overnight' label='Is Default For Overnight'/>
                    <TextInput source="Lodge_Price_Code"/>
                    <Affiliate label="Availability System" source="Affiliate_Code"/>
                    <TextInput source="Affiliate_Internal_Code" label='Accommodation Availability ID'/>
                    <TextInput label="Supplier Code" source="Supplier_Code" required/>
                    <NumberInput source="Sequence"/>
                    <TextInput label="Availability System ID" source="Res_Request_Identifier"/>
                    <NumberInput source="Latitude"/>
                    <NumberInput source="Longitude"/>
                    <AirportSelectInput/>
                    <NumberInput source='Age_Minimum' label='Age Minimum'/>
                    <TextInput source='Age_Minimum_Note' label='Age Minimum Note'/>
                    <NumberInput source='Bed_Count' label='Bed Count'/>
                    <TextInput source='Bed_Count_Note' label='Bed Count Note'/>
                    <TextInput source='Price_Code' label='Price Code'/>
                    <NumberInput source='Price_From' label='Price From'/>
                    <Status source="Status_Code" label='Status' defaultValue='Published'/>
                </FormTab>
                <UIContentFormTab/>
            </TabbedForm>

        </Create>
    )
};
