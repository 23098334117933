import React from "react";
import {NumberInput} from 'react-admin'
import {_is_null} from '../../validators/simpleValidateGenerator'

import BushFormSpyField from "../../components/forms/BushFormSpyField";

const subsEvent = ({values, form}) => {
    const {Base_Net, Base_Rack, Conversion_Rate} = values;
    if (Conversion_Rate > 0 && !_is_null(Base_Net) && !_is_null(Base_Rack)) {
        form.change('Rack_USD',Math.round(Base_Rack*Conversion_Rate));
    }
}

export const RackRateSubsValues = props => {
    return <BushFormSpyField subsEvent={subsEvent}>
        <NumberInput source='Rack_USD' label='Rack USD'  disabled {...props}/>
    </BushFormSpyField>
}



