import React from 'react'
import {
    TextInput, NumberInput,
    Create, TabbedForm, FormTab, DateInput
} from 'react-admin'

import Validator from '../../validators/tripTemplateValidator'
import {Currency} from "../../components/refrences/input/RelatedInputs";
import UIContentFormTab from "../../components/forms/UIContentFormTab";

export default props => {
    const validator = new Validator().g_cr_var_fui()
    return (
        <Create title='Create Trip Template' {...props} >
            <TabbedForm  validate ={validator} redirect='list'>
                <FormTab label="General">
                    <TextInput source="Name" required/>
                    <DateInput source='Start_Date'label='Available from'/>
                    <DateInput source='End_Date'label='Available to'/>
                    <NumberInput source="Price_From"/>
                    <NumberInput source="Age_Minimum"  label='Youngest Traveler Age'/>
                    <NumberInput source="Sequence"/>
                </FormTab>
                <UIContentFormTab/>
            </TabbedForm>
        </Create>
    )
};
