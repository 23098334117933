import React, {Fragment} from "react";
import {
   Datagrid, TextField
} from 'react-admin';
import LodgingTypeAddButton from "./LodgingTypeAddButton";
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types'
import CustomDeleteButton from "../../../components/Buttons/CustomDeleteButton";
import {LodgingType} from "../../../components/refrences/field/RelatedFields";

const useStyles = makeStyles({
    buttons: {
        display:'flex',
        'justify-content':'space-between'
    },
});

function recordToTitle(record){
    return 'Remove LodgingType ('+(record.Lodging_Type.Name)+') from Accommodation?';
}

const LodgingTypes = (props) => {
    const classes = useStyles();
    const {accommodationID,...others} = props;
    return (
        <Fragment>
            <div className={classes.buttons}>
                <span/>
                <LodgingTypeAddButton {...props}/>
            </div>

            <Datagrid style={{"width":"100%"}} {...others}  >
                <TextField source="Lodging_Type_Code" label="Code"/>
                <LodgingType source="Lodging_Type_Code" label="Name"  sortable={false}/>
                <CustomDeleteButton recordToTitle={recordToTitle}/>
            </Datagrid>
        </Fragment>
    )
}
LodgingTypes.propTypes ={
    accommodationID: PropTypes.string.isRequired
}


export default LodgingTypes;
