import React, {useEffect, useState} from "react";
import {UPDATE,GET_ONE,useNotify} from 'react-admin'
import dataProvider from "../../../rest-data-provider";
import {notifyError} from "../../../ec-admin-adapters/notifyF";
import RelatedAddOrEditButton from "../../../components/Buttons/RelatedAddOrEditButton";
import {typeInputs, fetchKeys} from "./ConditionalReservationInputs";
import Title from "./Title";
import axios from "axios";
import {basePath} from "../../../rest-data-provider/paths";
import ReservationValidator from "../../../validators/reservationValidator";


const ReservationEditButton =(props)=>{
    const {row,Trip_ID,Destination_ID,Accommodation_Tier_Code,Trip_Step_ID,steps,setSteps} = props;
    const {Service_Type_Code,Service_ID} = row;
    const [reservation,setReservation] = useState({});
    const [info,setInfo]=useState(null);
    const notify = useNotify();

    const validator = new ReservationValidator().g_up_var_f_ui(row.ID);
    const submitEvent = ({notify,values,setShow})=>{
        const ID = values['ID'];
        dataProvider(UPDATE, 'reservations', {data: values,id:ID}).then(() => {
            dataProvider(GET_ONE, 'reservations', {
                id: ID
            }).then(resp=>{
                if (resp && resp.data) {
                    const data = resp.data;
                    const Reservations = steps[Trip_Step_ID]['Reservations'];
                    for(let i =0 ; i< Reservations.length;i++){
                        if(Reservations[i].ID ===ID){
                            Reservations[i]=data;
                        }
                    }
                    setSteps({...steps});
                }
            })
            setShow(false);
        }).catch(error => {
            notifyError(notify,error);
        })
    };
    useEffect(()=>{
        setReservation({Destination_ID,...row});
        if(Service_Type_Code==='AC'){
            dataProvider(GET_ONE, 'destinations', {
                id: Destination_ID
            }).then(
                (resp) => {
                    axios.get(basePath + '/services/findAccommodationByServiceID/' + Service_ID).then((accommodation) => {
                        const theInfo={Accommodation_Tier_Code};
                        if(accommodation.data.length>0) {
                            theInfo.Accommodation=accommodation.data[0].Name;
                        }
                        if (resp && resp.data) {
                            theInfo.Destination=resp.data.Name;
                        }
                        setInfo(theInfo);
                    })
                }
            ).catch(error => {
                notifyError(notify,error);
            })
        }else if(Service_Type_Code==='AT'||Service_Type_Code==='PF'){
            axios.get(basePath + '/services/findActivityInfo/' + Service_ID).then((resp) => {
                if(resp.data.length>0){
                    const activityInfo = resp.data[0];
                    row.Accommodation_ID=activityInfo.Accommodation_ID;
                    row.From_Destination_ID=activityInfo.Destination_ID;
                    row.Activity_Code=activityInfo.Activity_Code;
                    setReservation({Destination_ID,...row});
                }
            }).catch(error => {
                notifyError(notify,error);
            })
        }
    },[Service_Type_Code,notify,row,Service_ID,Destination_ID,Accommodation_Tier_Code])

    const title =  <Title serviceType={Service_Type_Code} isEdit={true} info={info} />;
    return <RelatedAddOrEditButton title={title} record={reservation}
                                   validator={validator}
                                   submitEvent={submitEvent}>
        {
            fetchKeys(typeInputs(Service_Type_Code,true,{tripID:parseInt(Trip_ID),destinationID:Destination_ID}))
        }
    </RelatedAddOrEditButton>
};



export default ReservationEditButton;
