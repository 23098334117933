export default {
    primaryKey:'Code',
    commonValidate: {
        Top:['Required',{isInt:{min:0,max:10000}}],
        Left:['Required',{isInt:{min:0,max:10000}}],
        Right:['Required',{isInt:{min:0,max:10000}}],
        Bottom:['Required',{isInt:{min:0,max:10000}}],
        Width:['Required',{isInt:{min:0,max:10000}}],
        Height:['Required',{isInt:{min:0,max:10000}}],
    },
}



