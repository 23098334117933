import {Destination} from "../../components/refrences/input/NoneStoreRelatedInputs";
import React from "react";
import {useForm, useFormState} from "react-final-form";

export default props => {
    const formState = useFormState();
    const form = useForm();
    const Is_Master = formState.values['Is_Master_Destination'];
    return (
        <div hidden={Is_Master}>
            <Destination  {...props} source='Master_Destination_ID' label='Master Destination' allowEmpty={true}
                          filter={{Is_Master_Destination: true}} selectedCallback={({id}) => {
                if (id === (props.record && props.record.id)) {
                    form.change('Is_Master_Destination',true);
                }
            }
            }/>
        </div>
    )
}
