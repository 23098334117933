import React, {Fragment, useState} from "react";
import {Button, Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {Wifi} from "@material-ui/icons";
import {TextInput ,SimpleForm,useNotify} from 'react-admin' ;
import SaveCancelButtons from "../../components/Buttons/SaveCancelButtons";
import axios from "axios";
import {basePath} from "../../rest-data-provider/paths";
import {notifyError, notifyShow} from "../../ec-admin-adapters/notifyF";
import normalValidator from "validator";

const TestConnectionButton = ()=>{
    const [show,setShow] = useState(false);
    const [disabled,setDisabled] = useState(false);
    const notify = useNotify();
    return  <Fragment>
        <Button color="primary" onClick={()=>{setShow(true)
            setDisabled(false);
        }}>
            <Wifi/> Test Connection
        </Button>
        <Dialog
            disableEnforceFocus
            fullWidth
            open={show}
            onClose={()=>{setShow(false)}}
        >
            <DialogTitle>Test Connection</DialogTitle>
            <DialogContent>
                <SimpleForm
                    validate={(values)=>{
                        const receiver = values.Mail_Receiver;
                        if(!receiver){
                            return {Mail_Receiver:'Required'};
                        }
                        if(!normalValidator.isEmail(receiver)){
                            return {Mail_Receiver:'not a email'};
                        }

                    }}
                    save={(values)=>{
                        setDisabled(true);
                        axios.get(basePath + "/emailConf/test?emailAddress="+values.Mail_Receiver).then((() => {
                                notifyShow(notify,'connected successfully');
                                setShow(false);
                            }
                        )).catch(error => {
                            notifyError(notify,error);
                            setShow(false);
                        })
                    }}
                    toolbar={<SaveCancelButtons saveDisabled={disabled} saveLabel='Test' cancelEvent={()=>{setShow(false)}}/>}
                >
                    <TextInput source='Mail_Receiver' label='Mail Receiver' required/>
                </SimpleForm>

            </DialogContent>
        </Dialog>
    </Fragment>

}


export default TestConnectionButton;
