export default {
    primaryKey:'ID',
    UI_Content:{
        URL:['isURL',{isLength:{max: 1000}}],
        Page_Title:[{isLength:{max: 100}}],
        Subtitle: [{isLength:{max: 100}}],
        Meta_Description: [{isLength:{max: 1000}}],
        Included_Note:[{isLength:{max: 1000}}],
        Available_Note:[{isLength:{max: 1000}}],
    },
}
