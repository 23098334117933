import {
    CHANGE_DESTINATION_FOR_AIRPORT
} from '../constants/actionTypes'

export default function(state ={} ,action){
 switch (action.type) {
     case CHANGE_DESTINATION_FOR_AIRPORT:
         return {...state,airportsForDestination:action.airports};
     default:
         return state;
 }
}
