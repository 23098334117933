import React from 'react'
import {
    Edit, TextInput, DateTimeInput, SimpleForm,NumberInput
} from 'react-admin'
import Validator from '../../validators/pdfSettingValidator'
import EditTitle from "../../components/forms/EditTitle";

export default props => {
    const validator = new Validator().g_up_var_f_ui(props.id)
    return <Edit  title={<EditTitle EntityName='PDFSetting' id={props.id} resource='pdfSettings'/>} undoable={false} {...props} >
        <SimpleForm  validate ={validator}>
            <DateTimeInput disabled label='Created' source="Created_Time"/>
            <DateTimeInput disabled label='Modified' source="Modified_Time"/>
            <TextInput source="Code" required disabled/>
            <NumberInput source="Top" required/>
            <NumberInput source="Left" required/>
            <NumberInput source="Right" required/>
            <NumberInput source="Bottom" required/>
            <NumberInput source="Width" required disabled/>
            <NumberInput source="Height" required disabled/>
            <NumberInput source="Footer_Height" label='Footer Height' required/>
        </SimpleForm>

    </Edit>
}
