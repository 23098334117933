import React from 'react'
import {
    List, downloadCSV, Datagrid, TextField, EditButton, DeleteButton,
    Filter,
} from 'react-admin'
import {unparse as convertToCSV} from 'papaparse/papaparse.min'
import idReducer from '../../ec-admin-adapters/idReducer'


const AffiliateFilter = (props) => (
    <Filter {...props}>
    </Filter>
);

const exporter = affiliates => {
    affiliates = idReducer(affiliates)
    const csv = convertToCSV({
        data: affiliates,
        // fields: ['Final_Transfer_Origin', 'Name', 'Final_Transfer_Destination'] // order fields in the export
    });
    downloadCSV(csv, 'affiliates  ' + new Date()); // download as 'affiliates.csv` file
}

const AffiliateTable = props => {
    return (
        <Datagrid {...props} >
            <TextField source="Sequence"/>
            <TextField source="Name"/>
            <EditButton/>
            <DeleteButton undoable={false}/>
        </Datagrid>
    )
}

export default props => {

    return (
        <List {...props} filters={<AffiliateFilter/>} exporter={exporter}
              sort={{ field: 'Sequence', order: 'ASC' }}
        >
            <AffiliateTable/>
        </List>
    )
}







