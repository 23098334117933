import React from 'react'
import {
    Edit, TextInput, TabbedForm, FormTab,
    NumberInput, DateTimeInput,
    ReferenceManyField,EditButton,
    BooleanInput,Datagrid,TextField,FunctionField
} from 'react-admin';
import Validator from '../../validators/accommodationValidator';

import Animals from "./animals";
import Amenities from "./amenities";
import UIContentFormTab from "../../components/forms/UIContentFormTab";
import EditTitle from "../../components/forms/EditTitle";
import {
    AccommodationTier,
    Affiliate, Destination,
    Status,
    Vendor
} from "../../components/refrences/input/RelatedInputs";
import AirportSelectInput from "./privateAirport/AirportSelectInput";
import {MasterDestinationSubsDestination} from "../destinations/MasterDestinationSubsDestination";
import LodgingTypes from "./lodgingTypes";
import { makeStyles } from '@material-ui/styles';
import RoomTypesAddButton from './roomTypes/RoomTypesAddButton';

const useStyles = makeStyles({
    buttonStyle: {
        position: 'absolute',
        width: '11.5%',
        zIndex: 10,
        right: 0,
    },
});

export default props => {
    const classes = useStyles();
    const validator = new Validator().g_up_var_f_ui(props.id);
    return <Edit {...props} undoable={false}
                 title={<EditTitle EntityName='Accommodation' id={props.id} resource='accommodations'/>}>
        <TabbedForm  validate ={validator}>
            <FormTab label="General">
                <DateTimeInput disabled label='Created' source="Created_Time"/>
                <DateTimeInput disabled label='Modified' source="Modified_Time"/>
                <TextInput source="Name" required/>
                <AccommodationTier source="Accommodation_Tier_Code" label="Accommodation Tier"/>
                <Destination/>
                <MasterDestinationSubsDestination/>
                <Vendor/>
                <BooleanInput source='Is_Default_For_Destination' label='Is Default For Destination'/>
                <BooleanInput source='Has_Triple_Rooms' label='Has Triple Rooms'/>
                <BooleanInput source='Has_Quad_Rooms' label='Has Quad Rooms'/>
                <BooleanInput source='Is_Default_For_Overnight' label='Is Default For Overnight'/>
                <TextInput source="Lodge_Price_Code"/>
                <Affiliate />
                <TextInput source="Affiliate_Internal_Code" label='Accommodation Availability ID'/>
                <TextInput label="Supplier Code" source="Supplier_Code" required/>
                <NumberInput source="Sequence"/>
                <TextInput label="Availability System ID" source="Res_Request_Identifier"/>
                <NumberInput source="Latitude"/>
                <NumberInput source="Longitude"/>
                <AirportSelectInput  source="Default_Airport_ID" label="Default Airport"/>
                <NumberInput source='Age_Minimum' label='Age Minimum'/>
                <TextInput source='Age_Minimum_Note' label='Age Minimum Note'/>
                <NumberInput source='Bed_Count' label='Bed Count'/>
                <TextInput source='Bed_Count_Note' label='Bed Count Note'/>
                <TextInput source='Price_Code' label='Price Code'/>
                <NumberInput source='Price_From' label='Price From'/>
                <Status source="Status_Code" label='Status' defaultValue='Published'/>
            </FormTab>
            <UIContentFormTab/>
            <FormTab label="Animals">
                <ReferenceManyField reference="accommodationsToAnimals" target="Accommodation_ID" addLabel={false}>
                    <Animals accommodationID={props.id}/>
                </ReferenceManyField>
            </FormTab>
            <FormTab label="Amenities">
                <ReferenceManyField reference="accommodationsToAmenities" target="Accommodation_ID" addLabel={false}>
                    <Amenities accommodationID={props.id}/>
                </ReferenceManyField>
            </FormTab>
            <FormTab label="Lodging Types">
                <ReferenceManyField reference="accommodationsToLodgingTypes" target="Accommodation_ID" addLabel={false}>
                    <LodgingTypes accommodationID={props.id}/>
                </ReferenceManyField>
            </FormTab>
            <FormTab label ='Room Types'>
                <div className={classes.buttonStyle}>
                    <RoomTypesAddButton accommodationID={props.id} />
                </div>
                <ReferenceManyField reference="accommodationRoomTypes" target="Accommodation_ID" addLabel={false} sort={{ field: 'ID', order: 'ASC' }}>
                    <Datagrid style={{"width":"100%"}}>
                        <TextField source="ID" label="ID"/>
                        <TextField source="Name"/>
                        <FunctionField label="Is Default For Accommodation" source='Is_Default_for_Accommodation' render={record => {
                            return ((record.Is_Default_for_Accommodation && 'Yes') || 'No')
                        }}/>
                        <TextField source="Service_Line_Identifier" label="Service Line Identifier"/>
                        <EditButton/>
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>

        </TabbedForm>
    </Edit>
}
