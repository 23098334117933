export const getParameters = url =>
    url
        .match(/([^?=&]+)(=([^&]*))/g)
        .reduce((a, v) => ((a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)) , a), {});


export function replaceParamVal(paramName,replaceWith,url) {
    const re=eval('/('+ paramName+'=)([^&]*)/gi');
    const nUrl = url.replace(re,paramName+'='+replaceWith);
    return nUrl;
}
