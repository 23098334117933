import React, {Fragment, useEffect, useState} from "react";
import {
    Datagrid, TextField, FunctionField
} from 'react-admin';
import TransferAddButton from "./TransferAddButton";
import {makeStyles} from '@material-ui/styles';
import PropTypes from 'prop-types'
import {Destination, TransferMode} from "../../../components/refrences/field/RelatedFields";
import StatusButton from "../../../components/Buttons/StatusButton";
import {Button} from "@material-ui/core";
import TransferEditButton from "./TransferEditButton";
import {connect} from 'react-redux'
import axios from 'axios';
import {basePath} from "../../../rest-data-provider/paths";

const useStyles = makeStyles({
    buttons: {
        display: 'flex',
        'justify-content': 'space-between'
    },
});

function recordToTitle(record, resources) {
    const destinations = resources.destinations;
    let fromDestinationName = 'Unknown', toDestinationName = 'Unknown';
    if (destinations && destinations.data&&record.Transfer) {
        const fromDestination = destinations.data[record.Transfer.From_Destination_ID],
            toDestination = destinations.data[record.Transfer.To_Destination_ID];
        if (fromDestination) {
            fromDestinationName = fromDestination.Name;
        }
        if (toDestination) {
            toDestinationName = toDestination.Name;
        }
    }
    return 'Transfer (' + fromDestinationName + ' To ' + toDestinationName + ') from Route?';
}

 function removeRecordToTitle(record, resources){
    return "Remove "+ recordToTitle(record,resources);
 }
function restoreRecordToTitle(record, resources){
    return "Restore "+ recordToTitle(record,resources);
}

const TransferLink = props=> {
    const {history,record} = props;
    const {Transfer,Transfer_ID} = record;
    const {Source_ID} = Transfer;
    return <Button color="primary" onClick={()=>history.push('/transfers/'+(Source_ID || Transfer_ID))}>
        Transfer
    </Button>}

const Transfers = (props) => {
    const classes = useStyles();
    let {routeID,From_Destination_ID,dispatch, ...others} = props;
    const [newValues,setNewValues] =useState(undefined);
    useEffect(()=>{
            axios.get(basePath + '/transfers/getToDestinationIDByRouteID?routeId=' + routeID).then((response) => {
                const data = response.data;
                let Sequence = 1;
                if (data && data.length > 0) {
                    From_Destination_ID = data[0].To_Destination_ID;
                    Sequence = data[0].Sequence;
                }
                setNewValues({Sequence,From_Destination_ID,Route_ID:routeID,Status_Code:'Published'});
            })

    },[])
    return (
        <Fragment>
            <div className={classes.buttons}>
                <span/>
                <TransferAddButton {...props} initialValues={newValues}/>
            </div>

            <Datagrid style={{"width": "100%"}} {...others}  >
                <TextField source="Sequence"/>
                <Destination source="Transfer.From_Destination_ID" label="From Destination" sortable={false}/>
                <Destination source="Transfer.To_Destination_ID" label="To Destination" sortable={false}/>
                <TransferMode source="Transfer.Transfer_Mode_Code" label="How" sortable={false}/>
                <FunctionField label="Is Overnight" source='Is_Overnight' render={record => {
                    return ((record.Is_Overnight && 'Yes') || 'No')
                }}/>
                <TextField source='Transfer.Duration_Minutes' label='Duration Minutes'/>
                <TextField source='Transfer.Service_Line_Identifier' label='Service Line Identifier'/>
                <TextField source='Status_Code' label='Status'/>
                <TextField source='Transfer.Status_Code' label='Transfer Status'/>
                <TransferLink history={props.history}/>
                <TransferEditButton />
                <StatusButton removeRecordToTitle={removeRecordToTitle} restoreRecordToTitle={restoreRecordToTitle}/>
            </Datagrid>
        </Fragment>
    )
}
Transfers.propTypes = {
    routeID: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
    return {
        From_Destination_ID: state.admin.resources.routes.data[ownProps.routeID].From_Destination_ID
    }
}

export default connect(mapStateToProps)(Transfers);

// export default Transfers;
