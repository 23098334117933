export default {
    primaryKey:'ID',
    uniqueFields:['Accommodation_ID'],
    commonValidate: {
        Name: ['Required',{isLength:{max: 100}}],
        Accommodation_ID: ['Required',{isInt:{min:1,max: 4294967295}}],
        Special_ID: [{isInt:{min:1,max: 4294967295}}],
        Start_Date: ['Required',{LTT:'End_Date'}],
        End_Date: ['Required'],
        Description:[{isLength:{max: 10000}}],
        Terms_Conditions:[{isLength:{max: 1000}}],
    }
}



