import React from 'react'
import Button from '@material-ui/core/Button';
import {CreateButton, ExportButton } from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import Link from "@material-ui/core/Link";
export default ({
                                        bulkActions,
                                        basePath,
                                        currentSort,
                                        displayedFilters,
                                        exporter,
                                        filters,
                                        filterValues,
                                        onUnselectItems,
                                        resource,
                                        selectedIds,
                                        showFilter,
                                        total
                                    }) => (
    <Toolbar>
        <CreateButton basePath={basePath} />
        <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
        <Link color="primary" href="/#/route_wrap" variant="body2">
        {"WRAP"}
        </Link>
    </Toolbar>
);
