import React from 'react'
import {
    Edit, TextInput, NumberInput, DateInput, DateTimeInput
    , TabbedForm, FormTab,ReferenceManyField
} from 'react-admin'
import Validator from '../../validators/tripTemplateValidator'
import EditTitle from "../../components/forms/EditTitle";
import {Currency} from "../../components/refrences/input/RelatedInputs";
import TripTemplateSteps from "./tripTemplateSteps";
import UIContentFormTab from "../../components/forms/UIContentFormTab";

export default props => {

    const validator = new Validator().g_up_var_f_ui(props.id)
    return <Edit title={<EditTitle EntityName='Trip Template' id={props.id} resource='tripTemplates'/>} undoable={false} {...props} >
        <TabbedForm  validate ={validator} >
            <FormTab label="General" >
                <DateTimeInput disabled label='Created' source="Created_Time"/>
                <DateTimeInput disabled label='Modified' source="Modified_Time"/>
                <TextInput source="Name" required/>
                <DateInput source='Start_Date'label='Available from'/>
                <DateInput source='End_Date'label='Available to'/>
                <NumberInput source="Price_From"/>
                <NumberInput source="Age_Minimum" label='Youngest Traveler Age'/>
                <NumberInput source="Sequence"/>
            </FormTab>
            <UIContentFormTab/>
            <FormTab label="Steps">
                <ReferenceManyField reference="tripTemplateSteps" target="Trip_Template_ID" addLabel={false}
                                    sort={{ field: 'Step_Number', order: 'ASC' }}
                >
                    <TripTemplateSteps tripTemplateID={props.id}/>
                </ReferenceManyField>
            </FormTab>
        </TabbedForm>
    </Edit>
}
