import axios from "axios";
import {basePath} from "../paths";

function getSorts(sort) {
    let sorts = [];
    if (typeof sort == "string") {
        sorts.push(sort);
    } else if (Array.isArray(sort)) {
        sorts = sort;
    }
    if (sorts.length != 0 && sorts[0] != "") {
        sorts.splice(0, 0, "");
    }
    return sorts;
}
export const roomTypeFetchByDestinationIDAndAccommodationID = (destinationID, accommodationID, sort) => {
    return async (serviceName) => {
        if (destinationID) {
            let sorts = getSorts(sort);
            return axios.get(basePath + "/services/findRoomTypeServiceByDestinationAndAccommodation?destinationID=" + destinationID
                + ((accommodationID && "&accommodationID=" + accommodationID) || "")
                +((serviceName && "&serviceName=" + serviceName) || "")
                + sorts.join("&sort=")
            ).then(((services) => {
                    return services.data.map(service => {
                        return {
                            id: service.ID,
                            value: service.Name + "-" + (service.Service_Line_Identifier || "No Service_Line_Identifier"),
                            Service_Line_Identifier:(service.Service_Line_Identifier || "No Service_Line_Identifier"),
                        };
                    })
                }
            ));
        }
        return [];
    }

}

export const findTransferServiceByDestinations = (fromDestinationID,toDestinationID) => {
    return async (serviceName) => {
        if (fromDestinationID&&toDestinationID) {
            return axios.get(basePath + `/services/findTransferServiceByDestinations?fromDestinationID=${fromDestinationID}&toDestinationID=${toDestinationID}&serviceName=${serviceName||""}`)
                .then(((services) => {
                    return services.data.map(service => {
                        return {id: service.ID,
                            Service_Line_Identifier:(service.Service_Line_Identifier || "No Service_Line_Identifier"),
                            value: service.Name + "-" + (service.Service_Line_Identifier || "No Service_Line_Identifier")
                        };
                    })
                }
            ));
        }
        return [];
    }
}


export const findActivityServiceByDestinationAndAccommodationAndActivityCode = (destinationID, accommodationID, activityCode, sort) => {
    return async (serviceName) => {
        if (destinationID) {

            let sorts = getSorts(sort);
            return axios.get(basePath + "/services/findActivityServiceByDestinationAndAccommodationAndActivityCode?accommodationID=" + (accommodationID || "") +
                "&destinationID=" + (destinationID || "") + "&activityCode=" + (activityCode || "") + "&serviceName=" + (serviceName || "")
                + sorts.join("&sort=")

            ).then(((services) => {
                    return services.data.map(service => {
                        return {id: service.ID,
                            Service_Line_Identifier:(service.Service_Line_Identifier || "No Service_Line_Identifier"),
                            value: service.Name + "-" + (service.Service_Line_Identifier || "No Service_Line_Identifier"),
                            Included_Note:service.Included_Note};
                    })
                }
            ));
        }
        return [];
    }
}


export const  getTransferInfoByServiceAndFromDestination = async (serviceID,fromDestinationID, sort) => {
    let sorts = getSorts(sort);
    return axios.get(basePath + `/services/getTransferInfoByServiceAndFromDestination?serviceID=${serviceID}&fromDestinationID=${fromDestinationID}`
        + sorts.join("&sort=")
        ).then(resp=>resp.data);
}


export const transferLocationsFetch=(transferID)=>{
    return axios.get(basePath + "/transfers/getTransferLocationsInfo/" + transferID).then(resp=>resp.data);
}






