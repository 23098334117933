import React,{useCallback} from "react";
import FormGroup from "@material-ui/core/FormGroup/index";
import FormControlLabel from "@material-ui/core/FormControlLabel/index";
import Switch from "@material-ui/core/Switch/index";
import {useInput} from 'react-admin';

// const  DisabledBooleanInput = function (_a) {

//     return (React.createElement(FormGroup, __assign({}, sanitizeRestProps(rest)),
//         React.createElement(FormControlLabel, { control: React.createElement(Switch, __assign({ id: id, color: "primary", onChange: handleChange }, inputProps, options)), label: React.createElement(FieldTitle, { label: label, source: source, resource: resource, isRequired: isRequired }) }),
//         (touched && error) || helperText ? (React.createElement(FormHelperText, { error: !!error },
//             React.createElement(InputHelperText, { touched: touched, error: error, helperText: helperText }))) : null));
// };

export default props=>{

    //     var format = _a.format, label = _a.label, fullWidth = _a.fullWidth, helperText = _a.helperText, onBlur = _a.onBlur, onChange = _a.onChange, onFocus = _a.onFocus, options = _a.options, parse = _a.parse, resource = _a.resource, source = _a.source, validate = _a.validate, rest = __rest(_a, ["format", "label", "fullWidth", "helperText", "onBlur", "onChange", "onFocus", "options", "parse", "resource", "source", "validate"]);
//     var _b = useInput(__assign({ format: format,
//         onBlur: onBlur,
//         onChange: onChange,
//         onFocus: onFocus,
//         parse: parse,
//         resource: resource,
//         source: source, type: 'checkbox', validate: validate }, rest)), id = _b.id, _c = _b.input, finalFormOnChange = _c.onChange, type = _c.type, value = _c.value, inputProps = __rest(_c, ["onChange", "type", "value"]), isRequired = _b.isRequired, _d = _b.meta, error = _d.error, touched = _d.touched;
//     var handleChange = useCallback(function (event, value) {
//         finalFormOnChange(value);
//     }, [finalFormOnChange]);
    const{label} = props;
    const raProps = useInput({type:'checkbox',...props});
    const {onChange,type, ...inputProps} = raProps.input;
    // const fieldProps = useFinalFormField(source);
    //     // const inputProps = fieldProps.input;
    //     // const finalFormOnChange = inputProps.onChange;
    const handleChange = useCallback(function (event, value) {
        onChange(value);
    }, [onChange]);
    return (
        <FormGroup>
            <FormControlLabel disabled control={<Switch color="primary" id={raProps.id} {...inputProps} onChange={handleChange} />} label={label}/>
        </FormGroup>

    )
}
