import React from 'react'
import {
    Create, BooleanInput, NumberInput,TabbedForm, FormTab,TextInput
} from 'react-admin'
import Validator from '../../validators/transferValidator';
import Rates from "../rates";

import {Destination, Status, TransferMode} from "../../components/refrences/input/RelatedInputs";
import {VendorSubsServiceLine} from "../services/VendorSubsServiceLine";


export default props => {
    const validator = new Validator().g_cr_var_fui();

    return <Create {...props}>
        <TabbedForm   validate ={validator} submitOnEnter={false} redirect='list'>
            <FormTab label='General'>
                <Destination label='From Destination' source='From_Destination_ID'/>
                <Destination label='To Destination' source='To_Destination_ID'/>
                <TransferMode label='Transfer Mode'source='Transfer_Mode_Code'/>
                <TextInput source='Service_Line_Identifier' label='Service Line Identifier'/>
                <VendorSubsServiceLine source="Service.Vendor_ID" label='Vendor Name'/>
                <NumberInput source="Duration_Minutes"/>
               <Status source="Status_Code" label='Status' defaultValue='Published'/>
                <BooleanInput source='Is_Bidirectional' label='Is Bidirectional' defaultValue={true}/>
                <TextInput source="Service.Name"  label="Name"  required/>
                <TextInput source="Service.Service_Type_Code" label='Service Type Code' defaultValue='TF' disabled />
                <NumberInput source="Service.Capacity" label="Capacity" />
                <BooleanInput source="Service.Priced" label='Priced' defaultValue={false}/>
                <BooleanInput source="Service.Needs_Update" label='Needs Update' defaultValue={false}/>
            </FormTab>
            <FormTab label="Rates">
                <Rates/>
            </FormTab>
        </TabbedForm>
    </Create>
}
