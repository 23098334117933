import baseValidator from './baseValidator'
import meta from '../validateMetas/accommodationsToActivitiesValidateMeta'

class AccommodationsToActivitiesValidator extends baseValidator {
    meta = meta
    uiMeta={
        resources: "accommodationsToActivities",
    }

    constructor() {
        super();
        this.g_cr_var_fui = super.g_cr_var_fui.bind(this)
        this.g_up_var_f_ui = super.g_up_var_f_ui.bind(this)
    }
}

export default AccommodationsToActivitiesValidator;




