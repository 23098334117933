import React from "react";
import {TextInput, CREATE, BooleanInput} from 'react-admin'
import dataProvider from "../../../rest-data-provider";
import PropTypes from "prop-types";
import {Accommodation} from "../../../components/refrences/input/NoneStoreRelatedInputs";
import {notifyError, notifyShow} from "../../../ec-admin-adapters/notifyF";
import RelatedAddOrEditButton from "../../../components/Buttons/RelatedAddOrEditButton";
import {VendorSubsAccommodation} from "../../services/VendorSubsAccommodation";
import AccommodationRoomServiceLine from "../../accommodationRoomTypes/AccommodationRoomServiceLine";


const RoomTypesAddButton = props=>{
    const {accommodationID} = props;
    const submitEvent=({notify,refresh,values,setShow})=>{
        dataProvider(CREATE, 'accommodationRoomTypes', {data: values}).then(() => {
            refresh();
            setShow(false);
            notifyShow(notify, "New element added.");
        }).catch(error => {
            notifyError(notify,error);
        })
    }
    return <RelatedAddOrEditButton title='Add Room Types' record={{Accommodation_ID :accommodationID}} submitEvent={submitEvent}>
            <div style={{display: 'flex', width: '100%'}}>
                <div>
                    <h3>General</h3>
                    <TextInput source="Name" required/>
                    <Accommodation source="Accommodation_ID" label="Accommodation" />
                    <VendorSubsAccommodation source="Service.Vendor_ID" label='Vendor Name'/>
                    <BooleanInput source='Is_Default_for_Accommodation' label='Is Default For Accommodation' defaultValue={true}/>
                    <TextInput source='Room_Type_Code' label='Room Type Code' required/>
                    <AccommodationRoomServiceLine/>
                </div>
                <div>
                    <h3>Service</h3>
                    <TextInput source="Service.Name" label="Name" required/>
                    <TextInput source="Service.Service_Type_Code" label='Service Type Code' defaultValue='AC' disabled />
                    <BooleanInput source="Service.Priced" label='Priced' defaultValue={false}/>
                    <BooleanInput source="Service.Needs_Update" label='Needs Update' defaultValue={false}/>
                </div>
            </div>
            
            
    </RelatedAddOrEditButton>
}

RoomTypesAddButton.propTypes ={
    tripTemplateID: PropTypes.string.isRequired
};

export default RoomTypesAddButton;
