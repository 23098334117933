import React from 'react'
import {
    Edit, TextInput, TabbedForm, FormTab,
    DateTimeInput,Toolbar,SaveButton,BooleanInput
} from 'react-admin';
import Validator from '../../validators/accommodationsToActivitiesValidator';
import EditTitle from "../../components/forms/EditTitle";
import { withStyles } from '@material-ui/core';
import CustomDeleteButton from "../../components/Buttons/CustomDeleteButton";
import Rates from "../rates";
import {Accommodation, Status} from "../../components/refrences/input/RelatedInputs";
import HiddenInput from "../../components/inputs/HiddenInput";
import UIContentFormTab from "../../components/forms/UIContentFormTab";
import {VendorSubsAccommodation} from "../services/VendorSubsAccommodation";
import AccommodationActivityServiceType from "./AccommodationActivityServiceType";
import AccommodationActivityServiceLine from "./AccommodationActivityServiceLine";
import AccommodationActivityServiceCode from "./AccommodationActivityServiceCode";
import DisabledBooleanInput from "../../components/inputs/DisabledBooleanInput";
import ActivitySelectInput from "./ActivitySelectInput";

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

function recordToTitle(record){
    if(record&& (record.Name||record.Activity)){
            return 'Remove Activity ('+(record.Name||(record.Activity&&record.Activity.Name)||record.ID)+'['+record.Accommodation.Name+']) from Accommodation?';
    }

    return '';
}

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
        <CustomDeleteButton recordToTitle={recordToTitle}/>
    </Toolbar>
));


const EditTabs = (props) => {
    const validator = new Validator().g_up_var_f_ui(props.id);
    return (
        <TabbedForm  validate ={validator} {...props} toolbar={<CustomToolbar />}>
            <FormTab label="General">
                <DateTimeInput disabled label='Created' source="Created_Time"/>
                <DateTimeInput disabled label='Modified' source="Modified_Time"/>
                <Accommodation />
                <BooleanInput source="Auto_Assign" label="Auto Assign "/>
                <ActivitySelectInput/>
                <TextInput source="Name"/>
                <AccommodationActivityServiceCode/>
                <AccommodationActivityServiceLine />
                <VendorSubsAccommodation source="Service.Vendor_ID" label='Vendor Name'/>
                <Status source="Status_Code" label='Status' />
            </FormTab>
            <UIContentFormTab/>
            <FormTab label="Service" >
                <HiddenInput source="Service.ID" label="ID" required disabled/>
                <TextInput source="Service.Name" label="Name" />
                <AccommodationActivityServiceType />
                <DisabledBooleanInput source="Service.Priced" label='Priced' />
                <BooleanInput source="Service.Needs_Update" label='Needs Update'/>
            </FormTab>
            <FormTab label="Rates">
                <Rates/>
            </FormTab>
        </TabbedForm>
    )
}

const AccommodationsToActivitiesEdit = props => {
    return <Edit {...props} undoable={false} title={<EditTitle EntityName='Accommodation Activity' id={props.id} resource='accommodationsToActivities'/>}>
        <EditTabs/>
    </Edit>
}


export default AccommodationsToActivitiesEdit;
